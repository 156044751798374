import React, { useEffect, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import InputGroup from '../UI/formElements/InputGroup'
import TextareaGroup from '../UI/formElements/TextareaGroup'
import SelectGroup from '../UI/formElements/SelectGroup'
import UploadImage from '../UI/UploadImage'
// import { categoryOptions } from '../../helpers/constants'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getFilteres } from '../../store/filters/filtersSlice'
import { useValidation } from '../../hooks/useValidation'
import { getFormData, getImageSrc } from '../../helpers/utils'
import { createMaterialLibraryFeth, delMaterialLibraryByIdFeth, editMaterialLibraryByIdFeth, getMaterialLibraryByIdFeth } from '../../store/materials/materialLibraryThunks'
import { clearMaterial, setMaterial } from '../../store/materials/materialLibrarySlice'
import ButtonLoader from '../UI/ButtonLoader'
import { toast } from 'react-toastify'
import CheckBoxGroup from '../UI/formElements/CheckBoxGroup'

export const AddMaterialModal = ({ title, open, handleClose, uploadType, editModal = false, setDeleteModal }) => {
    const { material, materialId } = useSelector(state => state.materialLibrary);
    const dispatch = useDispatch()
    const systems = useSelector(getFilteres);
    const [formData, setFormData] = useState({
        file: null,
        title: '',
        description: '',
        system: null,
        isAnatomy: false
    })
    const [isLoading, setIsLoading] = useState(false)
    const validators = {
        name: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
        file: file => {
            if (!file) {
                return 'File is required';
            }
            if(file instanceof File) {
                const maxSizeInMB = uploadType === 'video' ? 1024 : uploadType === 'image' ? 10 : 25;
                const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
                if (file.size >= maxSizeInBytes) {
                    toast.warn(`The file exceeds the maximum size of ${maxSizeInMB} MB.`);
                    return `The file exceeds the maximum size of ${maxSizeInMB} MB.`
                }
            }
            return true;
        },
        system: value => value ? true : 'System is required',
    };
    const messages = {
        name: 'First name is required',
        description: 'Description is required',
        cover: 'Cover is required',
        system: 'System is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages, 
            exceptions: ['isAnatomy']
        }
    );

    const categotyes = systems ? systems.map(item => ({ value: item?.name, label: item?.name })) : []
    const formDataHandler = (name, value) => {
       
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))

    }

    const clearState = () => {
        dispatch(clearMaterial())
        setFormData({
            file: null,
            title: '',
            description: '',
            system: null,
            isAnatomy: false
        })
    }
    useEffect(() => {
        if (material) {
            setFormData({
                file: {
                    preview: getImageSrc(material?.fileName),
                    type: `image/${material?.fileName.split('.').pop()}`
                },
                title: material?.title,
                description: material?.description,
                system: material?.filter?.name,
                isAnatomy: material?.isAnatomy
            })
        } else {
            clearState()
        }
    }, [material])
    const removeHandler = async (id) => {
        if (setDeleteModal) {
            handleClose()
            setDeleteModal()
        } else {
            const res = await dispatch(delMaterialLibraryByIdFeth(materialId)).unwrap()
            if (res) {
                handleClose()
                clearState()
            }
        }

    }
   
    useEffect(() => {
        if (materialId && !editModal) {
            dispatch(getMaterialLibraryByIdFeth(materialId))
        }
    }, [materialId])
    const submitHandler = async (e, createAnothe = false) => {
        e.preventDefault();
        if (errors) {
            setIsErrorShown(true)
            return
        }
        setIsLoading(true)
        const newData = { ...formData }
        delete newData.file
        if (formData.file instanceof File) {
            newData['file'] = formData.file
        }
        const body = getFormData(newData)
        if (uploadType === 'video') {
            toast.info('Uploading...', {
                toastId: 'uploadProgress', // Уникальный идентификатор для обновления
                autoClose: false, // Отключаем авто-закрытие
                closeButton: false, // Отключаем кнопку закрытия
                progress: 0, // Начальный прогресс
            });
        }

        let res = null
        if (materialId) {
            res = await dispatch(editMaterialLibraryByIdFeth({ id: materialId, body: body })).unwrap()
        } else {
            res = await dispatch(createMaterialLibraryFeth(body)).unwrap()
        }
        if (res) {
            if (createAnothe) {
                clearState()
                setIsErrorShown(true)
            } else {
                // dispatch(setMaterial(res))
                handleClose();
                setIsErrorShown(false)
            }
        }
        setIsLoading(false)
    }
    useEffect(() => {
        if (!systems) {
            dispatch(getFiltersByName('system'))
        }
    }, [dispatch])

    return (
        <ModalTemplate open={open} handelClose={handleClose} modalSize={'modal__dialog--870'}>
            <>
                <div className="modal__body pt-0">
                    <div className="modal__body-heading text-center">
                        <h2>{title}</h2>
                    </div>

                    <div className="row g-24">
                        <div className="col-12">
                            <UploadImage
                                isRow={true}
                                type={String(uploadType).toLocaleLowerCase()}
                                defaulImages={formData.file && formData.file !== 'null' ? [formData.file] : []}
                                onChange={(file) => { formDataHandler('file', ...file) }}
                                error={isErrorShown && errors?.file}
                                errorMessage={errorMessages?.file}
                                docTypesRemome={['application/pdf']}
                            />
                        </div>
                        <div className="col-lg-6">
                            <InputGroup
                                wrapperClasses={'input--lg'}
                                inputClasses={'input--outline'}
                                placeholder={'Enter your title...'}
                                label={'Title'}
                                onChange={({ target }) => formDataHandler('title', target.value)}
                                value={formData.title}
                                error={isErrorShown && errors?.title}
                                errorMessage={errorMessages?.title}
                            />
                        </div>
                        <div className="col-lg-6">
                            <SelectGroup
                                label={'Category'}
                                wrapperClasses={'select--outline input--lg'}
                                placeholder={'Category'}
                                options={categotyes}
                                defaultValue={material?.filter?.name ? { value: material?.filter?.name, label: material?.filter?.name } : null}
                                onChange={(option) => { formDataHandler('system', option.value) }}
                                error={isErrorShown && errors?.system}
                                errorMessage={errorMessages?.system}
                            />
                        </div>
                        <div className="col-12">
                            <TextareaGroup
                                wrapperClasses='input--lg'
                                label={'Description'}
                                textareaClasses={'input--outline p-[16px]'}
                                placeholder={'Enter your notes...'}
                                onChange={({ target }) => formDataHandler('description', target.value)}
                                value={formData.description}
                                error={isErrorShown && errors?.description}
                                errorMessage={errorMessages?.description}
                            />
                        </div>
                        <div className="col-12">
                            <CheckBoxGroup
                                checked={formData.isAnatomy}
                                onChange={(e) => {
                                    formDataHandler('isAnatomy', e.target.checked)
                                }}
                            >
                                <p className="check-text">
                                    Add to Anatomy section
                                </p>
                            </CheckBoxGroup>
                        </div>
                    </div>



                </div>

                <div className="modal__footer">
                    {materialId ?
                        <button type='button' disabled={isLoading} onClick={() => { removeHandler(materialId) }} className="btn btn--danger btn--md radius-5">
                            {
                                isLoading
                                    ? <ButtonLoader /> :
                                    'Delete'
                            }
                        </button>
                        :
                        <button
                            type='button'
                            onClick={handleClose}
                            className="btn btn--shadow btn--md radius-5"
                            disabled={isLoading}
                        >
                            {
                                isLoading
                                    ? <ButtonLoader /> :
                                    'Cancel'
                            }
                        </button>}
                    <button type='button' onClick={submitHandler} className="btn btn--primary btn--md radius-5 md:ml-auto" disabled={isLoading}>
                        {
                            isLoading
                                ? <ButtonLoader /> :
                                'Save'
                        }
                    </button>
                    {!editModal && <button
                        type='button'
                        onClick={(e) => {
                            submitHandler(e, true)
                        }}
                        className="btn btn--primary btn--md radius-5"
                        disabled={isLoading}
                    >
                        {
                            isLoading
                                ? <ButtonLoader /> :
                                'Save and Add Another'
                        }
                    </button>}
                </div>
            </>
        </ModalTemplate>
    )
}
