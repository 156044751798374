import axios from "axios";
import { apiConfig } from "./config";
import { auth } from "./parts/auth";
import { users } from "./parts/users";
import { professions } from "./parts/professions";
import { pages } from "./parts/pages";
import { contactEmails } from "./parts/contactEmails";
import { contactUs } from "./parts/contactUs";
import { flashcards } from "./parts/flashCards";
import { filters } from "./parts/filters";
import { quizzes } from "./parts/quizzes";
import { articles } from "./parts/articles";
import { materialLibrary } from "./parts/materialLibrary";
import { comments } from "./parts/comments";
import { cases } from "./parts/cases";
import { courses } from "./parts/courses";
import { profile } from "./parts/profile";
import { paymant } from "./parts/paymant";
import { subscriptionPlan } from "./parts/subscriptionPlan";
import { notifications } from "./parts/notifications";
import { chat } from "./parts/chat";
import { forums } from "./parts/forums";
import { events } from "./parts/events";
import { search } from "./parts/search";
import { charts } from "./parts/charts";
import { reports } from "./parts/reports";
import { saveTables } from "./parts/saveTables";

export const apiInstance = axios.create(apiConfig);
apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const api = {
    auth: auth(apiInstance),
    users: users(apiInstance),
    professions: professions(apiInstance),
    pages: pages(apiInstance),
    contactEmails: contactEmails(apiInstance),
    contactUs: contactUs(apiInstance),
    flashcards: flashcards(apiInstance),
    filters: filters(apiInstance),
    quizzes: quizzes(apiInstance),
    articles: articles(apiInstance),
    materialLibrary: materialLibrary(apiInstance),
    comments: comments(apiInstance),
    cases: cases(apiInstance),
    courses: courses(apiInstance),
    profile: profile(apiInstance),
    paymant: paymant(apiInstance),
    subscriptionPlan: subscriptionPlan(apiInstance),
    notifications: notifications(apiInstance),
    chat: chat(apiInstance),
    forums: forums(apiInstance),
    events: events(apiInstance),
    search: search(apiInstance),
    charts: charts(apiInstance),
    reports: reports(apiInstance),
    saveTables: saveTables(apiInstance)
}

export default api;