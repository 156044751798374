import { createSlice } from '@reduxjs/toolkit';
import { deleteConversationFeth, getAllConversationsFeth, getConversationByIdFeth, getUnreadCountFeth, messagesMarkAsReadFeth, searchFilesFeth, searchInfoFeth, searchLinksFeth, searchMessagesFeth, sendMessageByConversationIdFeth } from './chatThunk';
import { toggleUserBlockFeth } from '../profile/profileThunk';


const initialState = {
    allChats: [],
    currentChatData: null,
    chatUsersAll: [],
    curretnChatMessages: [],
    chatInfoUser: null,
    searchMessageId: null,
    newMessagesCount:0,
    isLoading: false
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setNewMessageToCats: (state, action) => {
            state.allChats[action.payload.index].lastMessage = action.payload.message.content
            
            if(action.payload.message.userConversationId === state.currentChatData.conversation.id) {
                const newMessage = {
                    ...action.payload.message,
                    isRead: true,
                }
                state.curretnChatMessages.push(newMessage)
                state.allChats[action.payload.index].unreadMessagesCount = 0
                state.currentChatData.messages.push(newMessage) 
            } else {
                state.allChats[action.payload.index].unreadMessagesCount  += 1
            }
        },
        setNewMessagesCount: (state, action) => {
            state.newMessagesCount = action.payload
        },
        setInfoUser: (state, action) => {
            state.chatInfoUser = action.payload
        },
        setSearchMessageId: (state, action) => {
            state.searchMessageId = action.payload
        }
    },

    extraReducers(builder) {
       builder
        .addCase(getAllConversationsFeth.fulfilled, (state, action) => {
            
            state.isLoading = false
            if(action.payload?.pagination.page === 1) {
                state.allChats = action.payload?.data.conversations
            } else {
                state.allChats = [...state.allChats ,...action.payload?.data.conversations]
            }
            state.chatUsersAll = action.payload?.data.users
        } )
        .addCase(getAllConversationsFeth.pending, (state, action) => {
            
            state.isLoading = true
        })
        .addCase(getAllConversationsFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(getConversationByIdFeth.fulfilled, (state, action) => {
            if(action.payload?.data) {
                state.currentChatData = action.payload?.data
                if(action.payload?.pagination.page === 1 || state.searchMessageId) {
                    state.curretnChatMessages = action.payload?.data.messages.toReversed()
                } else {
                    state.curretnChatMessages = [...action.payload?.data.messages.toReversed(), ...state.curretnChatMessages]
                }
            }
            state.isLoading = false
        } )
        .addCase(getConversationByIdFeth.pending, (state, action) => {
            state.currentChatData = null
            state.isLoading = true
        })
        .addCase(getConversationByIdFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(sendMessageByConversationIdFeth.fulfilled, (state, action) => {
            state.curretnChatMessages.push(action.payload)
            const currentDialog = state.allChats.find(dialog => dialog.id === action.payload.userConversationId)
            if(currentDialog) {
                currentDialog.lastMessage = action.payload.content
            }
        } )
        .addCase(sendMessageByConversationIdFeth.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(sendMessageByConversationIdFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(messagesMarkAsReadFeth.fulfilled, (state, action) => {
            const currentDialog = state.allChats.find(dialog => dialog.id === action.payload.userConversationId)
            if(currentDialog) {
                currentDialog.unreadMessagesCount = action.payload.unreadMessagesCount
            }
        } )
        .addCase(searchInfoFeth.fulfilled, (state, action) => {
            state.chatInfoUser = action.payload?.data
        } )
        .addCase(searchInfoFeth.pending, (state, action) => {
            state.chatInfoUser = null
            state.isLoading = true
        })
        .addCase(searchInfoFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(searchMessagesFeth.fulfilled, (state, action) => {
            if(action.payload.page === 1) {
                state.chatInfoUser = action.payload?.data
            } else {
                state.chatInfoUser = [...state.chatInfoUser, ...action.payload?.data]
            }
            
        } )
        .addCase(searchMessagesFeth.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(searchMessagesFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(searchFilesFeth.fulfilled, (state, action) => {
            if(action.payload.page === 1) {
                state.chatInfoUser = action.payload?.data
            } else {
                state.chatInfoUser = [...state.chatInfoUser, ...action.payload?.data]
            }
            
        } )
        .addCase(searchFilesFeth.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(searchFilesFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(searchLinksFeth.fulfilled, (state, action) => {
            if(action.payload.page === 1) {
                state.chatInfoUser = action.payload?.data
            } else {
                state.chatInfoUser = [...state.chatInfoUser, ...action.payload?.data]
            }
            
        } )
        .addCase(searchLinksFeth.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(searchLinksFeth.rejected, (state, action) => {
            state.isLoading = false
        } )
        .addCase(deleteConversationFeth.fulfilled, (state, action) => {
            state.allChats = state.allChats.filter(chat => chat.id != action.payload.id)
        } )
        .addCase(getUnreadCountFeth.fulfilled, (state, action) => {
            if(action.payload) {
                state.newMessagesCount = action.payload?.unreadMessagesCount
            }
            
        } )
        .addCase(toggleUserBlockFeth.fulfilled, (state, action) => {
            if(state.currentChatData) {
                state.currentChatData.conversation.isBlocked = action.payload.isBlocked
            }
        })
    }
})

export const {
    setNewMessageToCats,
    setNewMessagesCount,
    setInfoUser,
    setSearchMessageId
} = chatSlice.actions;
export const getNewMessagesCount = (state) => state.chat.newMessagesCount;

export default chatSlice.reducer