import { createSlice } from '@reduxjs/toolkit';
import { getNewNotificationsCountFeth, getNotificationsPageContentFeth, getUserNotificationsFeth, notificationsMarkAsReadAllFeth, notificationsMarkAsReadOneFeth } from './notificationsThunk';
import { managingModeratorStatusFeth } from '../forums/forumsThunk';
import { manageAuthorStatusFeth, manageEditorStatusFeth } from '../articles/articlesThunks';



const initialState = {
    notificationsAll: [],
    isLoading: false,
    pageContent: [],
    unreadCount: 0,
    newNotifications: 0,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNewNotifications: (state, action) => {
            state.newNotifications = action.payload
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getUserNotificationsFeth.fulfilled, (state, action) => {
                if(action.payload.pagination.page === 1) {
                    state.notificationsAll = action.payload.data.notifications
                } else {
                    state.notificationsAll = [...state.notificationsAll, ...action.payload.data.notifications]
                }
                
                state.pagination = action.payload.pagination
            })
            .addCase(getNotificationsPageContentFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.pageContent = action.payload
            })
            .addCase(getNotificationsPageContentFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getNotificationsPageContentFeth.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(notificationsMarkAsReadAllFeth.fulfilled, (state, action) => {
                state.newNotifications = 0
                state.notificationsAll = state.notificationsAll.map(item => ({
                    ...item,
                    isRead: true
                }))
            })
            .addCase(getNewNotificationsCountFeth.fulfilled, (state, action) => {
                if (action.payload?.newNotifications) {
                    state.newNotifications = action.payload.newNotifications
                }
            })
            .addCase(notificationsMarkAsReadOneFeth.fulfilled, (state, action) => {
                if (action.payload) {
                    state.newNotifications = state.newNotifications - 1
                    const currentNote = state.notificationsAll.find(note => note.id === action.payload.id)
                    currentNote.isRead = true
                }
            })
            .addCase(managingModeratorStatusFeth.fulfilled, (state, action) => {
                if (action.payload) {
                    const currentNote = state.notificationsAll.find(note => note.id === action.payload.id)
                    currentNote.isProcessed = true
                }
            })
            .addCase(manageEditorStatusFeth.fulfilled, (state, action) => {
                if (action.payload) {
                    const currentNote = state.notificationsAll.find(note => note.id === action.payload.id)
                    currentNote.isProcessed = true
                }
            })
            .addCase(manageAuthorStatusFeth.fulfilled, (state, action) => {
                if (action.payload) {
                    const currentNote = state.notificationsAll.find(note => note.id === action.payload.id)
                    currentNote.isProcessed = true
                }
            })
    }
})

export const {
    setNewNotifications
} = notificationsSlice.actions;

export const getNewNotifications = (state) => state.notifications.newNotifications;
export default notificationsSlice.reducer