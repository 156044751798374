import React, { useEffect, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import { ReactComponent as UploadIcon } from "../../assets/img/ico-image-upload.svg"
import { ReactComponent as DotsIcon } from "../../assets/img/ico-dots-2.svg"
import { ReactComponent as TrashIcon } from "../../assets/img/ico-trash.svg"
import { useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import ButtonLoader from '../UI/ButtonLoader'
import { getImageSrc } from '../../helpers/utils'
import SortQuizAnsvers from '../parts/SortQuizAnsvers'
import { addQuizQuestionsByQuizIdFeth, addQuizQuestionsOrderingByQuizIdFeth, deleteQuizQuestionsByQuestionIdFeth, editQuizQuestionsByQuestionIdFeth } from '../../store/quizzes/quizzesThunks'
import { setActiveQuestionId } from '../../store/quizzes/quizzesSlice'

const AddQuestionModal = ({ handelClose, open}) => {
    const [quizId, quiz] = useOutletContext();
    const dispach = useDispatch()
    const { questions, activeQuestionId, isLoadingQuestin } = useSelector((state) => state.quizzes)
    const [formData, setFormData] = useState({
        question_text: '',
        question_image: null,
        order: questions?.length || 0,
        answers: []
    })
    const [errors, setErrors] = useState({})
    const [newAnswer, setNewAnswer] = useState({
        answer_text: '',
        answer_image: null,
        correct_answer: false,

    })
    const questionHandler = (name, value) => {
        if(name === 'question_text') {
            setErrors(prev => ({
                ...prev,
                question_text: value?.trim().length > 0 ? false : 'Question text is required'
            }))
        } 
       
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const addNewAnswer = (e) => {
        e.preventDefault()
        if (formData.answers.length >= 1) {
            setErrors(prev => ({
                ...prev,
                answers: false
            }))
        }

        setFormData(prev => ({
            ...prev,
            answers: [
                ...prev.answers,
                {
                    ...newAnswer,
                    order: formData.answers.length
                }
            ]
        }))
        setNewAnswer({
            answer_text: '',
            answer_image: null,
            correct_answer: false,
        })
    }
    const removeHandler = async (id) => {
        const filteredQuestions = questions.filter(item => item.id !== id)
        const res = await dispach(deleteQuizQuestionsByQuestionIdFeth(id)).unwrap();
        if(res) {
            const orderingData = filteredQuestions.map((item, index) => ({
                questionId: item.id,
                order: index
            }))
            const res2 = await  dispach(addQuizQuestionsOrderingByQuizIdFeth({id: quizId, body: {
                "ordering" : orderingData
            }})).unwrap()
            handelClose() 
        }
    }
    const newAnsweHandler = (name, value) => {
        setNewAnswer(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const clearState = () => {
        dispach(setActiveQuestionId(null))
        setFormData({
            question_text: '',
            question_image: null,
            answers: [],
            order: questions?.length || 0,
        })
    }
    const validate = () => {
        const newErrors = {};

        if (formData.question_text.trim().length === 0) {
            newErrors.question_text = 'Question text is required';
        }

        if (!Array.isArray(formData.answers) || formData.answers.length < 2) {
            newErrors.answers = 'there must be more than 1 answers';
        } else {
            let hasCorrectAnswer = false;

            formData.answers.forEach((answer, index) => {
                if (answer.answer_text.trim().length === 0) {
                    newErrors[`answers_${index}_answer_text`] = `Answer text is required`;
                }
                if (answer.correct_answer) {
                    hasCorrectAnswer = true;
                }
            });

            if (!hasCorrectAnswer) {
                newErrors.correct_answer = 'At least one answer must be marked as correct';
            }
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };
    const submitHandler = async (e) => {
        e.preventDefault()

        if (!validate()) {

            return
        }
        const answer_images = []
        const newAnswers = formData.answers.map((item, index) => {
            if (item.answer_image && typeof item.answer_image !== 'string') {
               
                answer_images.push(item.answer_image)
                return {
                    ...item,
                    answer_image:  item.answer_image.name.slice(0,item.answer_image.name.lastIndexOf("."))
                }
            }
            else {
                // newAnswers.push(item)
                return item
            }
        })
        
        
        const body = new FormData()
        body.append('answers', JSON.stringify(newAnswers))
        answer_images.forEach(item => {
            body.append('answer_images', item)
        })
        body.append('question_text', formData.question_text)
        body.append('question_image', formData.question_image)
        body.append('order', formData.order)
       
        let res = null;
        
        if (activeQuestionId) {
           
            res = await dispach(editQuizQuestionsByQuestionIdFeth({ id: activeQuestionId, body: body })).unwrap()
        } else {
            res = await dispach(addQuizQuestionsByQuizIdFeth({ id: quizId, body: body })).unwrap()
        }
        if (res) {
            
        }
    }
    
    useEffect(() => {
        if (activeQuestionId) {
            setFormData(questions.find(item => item.id === activeQuestionId))
            
        } else {
            setFormData({
                question_text: '',
                question_image: null,
                answers: [],
                order: questions?.length || 0,
            })
        }
        setErrors({})
    }, [activeQuestionId])

    return (
        <ModalTemplate open={open} lightGreyBg={false} handelClose={handelClose} modalSize='modal__dialog--870'>
            <div className="modal__body items-center pt-0">
                <div className="modal__body-heading text-center">
                    {activeQuestionId ? <h2>Edit question</h2> : <h2>Add new question</h2>}
                </div>
                <div className="list-group list-group--lg w-full">
                    <div className="form-group-row ">
                        <span className="form-group-row__label justify-end">Question</span>
                        <div className="list-group list-group--xs flex-auto">
                            <div className="actions-group">
                                <div className="input-wrapper input--md flex-auto">
                                    <input className="input input--outline"
                                        onChange={({ target }) => questionHandler('question_text', target.value)}
                                        type="text" placeholder="Enter option here..." value={formData?.question_text} />
                                </div>
                                {formData?.question_image ? <div className="image-preview-group">
                                    <img src={getImageSrc(formData.question_image)} alt="" />
                                    <button
                                        type='button'
                                        onClick={({ target }) => questionHandler('question_image', null)}
                                        className="btn color-danger">
                                        <TrashIcon />
                                    </button>
                                </div> :
                                    <label className="btn btn--square btn--secondary-2 btn--md">
                                        <input type="file" accept="image/*" onChange={({ target }) => questionHandler('question_image', target.files[0])} hidden />
                                        <UploadIcon />
                                    </label>
                                }
                            </div>
                            {errors.question_text && <p className='color-danger text--xs mt-1'>{errors.question_text}</p>}
                        </div>
                    </div>
                    <div className="form-group-row w-full">
                        <span className="form-group-row__label justify-end">Answer options</span>
                        <div className="list-group list-group--xs flex-auto">
                            {formData.answers.length > 0 &&
                                <SortQuizAnsvers errors={errors} setItems={setFormData} setErrors={setErrors} items={formData} />
                            }
                            {errors.answers && <p className='color-danger text--xs mt-1'>{errors.answers}</p>}
                            <div className="actions-group">
                                <div className="input-wrapper input--md flex-auto">
                                    <input className="input input--outline"
                                        type="text"
                                        placeholder="Enter option here..."
                                        value={newAnswer.answer_text}
                                        onChange={({ target }) => { newAnsweHandler('answer_text', target.value) }}
                                    />
                                </div>
                                {newAnswer.answer_image ? <div className="image-preview-group">
                                    <img src={getImageSrc(newAnswer.answer_image)} alt="" />
                                    <button
                                        type='button'
                                        onClick={({ target }) => { newAnsweHandler('answer_image', null) }}
                                        className="btn color-danger">
                                        <TrashIcon />
                                    </button>
                                </div> :
                                    <label className="btn btn--square btn--secondary-2 btn--md">
                                        <input type="file"
                                            onChange={({ target }) => { newAnsweHandler('answer_image', target.files[0]) }}
                                            accept="image/*"
                                            hidden />
                                        <UploadIcon />
                                    </label>}
                                <button type='button' onClick={addNewAnswer} className="btn btn--primary btn--square btn--md">
                                    <PlusIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                {activeQuestionId ?
                    <button type='button' disabled={isLoadingQuestin} onClick={() => { removeHandler(activeQuestionId) }} className="btn btn--danger btn--md radius-5">
                        {
                            isLoadingQuestin
                                ? <ButtonLoader /> :
                                'Delete'
                        }
                    </button>
                    :
                    <button
                        type='button'
                        onClick={handelClose}
                        className="btn btn--shadow btn--md radius-5"
                        disabled={isLoadingQuestin}
                    >
                        {
                            isLoadingQuestin
                                ? <ButtonLoader /> :
                                'Cancel'
                        }
                    </button>}
                <button type='button'
                    onClick={submitHandler}
                    className="btn btn--primary btn--md radius-5 md:ml-auto"
                    disabled={isLoadingQuestin}
                >
                    {
                        isLoadingQuestin
                            ? <ButtonLoader /> :
                            'Save'
                    }

                </button>
                <button type='button'
                    onClick={(e) => {
                        submitHandler(e)
                        clearState()
                    }}
                    className="btn btn--primary btn--md radius-5" disabled={isLoadingQuestin}>
                    {
                        isLoadingQuestin
                            ? <ButtonLoader /> :
                            'Save and Add Another'
                    }
                </button>
            </div>
        </ModalTemplate>
    )
}

export default AddQuestionModal