import { ButtonView, Command, Plugin, ResizeObserver } from "ckeditor5"

export default class SaveTableButton extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('saveTableButton', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Save Table',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13V17H7V13H3ZM1 13V12V8V7V2.5C1 1.67157 1.67157 1 2.5 1H7H8H12H13H17.5C18.3284 1 19 1.67157 19 2.5V7V8V9.3H17V8H13V9.3H12.5067C12.3753 9.29959 12.2452 9.32528 12.1238 9.3756C12.0811 9.39334 12.0397 9.414 12 9.43741V8H8V12H9.43733C9.41387 12.0398 9.39317 12.0812 9.37541 12.124C9.32509 12.2454 9.29939 12.3755 9.29981 12.5069V13H8V17H9.29981V19H8H7H2.5C1.67157 19 1 18.3284 1 17.5V13ZM7 12V8L3 8V12H7ZM8 3H12V7H8V3ZM13 3V7H17V3H13ZM7 7V3H3V7L7 7Z" fill="currentColor"/>
                <path d="M19.25 11H13.4052C13.3066 10.9997 13.209 11.019 13.118 11.0567C13.027 11.0944 12.9444 11.1499 12.875 11.2198L11.2198 12.875C11.1499 12.9444 11.0944 13.027 11.0567 13.118C11.019 13.209 10.9997 13.3066 11 13.4052V19.25C11 19.4489 11.079 19.6397 11.2197 19.7803C11.3603 19.921 11.5511 20 11.75 20H19.25C19.4489 20 19.6397 19.921 19.7803 19.7803C19.921 19.6397 20 19.4489 20 19.25V11.75C20 11.5511 19.921 11.3603 19.7803 11.2197C19.6397 11.079 19.4489 11 19.25 11ZM15.5 18.125C15.2033 18.125 14.9133 18.037 14.6666 17.8722C14.42 17.7074 14.2277 17.4731 14.1142 17.199C14.0007 16.9249 13.9709 16.6233 14.0288 16.3324C14.0867 16.0414 14.2296 15.7741 14.4393 15.5643C14.6491 15.3546 14.9164 15.2117 15.2074 15.1538C15.4983 15.0959 15.7999 15.1257 16.074 15.2392C16.3481 15.3527 16.5824 15.545 16.7472 15.7916C16.912 16.0383 17 16.3283 17 16.625C17 17.0228 16.842 17.4044 16.5607 17.6857C16.2794 17.967 15.8978 18.125 15.5 18.125ZM17.5625 13.25C17.5625 13.2997 17.5427 13.3474 17.5076 13.3826C17.4724 13.4177 17.4247 13.4375 17.375 13.4375H13.625C13.5753 13.4375 13.5276 13.4177 13.4924 13.3826C13.4573 13.3474 13.4375 13.2997 13.4375 13.25V11.75H17.5625V13.25Z" fill="currentColor"/></svg>`,
                tooltip: true
            });

            view.on('execute', () => {
                // console.log('Table button clicked!');
                editor.execute('openSaveModal');
            });

            return view;
        });
    }
}