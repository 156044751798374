import React, { useEffect, useRef, useState } from 'react'
import InputGroup from '../components/UI/formElements/InputGroup'
import CheckBoxGroup from '../components/UI/formElements/CheckBoxGroup'
import RadioButtonQuiz from '../components/UI/formElements/RadioButtonQuiz'
import InputGroupRow from '../components/UI/formElements/InputGroupRow'
import TextareaGroupRow from '../components/UI/formElements/TextareaGroupRow'
import TextareaGroup from '../components/UI/formElements/TextareaGroup'
import RadioDefault from '../components/UI/formElements/RadioDefault'
import SelectGroup from '../components/UI/formElements/SelectGroup'
import SelectGroupRow from '../components/UI/formElements/SelectGroupRow'
import SwithGroup from '../components/UI/formElements/SwithGroup'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { AccessibilityHelp, AutoLink, Autosave, Bold, ClassicEditor, Essentials, Italic, Link, Paragraph, SelectAll, Undo } from 'ckeditor5'

import 'ckeditor5/ckeditor5.css';
const Ui = () => {
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);
    const editorConfig = {
        toolbar: {
            items: ['undo', 'redo', ],
            // shouldNotGroupWhenFull: false
        },
        plugins: [Bold, Italic, Link, Paragraph, Undo],
        initialData:
            '<h2>Congratulations on setting up CKEditor 5! 🎉</h2>\n<p>\n    You\'ve successfully created a CKEditor 5 project. This powerful text editor will enhance your application, enabling rich text editing\n    capabilities that are customizable and easy to use.\n</p>\n<h3>What\'s next?</h3>\n<ol>\n    <li>\n        <strong>Integrate into your app</strong>: time to bring the editing into your application. Take the code you created and add to your\n        application.\n    </li>\n    <li>\n        <strong>Explore features:</strong> Experiment with different plugins and toolbar options to discover what works best for your needs.\n    </li>\n    <li>\n        <strong>Customize your editor:</strong> Tailor the editor\'s configuration to match your application\'s style and requirements. Or even\n        write your plugin!\n    </li>\n</ol>\n<p>\n    Keep experimenting, and don\'t hesitate to push the boundaries of what you can achieve with CKEditor 5. Your feedback is invaluable to us\n    as we strive to improve and evolve. Happy editing!\n</p>\n<h3>Helpful resources</h3>\n<ul>\n    <li>📝 <a href="https://orders.ckeditor.com/trial/premium-features">Trial sign up</a>,</li>\n    <li>📕 <a href="https://ckeditor.com/docs/ckeditor5/latest/installation/index.html">Documentation</a>,</li>\n    <li>⭐️ <a href="https://github.com/ckeditor/ckeditor5">GitHub</a> (star us if you can!),</li>\n    <li>🏠 <a href="https://ckeditor.com">CKEditor Homepage</a>,</li>\n    <li>🧑‍💻 <a href="https://ckeditor.com/ckeditor-5/demo/">CKEditor 5 Demos</a>,</li>\n</ul>\n<h3>Need help?</h3>\n<p>\n    See this text, but the editor is not starting up? Check the browser\'s console for clues and guidance. It may be related to an incorrect\n    license key if you use premium features or another feature-related requirement. If you cannot make it work, file a GitHub issue, and we\n    will help as soon as possible!\n</p>\n',
        // link: {
        //     addTargetToExternalLinks: true,
        //     defaultProtocol: 'https://',
        //     decorators: {
        //         toggleDownloadable: {
        //             mode: 'manual',
        //             label: 'Downloadable',
        //             attributes: {
        //                 download: 'file'
        //             }
        //         }
        //     }
        // },
        placeholder: 'Type or paste your content here!'
    };
    
    return (
        <>
            <div className="container">
                <div className="flex flex-col flex-wrap gap-4 p-8">
                    <div>
                        <div className="main-container">
                            <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
                                <div className="editor-container__editor">
                                    <div>
                                        <CKEditor
                                            onReady={() => {
                                                console.log('ready')
                                            }}
                                            editor={ClassicEditor}
                                            config={editorConfig}
                                            // onReady={(editor) => {
                                            //     editorRef.current = editor;
                                            // }}
                                        />
                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex gap-5">
                        <InputGroup
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--outline'}
                            placeholder={'Nur'}
                            label={'First Name'}
                        />
                        <CheckBoxGroup
                            wrapperClasses={'input--lg'}

                        >
                            <p className="check-text">
                                Remember me
                            </p>
                        </CheckBoxGroup>

                    </div>

                    <div className="flex flex-col gap-2">
                        <RadioButtonQuiz
                            name={'question'}
                            value={'question1'}
                        >
                            <span className="quiz-answer-item__text">
                                <span className="quiz-answer-item__id">B)</span>
                                <span>2 months</span>
                            </span>
                        </RadioButtonQuiz>
                    </div>
                    <InputGroupRow
                        label={'Email'}
                        tips={'For multiple addresses please separate with “,”'}
                        inputClasses={'input--outline'}
                        placeholder={'Enter user email address(es)...'}
                    />
                    <TextareaGroupRow
                        label={'Additional Message'}
                        placeholder={'Enter your description...'}
                        textareaClasses={'input--outline py-[16px]'}
                    />
                    <TextareaGroup
                        textareaClasses={'input--outline p-[16px]'}
                        placeholder={'Enter your notes...'}
                    />
                    <RadioDefault>
                        <div className="w-full">
                            <p className="text--sm mb-[6px]">Send to the existing email</p>
                            <p className="color-secondary text--xs">Nur.Alam@mail.com</p>
                        </div>
                    </RadioDefault>
                    <SelectGroup
                        label={'Billing address'}
                        wrapperClasses={'select--outline input--lg'}
                        options={[
                            { value: "value1", label: "value1" },
                            { value: "value2", label: "value2" },
                        ]}
                    />
                    <SelectGroup
                        // label={'Billing address'}
                        wrapperClasses={'input--lg'}
                        options={[
                            { value: "value1", label: "value1" },
                            { value: "value2", label: "value2" },
                        ]}
                    />
                    <SelectGroupRow
                        groupClasses={'items-center gap--lg md:flex-nowrap'}
                        label={'Billing address'}
                        wrapperClasses={'select--outline input--md w-full'}
                        options={[
                            { value: "value1", label: "value1" },
                            { value: "value2", label: "value2" },
                        ]}
                    />
                    <SwithGroup
                        wrapperClasses='w-auto min-w-[163px]'
                        leftNode={
                            <>
                                <span className="ico ico-18">
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.5625 7.3125H4.5C3.67125 7.3125 3 6.64125 3 5.8125V3.75C3 2.92125 3.67125 2.25 4.5 2.25H6.5625C7.39125 2.25 8.0625 2.92125 8.0625 3.75V5.8125C8.0625 6.64125 7.39125 7.3125 6.5625 7.3125Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3125H12.9375C12.1087 7.3125 11.4375 6.64125 11.4375 5.8125V3.75C11.4375 2.92125 12.1087 2.25 12.9375 2.25H15C15.8288 2.25 16.5 2.92125 16.5 3.75V5.8125C16.5 6.64125 15.8288 7.3125 15 7.3125Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.5625 15.75H4.5C3.67125 15.75 3 15.0788 3 14.25V12.1875C3 11.3587 3.67125 10.6875 4.5 10.6875H6.5625C7.39125 10.6875 8.0625 11.3587 8.0625 12.1875V14.25C8.0625 15.0788 7.39125 15.75 6.5625 15.75Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15 15.75H12.9375C12.1087 15.75 11.4375 15.0788 11.4375 14.25V12.1875C11.4375 11.3587 12.1087 10.6875 12.9375 10.6875H15C15.8288 10.6875 16.5 11.3587 16.5 12.1875V14.25C16.5 15.0788 15.8288 15.75 15 15.75Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span className="switch-control--text">Grid</span>
                            </>
                        }
                        rightNode={
                            <>
                                <span className="ico ico-18">
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49927 4.82752H15.7527" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M15.7527 9H7.49927" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.49927 13.1725H15.7527" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M4.12271 4.45236C3.91552 4.45236 3.74756 4.62033 3.74756 4.82752C3.74756 5.03471 3.91552 5.20267 4.12271 5.20267C4.32991 5.20267 4.49787 5.03471 4.49787 4.82752C4.49787 4.62033 4.32991 4.45236 4.12271 4.45236" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M4.12271 8.62485C3.91552 8.62485 3.74756 8.79281 3.74756 9C3.74756 9.2072 3.91552 9.37516 4.12271 9.37516C4.32991 9.37516 4.49787 9.2072 4.49787 9C4.49787 8.79281 4.32991 8.62485 4.12271 8.62485" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M4.12271 12.7973C3.91552 12.7973 3.74756 12.9653 3.74756 13.1725C3.74756 13.3797 3.91552 13.5476 4.12271 13.5476C4.32991 13.5476 4.49787 13.3797 4.49787 13.1725C4.49787 12.9653 4.32991 12.7973 4.12271 12.7973" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span className="switch-control--text">List</span>
                            </>
                        }
                    /> */}

                </div>
            </div>
        </>
    )
}

export default Ui
