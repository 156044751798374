import { ButtonView, Command, Plugin, ResizeObserver } from "ckeditor5"

export default class InsertSavedTable extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('insertSavedTable', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Saved Table',
                icon:  `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 13V17H7V13H3ZM1 13V12V8V7V2.5C1 1.67157 1.67157 1 2.5 1H7H8H12H13H17.5C18.3284 1 19 1.67157 19 2.5V7V8V9.25469C18.396 8.83338 17.7224 8.50489 17 8.28988V8H15H13V8.28988C12.6548 8.39263 12.3207 8.5213 12 8.67363V8H8V12H8.67363C8.5213 12.3207 8.39263 12.6548 8.28988 13H8V15V17H8.28988C8.50489 17.7224 8.83338 18.396 9.25469 19H8H7H2.5C1.67157 19 1 18.3284 1 17.5V13ZM7 12V8L3 8V12H7ZM8 3H12V7H8V3ZM13 3V7H17V3H13ZM7 7V3H3V7L7 7Z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6321 10.0227C14.9224 9.93669 14.2046 10.0966 13.5985 10.4757C13.0342 10.8286 12.5985 11.3517 12.3529 11.9675C12.046 12.0013 11.7471 12.0886 11.4698 12.2259C11.1589 12.3799 10.8813 12.5936 10.6529 12.8548C10.1917 13.3824 9.959 14.0716 10.0059 14.7708C10.0529 15.4699 10.3757 16.1218 10.9032 16.583C11.4258 17.0399 12.107 17.2725 12.7993 17.2313C13.0151 17.0619 13.2853 16.9643 13.5715 16.9643V15.5357C13.5715 14.7467 14.2111 14.1071 15 14.1071C15.789 14.1071 16.4286 14.7467 16.4286 15.5357V16.9643C16.7145 16.9643 16.9845 17.0617 17.2001 17.2308H17.7004H17.7025C18.2681 17.2274 18.8128 17.0168 19.2335 16.6387C19.6542 16.2606 19.9217 15.7415 19.9854 15.1794C20.0489 14.6174 19.9043 14.0515 19.5786 13.589C19.2799 13.1646 18.8479 12.8542 18.3533 12.7058C18.2637 12.0449 17.9649 11.4287 17.4986 10.9485C17.0007 10.4355 16.3419 10.1087 15.6321 10.0227ZM15.5357 15.5357C15.5357 15.2399 15.2959 15 15 15C14.7041 15 14.4643 15.2399 14.4643 15.5357V17.8571H13.5714C13.427 17.8571 13.2968 17.9441 13.2415 18.0776C13.1862 18.2111 13.2168 18.3647 13.3189 18.4669L14.7475 19.8954C14.8869 20.0349 15.1131 20.0349 15.2526 19.8954L16.6811 18.4669C16.7833 18.3647 16.8138 18.2111 16.7585 18.0776C16.7033 17.9441 16.573 17.8571 16.4286 17.8571H15.5357V15.5357Z" fill="currentColor"/>
                        </svg>`,
                tooltip: true
            });

            view.on('execute', () => {
                editor.execute('openInsertSavedTableModal');
            });

            return view;
        });
    }
}