import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Chart from 'chart.js/auto';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	AutoImage,
	AutoLink,
	Autosave,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	Essentials,
	GeneralHtmlSupport,
	Heading,
	HtmlComment,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SelectAll,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import ChartPlugin from '../../hooks/ckEditorPlagin';
import AddChartModal from '../Modals/AddChartModal';
import ThisCustomUploadAdapterPlugin from '../../hooks/uploadAdapter';
import VideoUploadPlugin from '../../hooks/CkEditorVideoPlagin';
import MyCustomPlugin from '../../hooks/MyCustomPlugin';
import { initializeChart } from '../../hooks/creacteChartFn';
import TableRowsResize from '../../hooks/TableRowsResize';
import SaveTableButton from '../../hooks/SaveTableButton';
import OptionsTableButton from '../../hooks/TableOptionsButton';
import SaveTableModal from '../Modals/SaveTableModal';
import InsertSavedTable from '../../hooks/InsertSavedTable';
import InsertSavedTableModal from '../Modals/InsertSavedTableModal';
import TableOptionsModal from '../Modals/TableOptionsModal';
import InsertBelowTableButton from '../../hooks/InsertBelowTableButton';

const TextEditor = ({ editorData, setData }) => {
	const [chartModal, setChartModal] = useState(false)
	const [saveTableModal, setSaveTableModal] = useState(false)
	const [insetrTableModal, setInsetrTableModal] = useState(false)
	const [optionsTableModal, setOptionsTableModal] = useState(false)
	const handleEditorChange = (editor) => {
		const data = editor.getData();
		setData(data);
	};
	const [chartData, setChartData] = useState(null);
	const imageRef = useRef(null);
	const imageWrapperRef = useRef(null)
	const initializedCharts = useRef(new WeakMap());
	const handleChartModalClose = () => {
		setChartModal(false)
		setChartData(null)
		imageRef.current = null
	}

	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);

	useEffect(() => {
		setIsLayoutReady(true);

		return () => setIsLayoutReady(false);
	}, []);

	const editorConfig = {
		toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'selectAll',
				'|',
				'heading',
				'|',
				'bold',
				'italic',
				'underline',
				'removeFormat',
				'|',
				'link',
				'insertImage',
				'mediaEmbed',
				'uploadVideo',
				'insertTable',
				'insertSavedTable',
				'blockQuote',
				'insertChart',
				'|',
				'alignment',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent',
				'|',
				'accessibilityHelp'
			],
			shouldNotGroupWhenFull: false
		},
		plugins: [
			AccessibilityHelp,
			Alignment,
			AutoImage,
			AutoLink,
			Autosave,
			// Base64UploadAdapter,
			BlockQuote,
			Bold,
			Essentials,
			GeneralHtmlSupport,
			Heading,
			HtmlComment,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			MediaEmbed,
			Paragraph,
			PasteFromOffice,
			RemoveFormat,
			SelectAll,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			TodoList,
			Underline,
			Undo,
			ChartPlugin,
			// ThisCustomUploadAdapterPlugin
			VideoUploadPlugin,
			TableRowsResize,
			SaveTableButton,
			OptionsTableButton,
			InsertSavedTable,
			InsertBelowTableButton
		],
		heading: {
			options: [
				{
					model: 'paragraph',
					title: 'Paragraph',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'Heading 1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'Heading 2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'Heading 3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'Heading 4',
					class: 'ck-heading_heading4'
				},
				{
					model: 'heading5',
					view: 'h5',
					title: 'Heading 5',
					class: 'ck-heading_heading5'
				},
				{
					model: 'heading6',
					view: 'h6',
					title: 'Heading 6',
					class: 'ck-heading_heading6'
				}
			]
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				}
			]
		},
		extraPlugins: [ThisCustomUploadAdapterPlugin, MyCustomPlugin],
		image: {
			resizeOptions: [
				{
					name: 'resizeImage:original',
					value: null,
					label: 'Original'
				},
				{
					name: 'resizeImage:10',
					value: '10',
					label: '10%'
				},
				{
					name: 'resizeImage:25',
					value: '25',
					label: '25%'
				},
				{
					name: 'resizeImage:50',
					value: '50',
					label: '50%'
				},
				{
					name: 'resizeImage:75',
					value: '75',
					label: '75%'
				},
				{
					name: 'resizeImage:100',
					value: '100',
					label: '100%'
				}
			],
			toolbar: [
				'toggleImageCaption',
				'imageTextAlternative',
				'|',
				'imageStyle:inline',
				'imageStyle:wrapText',
				'imageStyle:breakText',
				'|',
				'resizeImage'
			]
		},
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			// decorators: {
			// 	toggleDownloadable: {
			// 		mode: 'manual',
			// 		label: 'Downloadable',
			// 		attributes: {
			// 			download: 'file'
			// 		}
			// 	}
			// }
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		placeholder: 'Type or paste your content here!',
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
				'tableProperties',
				'tableCellProperties',
				'saveTableButton',
				'OptionsTableButton'
			],
		},
		mediaEmbed: {
			previewsInData: true
		},
		resize_dir: "both"
	};
	const handleInsertChart = (dataUrl, chartData) => {

		if (imageRef.current) {
			const imageElement = imageRef.current;


			imageElement.setAttribute('data-chart', JSON.stringify(chartData))



			updateChart(chartData, imageElement)
			// if (imageElement) {
			// 	const editor = editorRef.current;

			// 	editor.model.change(writer => {

			// 		const viewElement = editor.editing.view.domConverter.domToView(imageElement);

			// 		// Получаем родителя viewElement
			// 		const parentViewElement = viewElement.findAncestor('element', 'span');
			// 		console.log(viewElement)
			// 		if (parentViewElement) {
			// 			const modelElement = editor.editing.mapper.toModelElement(viewElement);
			// 			console.log(modelElement)
			// 			// parentViewElement.getChild(0)._setAttribute('data-chart', JSON.stringify(chartData))
			// 			console.log(modelElement)
			// 			if (modelElement) {
			// 				// writer.setAttribute('data-chart', JSON.stringify(chartData), parentViewElement);
			// 				// writer.setAttribute('src', dataUrl, modelElement);
			// 				// writer.setAttribute('data-chart', JSON.stringify(chartData), modelElement);
			// 				// updateChart(chartData ,imageElement)
			// 				// console.log(editor.getData())
			// 			} else {
			// 				console.error("Image model element not found.");
			// 			}
			// 		} else {
			// 			console.error("Parent view element not found.");
			// 		}
			// 	});
			// }

		}

		if (editorRef.current && !imageRef.current) {
			const editor = editorRef.current;
			editor.model.change(writer => {
				const generateId = new Date().getTime()
				const insertPosition = editor.model.document.selection.getFirstPosition();

				const imageHtml = `<span class='editor-chart'><canvas class='chartImage id-${generateId}' data-chart='${JSON.stringify(chartData)}' /></span>`;
				// const imgElement = document.createElement('canvas');

				// imgElement.src = dataUrl;

				// imgElement.alt = 'Chart Image';

				// imgElement.classList.add(`id-${generateId}`);
				// imgElement.classList.add(`chartImage`);
				// imgElement.setAttribute('data-chart', JSON.stringify(chartData));
				const viewFragment = editor.data.processor.toView(imageHtml);
				const modelFragment = editor.data.toModel(viewFragment);
				writer.insert(modelFragment, insertPosition);


				// const insertPosition = editor.model.document.selection.getFirstPosition();

				// Создаем элемент изображения в модели CKEditor
				// const imageElement = writer.createElement('imageBlock', {
				// 	src: dataUrl,
				// 	alt: 'Chart Image',
				// 	dataChart: JSON.stringify(chartData),
				// 	class: 'chartImage'
				// });
				// const chartElement = writer.createElement('chartBlock', {
				// 	dataChart: JSON.stringify(chartData),
				// 	class: 'chartBlock'
				// });

				// // Вставляем элемент в документ в текущую позицию курсора
				// writer.insert(chartElement, insertPosition);

				// // Перемещаем курсор за вставленным изображением
				// writer.setSelection(writer.createPositionAfter(chartElement));


				setTimeout(() => {
					const canvas = editor.editing.view.getDomRoot().querySelector(`.id-${generateId}`);
					// const viewElement = editor.editing.view.domConverter.domToView(imgElement)
					// const domElement = editor.editing.view.domConverter.viewToDom(viewElement);
					if (canvas) {
						initializeChart(chartData, canvas);
					}
				}, 500);
			});
		}
	};
	const updateChart = (chartData, canvas) => {
		initializeChart(chartData, canvas);


		const data = editorRef.current.getData();
		const wrapper = document.createElement('div')
		wrapper.innerHTML = data

		const canvasNode = wrapper.querySelector(`.${canvas.classList[2]}`)
		canvasNode.setAttribute('data-chart', `${JSON.stringify(chartData)}`)

		setData(wrapper.innerHTML);

	};
	const openChartModal = (editor) => {
		if (editor.editing && editor.editing.view && editor.editing.view.document) {
			let lastClickTime = 0;
			editor.editing.view.document.on('click', (evt, data) => {

				const clickedElement = data.domTarget;
				const now = Date.now();
				if (now - lastClickTime < 300) {
					if (clickedElement.classList.contains('chartImage')) {
						imageRef.current = clickedElement
						console.log(clickedElement.getAttribute('data-chart'))
						const chartData = JSON.parse(clickedElement.getAttribute('data-chart'));

						setChartData(chartData);
						setChartModal(true);
					}
				}
				lastClickTime = now;
			});
		}


	};

	const initCharts = (editor) => {
		const domRoot = editor.editing.view.getDomRoot();
		if (!domRoot) return
		const canvasElements = domRoot.querySelectorAll('.chartImage');

		canvasElements.forEach(element => {
			const chartData = element.getAttribute('data-chart');

			// Проверяем, был ли элемент уже инициализирован через WeakMap
			if (chartData && !initializedCharts.current.has(element)) {

				initializeChart(JSON.parse(chartData), element); // Ваша логика инициализации графика
				initializedCharts.current.set(element, true); // Помечаем, что график инициализирован
			}
		});
	};

	useEffect(() => {
		document.addEventListener('click', openChartModal);
		return () => {
			document.removeEventListener('click', openChartModal);
		};
	}, []);

	// const handleInsertTableRowBelow = () => {
	// 	const editor = editorRef.current
	// 	editor.execute('insertTableRowBelow');
	// }

	useEffect(() => {
		if (editorRef.current) {
			window.editorRef = editorRef.current
		}
	}, [editorRef.current])

	return (

		<div className='editor-container' ref={editorContainerRef}>
			<div ref={editorRef}>
				{isLayoutReady &&
					<CKEditor
						editor={ClassicEditor}
						config={editorConfig}
						data={editorData}
						onChange={(event, editor) => {
							handleEditorChange(editor)
							setTimeout(() => { initCharts(editor) }, 200)
						}}
						onReady={(editor) => {
							editorRef.current = editor;
							setTimeout(() => {
								initCharts(editor)
							}, 500)


							openChartModal(editor)
							editor.commands.add('openChartModal', {
								execute: () => setChartModal(true),
								destroy: () => { }
							});
							editor.commands.add('openSaveModal', {
								execute: () => setSaveTableModal(true),
								destroy: () => { }
							});
							editor.commands.add('openInsertSavedTableModal', {
								execute: () => setInsetrTableModal(true),
								destroy: () => { }
							});
							editor.on('openTableSettingsModal', () => {
								setOptionsTableModal(true);
							});
							// editor.editing.view.document.on('keydown', (event, domEventData) => {
							// 	if (!domEventData.domEvent.shiftKey && domEventData.domEvent.keyCode === 13) {
							// 		const row = domEventData.domTarget.closest("tr")
							// 		if (row) {
							// 			domEventData.domEvent.preventDefault()
							// 			handleInsertTableRowBelow()
							// 			row.nextSibling.firstChild.focus()
							// 		}
							// 	}
							// });
						}}
					/>}</div>
			{chartModal && <AddChartModal
				initialData={chartData}
				open={chartModal}
				handleClose={handleChartModalClose}
				onInsert={handleInsertChart}
			/>}
			{saveTableModal && <SaveTableModal
				open={saveTableModal}
				handelClose={() => setSaveTableModal(false)}
				editorRef={editorRef}
			/>}
			{insetrTableModal &&
				<InsertSavedTableModal
					open={insetrTableModal}
					handelClose={() => setInsetrTableModal(false)}
					editorRef={editorRef}
				/>
			}
			{optionsTableModal && 
				<TableOptionsModal 
					open={optionsTableModal} 
					handelClose={() => setOptionsTableModal(false)} 
					editorRef={editorRef}
				/>
			}
		</div>
	);
}

export default TextEditor;
