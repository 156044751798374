
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Comment from './Comment';
import CommentForm from './CommentForm';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { likeCommentByIdFeth } from '../../store/comments/commentsThunk';
import { deleteThreadCommentFeth, likeThreadCommentFeth, pinThreadCommentModeratorFeth } from '../../store/forums/forumsThunk';
import { scroltoComment } from '../../helpers/utils';
const CommentSection = ({ addCommentFeth, commentArrray, pagination, setLimit, isLoading = false, showOnly = false, searchBlock = false, formType = 'input', isPin = false, sortHandler, searchHandler, canDelete }) => {
    const { id, threadId } = useParams()
    const [comments, setComments] = useState([]);
    const dispatch = useDispatch()
    const { hash } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const targetId = useMemo(() => (searchParams.get('target') || null), [searchParams])

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);
    useEffect(() => {
        if (commentArrray) {
            setComments(commentArrray)
        }
    }, [commentArrray])
    const addComment = async (comment) => {
        const data = {
            "content": comment,
        }
        const res = await dispatch(addCommentFeth({ id: threadId ? threadId : id, body: data })).unwrap()
        if (res) {
            setComments([res, ...comments]);
        }
    };
    useEffect(() => {
        let timeout = null
        if (!isLoading && commentArrray) {
            timeout = setTimeout(() => {
                scroltoComment()
            }, 200)
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [commentArrray, isLoading])

    const addReply = async (commentId, reply) => {
        const data = {
            "content": reply,
            "parentId": commentId
        }

        const res = await dispatch(addCommentFeth({ id: threadId ? threadId : id, body: data })).unwrap()

        if (res) {
            const updateComments = (comments) =>
                comments.map((comment) => {
                    if (comment.id === res.parentId) {
                        return {
                            ...comment,
                            replies: comment.replies ? [...comment.replies, res] : [res],
                        };
                    } else if (comment.replies) {
                        return {
                            ...comment,
                            replies: updateComments(comment.replies)
                        };
                    } else {
                        return comment;
                    }
                });
            setComments(updateComments(comments));
        }
    };

    const likeHandler = async (commentId) => {
        let res
        if (formType === 'input') {
            res = await dispatch(likeCommentByIdFeth(commentId)).unwrap()
        } else {
            res = await dispatch(likeThreadCommentFeth(commentId)).unwrap()
        }

        if (res) {
            const updateLikes = (comments) =>
                comments.map((comment) => {
                    if (comment.id === commentId) {
                        return { ...comment, ...res };
                    } else if (comment.replies && comment.replies.length > 0) {
                        return { ...comment, replies: updateLikes(comment.replies) };
                    }
                    return comment;
                });
            setComments(updateLikes(comments));
        }

    };
    // const sortPinn = (arr) => arr.sort((a, b) => {
    //   if (a.isPinned && !b.isPinned) return -1; // Если первый элемент закреплен, а второй нет
    //   if (!a.isPinned && b.isPinned) return 1;  // Если второй элемент закреплен, а первый нет
    //   return 0; // Если оба элемента одинаково закреплены или не закреплены
    // });
    const pinCommentHandler = async (commentId) => {
        const res = await dispatch(pinThreadCommentModeratorFeth(commentId)).unwrap()
        if (res) {
            const updatePins = (comments) =>
                comments.map((comment) => {
                    if (comment.id === commentId) {
                        return { ...comment, ...res };
                    } else if (comment.replies && comment.replies.length > 0) {
                        return { ...comment, replies: updatePins(comment.replies) };
                    }
                    return comment;
                })
            setComments(updatePins(comments));
        }

    };
    const deleteComment = async (commentId) => {
        const res = await dispatch(deleteThreadCommentFeth(commentId)).unwrap()
        if (res) {
            const updateComments = (comments) => comments.map(comment => {
                // Если id совпадает с комментарием, пропускаем его (удаляем)
                if (comment.id === commentId) {
                    return null;
                }

                // Если у комментария есть ответы, фильтруем их
                const newReplies = comment.replies ? comment.replies.filter(reply => reply.id !== commentId) : [];

                // Возвращаем новый объект комментария с обновлёнными ответами
                return { ...comment, replies: newReplies };
            }).filter(comment => comment !== null);
            
            setComments(updateComments(comments))
        }
    }
    return (
        <div className="widget-comments" id={'comments'}>
            {!showOnly && <CommentForm formType={formType} addComment={addComment} />}
            {searchBlock && <div className="group group--sm justify-between">
                <div className="switch-multy">
                    <input type="radio" id="switch-multy-radio1" value={'lastest'} onChange={({ target }) => sortHandler(target.value)} name="radio" />
                    <label htmlFor="switch-multy-radio1">Lastest</label>

                    <input type="radio" id="switch-multy-radio2" value={'popular'} onChange={({ target }) => sortHandler(target.value)} name="radio" />
                    <label htmlFor="switch-multy-radio2">Popular</label>

                    <input type="radio" id="switch-multy-radio3" value={'pinned'} onChange={({ target }) => sortHandler(target.value)} name="radio" />
                    <label htmlFor="switch-multy-radio3">Pinned</label>
                    <span className="active-label"></span>
                </div>
                <form method="get" className="form-group w-[300px]">
                    <div className="input-wrapper input--sm">
                        <span className="ico">
                            <img src={require("../../assets/img/ico-loupe.svg").default} />
                        </span>
                        <input className="input input--search input--solid rounded-full" type="text" onChange={({ target }) => searchHandler(target.value)} placeholder="Search comments" />
                    </div>
                </form>
            </div>}
            {comments.length > 0 ? <div>
                <ul className="comments-list">
                    {comments.map((comment) => (
                        <Comment
                            key={comment.id}
                            comment={comment}
                            addReply={addReply}
                            likeComment={likeHandler}
                            showOnly={showOnly}
                            pinComment={pinCommentHandler}
                            isPin={isPin}
                            targetId={targetId}
                            canDelete={canDelete}
                            deleteComment={deleteComment}
                        />
                    ))}
                </ul>
                {pagination?.maxPages != pagination?.page &&
                    <div className="flex flex-col gap-[15px] mt-[32px] items-center">
                        <button
                            type='button'
                            onClick={(e) => {
                                e.preventDefault()
                                setLimit(prev => prev + 1)
                            }}
                            className="btn btn--shadow btn--md radius-5 w-full"
                            disabled={isLoading}
                        >
                            <span className="text-[14px] font-[500]">
                                Show More
                            </span>
                        </button>
                    </div>
                }
            </div> : <div className='text-center'>Comments not Found</div>}

        </div>
    );
}

export default CommentSection
