import React, { useEffect, useState } from 'react'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import SingleActions from '../../components/parts/SingleActions'
import BreadCrambs from '../../components/parts/BreadCrambs'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { delMaterialLibraryByIdFeth, favoriteMaterialLibraryByidFeth, getMaterialLibraryByIdFeth, likeMaterialLibraryByIdFeth, viewMaterialLibraryByIdFeth } from '../../store/materials/materialLibraryThunks'
import { useSelector } from 'react-redux'
import { getDateToString, getImageSrc } from '../../helpers/utils'
import { openFancybox } from '../../helpers/openFancybox'
import { AddMaterialModal } from '../../components/Modals/AddMaterialModal'
import { setMaterialId } from '../../store/materials/materialLibrarySlice'
import PageLoader from '../../components/parts/PageLoader'
import SidebarRelatedImages from '../../components/parts/sidebarPosts/SidebarRelatedImages'
import DeleteModal from '../../components/Modals/DeleteModal'

const ImageSingle = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(false)
    const {material, isLoading} = useSelector(state => state.materialLibrary)
    const [addMaterialModal, setAddMaterialModal] = useState(false)
    const addModalHandler = () => {
        setAddMaterialModal(prev => !prev)
    }
   
    const helpLinks = [
        {
            title: 'FAQ',
            url: '/faq'
        },
        {
            title: 'Event Calendar',
            url: '/event-calendar'
        },
        {
            title: 'Pricing Plan',
            url: '/pricing-plan'
        },
        {
            title: 'About Us',
            url: '/about-us'
        },
        {
            title: 'Contact Us',
            url: '/contact-us'
        },
    ]
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/materials',
            label: 'Material Library'
        },
        {
            link: '/materials/image/:id',
            label: material?.title
        },
    ]
    
    const toggleLike = () => {
            dispatch(likeMaterialLibraryByIdFeth(id))

    }
    const toggleFavorite = () => {
        dispatch(favoriteMaterialLibraryByidFeth(id))
    }
    const deleteHandler = async () => {
        const res = await dispatch(delMaterialLibraryByIdFeth(id)).unwrap()
        if(res) {
            navigate('/materials')
        }
    }
    const editHandler = () => {
        dispatch(setMaterialId(id))
        setAddMaterialModal(true)
    }
    useEffect(() => {
        if(id) {
            dispatch(getMaterialLibraryByIdFeth(id))
            dispatch(viewMaterialLibraryByIdFeth(id))
        }
    },[id, dispatch])
    if(isLoading) return <PageLoader />
    return (
        <main className="content">
            <BreadCrambs breabcrambs={breadcramds} />
            <section className="page-section">
                <div className="container">

                    <div className="page-section--row">
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    {material?.relatedMaterials && material?.relatedMaterials.length > 0 ? 
                                        <SidebarRelatedImages 
                                            images={material?.relatedMaterials} 
                                            title={'Related Images'}
                                        />
                                    : null}
                                </div>
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                <div>
                                    <div className="pill-custom pill-custom-2">
                                        <span className="pill-text">{material?.filter?.name}</span>
                                    </div>
                                    <h2>
                                        {material?.title}
                                    </h2>
                                    <SingleActions
                                        likes={material?.likes}
                                        user={material?.user}
                                        displayUser={true}
                                        createAt={material?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={material?.isLiked}
                                        isFavoriteProps={material?.isFavorite}
                                        handleDeleteButton={() => setDeleteModal(true)}
                                        editButtonHandler={editHandler}
                                        refType={'LibraryMaterial'}
                                    />
                                    <div>
                                        <div className="article-media">
                                            <img src={getImageSrc(material?.fileName)} alt="" />
                                            <div className="card-actions">
                                                <button type='button' onClick={() => openFancybox([material?.fileName], 0)} className="btn btn--md radius-5
                                                btn--shadow btn--download btn--square rounded-full">
                                                    <span className="ico">
                                                        <img src={require("../../assets/img/ico-zoom.svg").default} />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {material?.description}
                                </div>
                            </article>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={'Useful links'}
                                        links={helpLinks}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>

                </div>
            </section>
            {addMaterialModal && <AddMaterialModal editModal = {true} title={`Edit Image`} uploadType={'image'} open={addMaterialModal} handleClose={addModalHandler} setDeleteModal={() => setDeleteModal(true)}/>}
            {deleteModal && <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'image'} deleteHandler={deleteHandler} />}
        </main>
    )
}

export default ImageSingle
