import React, { useEffect, useState } from 'react'
import BreadCrambs from '../../components/parts/BreadCrambs'
import SingleActions from '../../components/parts/SingleActions'
import CommentsPart from '../../components/parts/CommentsPart'
import Accordeon from '../../components/UI/Accordeon'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Linkify from 'react-linkify';
import { deleteArticleByIdFeth, getArticleByIdFeth, likeArticleByArticleIdFeth, toggleFavoriteArticleByArticleIdFeth, viewArticleByArticleIdFeth } from '../../store/articles/articlesThunks'
import PageLoader from '../../components/parts/PageLoader'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getDateToString, getImageSrc, scroltoComment } from '../../helpers/utils'
import { Link as ScrollLink } from 'react-scroll'
import CardArticle from '../../components/parts/Cards/CardArticle'
import CardQuiz from '../../components/parts/Cards/CardQuiz'
import { setArticleId } from '../../store/articles/articlesSlice'
import CommentSection from '../../components/comments/CommentSection'
import { addCommentArticleIdFeth, getCommentsByEntityTypeEntityIdFeth } from '../../store/comments/commentsThunk'
import { getMe } from '../../store/auth/authSlice'
import SidebarRelatedVideos from '../../components/parts/sidebarPosts/SidebarRelatedVideos'
import SidebarArticlesPosts from '../../components/parts/sidebarPosts/SidebarArticlesPosts'
import SidebarRelatedImages from '../../components/parts/sidebarPosts/SidebarRelatedImages'
import SirebarLatestCases from '../../components/parts/sidebarPosts/SirebarLatestCases'
import 'ckeditor5/ckeditor5.css';
import { initializeChart } from '../../hooks/creacteChartFn'
import $ from 'jquery';


import 'datatables.net-dt/css/dataTables.dataTables.css';

import 'datatables.net';
// import 'datatables.net-dt';
import 'datatables.net-responsive-dt';
// import 'datatables.net-rowgroup-dt';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs5';


import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
// import 'datatables.net-rowgroup-dt/css/rowGroup.dataTables.min.css';
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
import { socket } from '../../App'
import { setNewComment } from '../../store/comments/commentsSlise'

const ArticleSingle = () => {
    const { id } = useParams();
    const me = useSelector(getMe)
    const { article, isLoading } = useSelector(state => state.articles)
    const { comments, pagination, commentsIsLoading } = useSelector(state => state.comments)
    const [commentsLimit, setCommentsLimit] = useState(10)
    const [commentsPage, setCommentsPage] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/articles',
            label: 'Articles'
        },
        {
            link: '/articles/:id',
            label: article?.title
        },
    ]
    const [canInit, setCanInit] = useState(false)

    const fetchArticle = async () => {

        const res = await dispatch(getArticleByIdFeth({ id, qwery: searchParams.toString() })).unwrap();
        if (res) {
            setCanInit(true)
        }
    }
    useEffect(() => {
        if (id) {
            fetchArticle()
        }
    }, [id, searchParams])
    useEffect(() => {
        if(!id) return 
        socket.emit("articleComments", {
            id: id
        });
        socket.on('newComment', (comment) => {
            dispatch(setNewComment(comment))
        });
        return () => {
            socket.off('newComment');
        };
    }, [id])
    useEffect(() => {
        // Загрузка jQuery
        if (!article) return
        let tableTimeout = setTimeout(() => {
            let tables = document.querySelectorAll('table')
            let canvasElements = document.querySelectorAll('.chartImage')
            let videos = document.querySelectorAll('video')
            tables.forEach((table, index) => {
                const tableHasOptions = $(table).closest('[data-option]').attr('data-option')
                if (tableHasOptions) {
                    const tableOptions = JSON.parse(tableHasOptions)

                    if(
                        !tableOptions.searching && 
                        !tableOptions.ordering && 
                        !tableOptions.buttons
                    ) {
                        return
                    }

                    $(table).closest('.table').attr('id', `datatable-${index}`)
                    
                    const datatable = $(table).DataTable({
                        paging: false,
                        searching: tableOptions.searching,
                        ordering: tableOptions.ordering,
                        info: false,
                        // scrollX: true,
                        // scrollCollapse: true,
                        // scrollY: 300,
                        // responsive: true,
                        // autoWidth: false,
                        buttons:tableOptions.buttons ? [
                            'colvis', 
                        ] : [],
                    })
                    if(tableOptions.buttons) {
                        datatable.buttons().container().appendTo(`#datatable-${index} .dt-layout-start:eq(0)`);
                    }
                }
            })
            

            canvasElements.forEach((element, index) => {
                const chartData = element.getAttribute('data-chart');
                initializeChart(JSON.parse(chartData), element);
            });
            videos.forEach(video => {
                $(video).attr('controlsList', "nodownload")
            })
        }, 1000)
        return () => {
            clearTimeout(tableTimeout)
        }
    }, [article]);
    useEffect(() => {
        if (id) {
            dispatch(getCommentsByEntityTypeEntityIdFeth({ id, type: 'article', qwery: `limit=${commentsLimit}&page=${commentsPage}${commentsPage === 1 ? `&${searchParams.toString()}` : ''} ` }));
        }
    }, [id, commentsPage])
    const toggleLike = () => {
        dispatch(likeArticleByArticleIdFeth(id))
    }
    const toggleFavorite = () => {
        dispatch(toggleFavoriteArticleByArticleIdFeth(id))

    }

    function addIdsToHeaders(htmlContent) {
        // Создаем временный элемент для манипуляций с HTML-контентом
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;

        // Найти все заголовки h1 - h6
        const images = tempDiv.querySelectorAll('img')
        const headers = tempDiv.querySelectorAll('h2, h3');
        const links = [];
        images.forEach(img => {
            img.removeAttribute('width')
            img.removeAttribute('height')
        })
        headers.forEach(header => {
            const text = header.textContent || header.innerText;
            const id = text.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
            header.id = id;
            links.push({
                id: id,
                title: text
            });
        });

        return { updatedHtml: tempDiv.innerHTML, links };
    }
    const deleteHandler = async () => {
        const res = await dispatch(deleteArticleByIdFeth(id)).unwrap()
        // if (res) {
        //     navigate('/articles')
        // }
    }
    const editHandler = () => {
        navigate(`/article-edit/${id}${searchParams.get('versionId') ? '?' + searchParams.toString() : ''}`)
    }
    useEffect(() => {
        if (id) { dispatch(viewArticleByArticleIdFeth(id)) }
    }, [id])

    if (isLoading) return <PageLoader />
    return (
        <main className="content">

            <BreadCrambs breabcrambs={breadcramds} />
            <section className="page-section">
                <div className="container">
                    <div className="page-section--row">
                        <div className="page-sidebar --none-border nav-article-aside mobile-fixed mobile-fixed--left lg:mt-[210px]" data-aside="body">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <p className="sidebar-label mb-[20px]">Contents</p>
                                <ul className="nav-article">
                                    {addIdsToHeaders(article?.content).links.map(link => (
                                        <li key={link.id}>
                                            <ScrollLink
                                                activeClass="active"
                                                to={link.id}
                                                spy={true}
                                                smooth={true}
                                                hashSpy={true}
                                                offset={-100}
                                                duration={500}
                                            >{link.title}</ScrollLink>
                                        </li>
                                    ))}
                                </ul>
                                <a href="" className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path>
                                    </svg>
                                </a>
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                <div id="introduction">
                                    <div className="pill-custom">
                                        <span className="pill-text">{article?.filter?.name}</span>
                                    </div>
                                    <h2>
                                        {article?.title}
                                    </h2>

                                    <SingleActions
                                        likes={article?.likes}
                                        displayUser={article?.displayAuthorName}
                                        user={article?.user}
                                        historyBtn={`/articles/${id}/history`}
                                        createAt={article?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={article?.isLiked}
                                        isFavoriteProps={article?.isFavorite}
                                        handleDeleteButton={deleteHandler}
                                        editButtonHandler={editHandler}
                                        multipleEditors={article?.editors}
                                        canEdit={true}
                                        hideDeletebutton={article?.user?.id !== me?.id}
                                        refType={'Article'}
                                    />
                                    <div>
                                        <div className='text-[14px]'>
                                            <span className='text-[14px] font-bold'>Author: </span>
                                            <Link to={`/profile/${article?.user?.id}`}>{article?.user?.firstName} {article?.user?.lastName}</Link></div>
                                        {article?.authors?.length > 0 ? <div className='text-[14px]'>
                                            <span className='text-[14px] font-bold'>Contributor(s): </span>
                                            {article?.authors?.map((autor, index) =>
                                                <React.Fragment key={index}>
                                                    <Link to={`/profile/${autor.id}`} >
                                                        {autor.firstName} {autor.lastName}
                                                    </Link>
                                                    {index < article?.authors?.length - 1 && ', '}
                                                </React.Fragment>
                                            )}

                                        </div> : null}
                                        {article?.editors?.length > 0 ? <div className='text-[14px]'>
                                            <span className='text-[14px] font-bold'>Editor(s): </span>
                                            {article?.editors?.map((autor, index) =>
                                                <React.Fragment key={index}>
                                                    <Link to={`/profile/${autor.id}`}>
                                                        {autor.firstName} {autor.lastName}
                                                    </Link>
                                                    {index < article?.editors
                                                        ?.length - 1 && ', '}
                                                </React.Fragment>
                                            )}

                                        </div> : null}
                                    </div>
                                    {article?.cover && <div>
                                        <div className="article-media">
                                            <img src={getImageSrc(article?.cover)} alt="" />
                                        </div>
                                    </div>}
                                </div>
                                <div className='editor-text editor-container ck-content' dangerouslySetInnerHTML={{ __html: addIdsToHeaders(article?.content).updatedHtml }} />
                                <div>
                                    <div className="w-full">
                                        {article?.quizzes && article?.quizzes?.length > 0 && <Accordeon
                                            iconStyle='icon-first'
                                            title={'Related quizzes'}
                                        >
                                            <div className="pb-[20px]">
                                                <div className="row g-16">
                                                    {article?.quizzes.map(quize => (
                                                        <div className="col-xl-4 col-md-6" key={quize.id}>
                                                            <CardQuiz
                                                                authorized={true}
                                                                data={quize}
                                                                hideActions={true}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Accordeon>}
                                        {article?.references && <Accordeon
                                            iconStyle='icon-first'
                                            title={'References'}
                                        >
                                            <div className="pb-[20px]">
                                                {/* <Linkify componentDecorator={componentDecorator}>
                                                    {article?.references}
                                                </Linkify> */}
                                                <div className='editor-text editor-container ck-content' dangerouslySetInnerHTML={{ __html: article?.references }}></div>
                                            </div>
                                        </Accordeon>}
                                        {article?.relatedArticles && article?.relatedArticles?.length > 0 && <Accordeon
                                            iconStyle='icon-first'
                                            title={'Articles you may like'}
                                        >
                                            <div className="pb-[20px]">
                                                <div className="row g-16">
                                                    {article?.relatedArticles.map(article => (
                                                        <div className="col-xl-4 col-md-6" key={article.id}>
                                                            <CardArticle
                                                                data={article}
                                                                authorized={true}
                                                                hideActions={true}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Accordeon>}
                                    </div>
                                </div>
                                <div>
                                    <SingleActions
                                        likes={article?.likes}
                                        displayUser={article?.displayAuthorName}
                                        user={article?.user}
                                        historyBtn={`/articles/${id}/history`}
                                        createAt={article?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={article?.isLiked}
                                        isFavoriteProps={article?.isFavorite}
                                        handleDeleteButton={deleteHandler}
                                        editButtonHandler={editHandler}
                                        multipleEditors={article?.editors}
                                        canEdit={true}
                                        hideDeletebutton={article?.user?.id !== me?.id}
                                        refType={'Article'}
                                    />
                                </div>
                                {article?.enableComments && me && <div>
                                    <CommentSection
                                        addCommentFeth={addCommentArticleIdFeth}
                                        commentArrray={comments}
                                        pagination={pagination}
                                        setLimit={setCommentsPage}
                                        isLoading={commentsIsLoading}
                                    />
                                </div>}
                            </article>
                        </div>
                        <div className="page-sidebar --none-border lg:mt-[210px]" style={{ position: 'static' }} data-aside="body">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    {/* {article?.relatedImages && article?.relatedImages?.length ?
                                        <SidebarRelatedImages
                                            images={article?.relatedImages}
                                            title={'Related Images'}
                                        />
                                        : null} */}
                                    {article?.relatedCases && article?.relatedCases?.length ?
                                        <SirebarLatestCases
                                            cases={article?.relatedCases}
                                            title={'Related Cases'}
                                        />
                                        : null}
                                    {article?.relatedVideos && article?.relatedVideos?.length > 0 ?
                                        <SidebarRelatedVideos
                                            videos={article?.relatedVideos}
                                            title={'Related Videos'}
                                        />
                                        : null}
                                    {article?.relatedArticles && article?.relatedArticles?.length ?
                                        <SidebarArticlesPosts
                                            posts={article?.relatedArticles}
                                            title={`${article?.filter?.name} articles`}
                                            showIcon={false}
                                        />
                                        : null}
                                    <a href="" className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                                        <span className="ico">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                <path d="M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ArticleSingle
