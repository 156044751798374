import { formatISO } from 'date-fns'
import { useMemo } from 'react'
import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import CardEvent from '../../components/parts/Cards/CardEvent'
import PageLoader from '../../components/parts/PageLoader'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import { filterOptions } from '../../helpers/constants'
import { getAllEventsFetch, getEventCountByDateFetch } from '../../store/events/eventsThunks'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'

const EventCalendar = () => {
    const dispatch = useDispatch()
    const { events, eventCountByDate, isEventCountByDateLoading, isEventsLoading, pagination } = useSelector(state => state.events)
    const [searchParams, setSearchParams] = useSearchParams()

    const params = useMemo(() => ({
        sort: searchParams.get("sort") || 'latest',
        category: searchParams.get("category")?.length
            ? searchParams.get("category").split(',').filter(Boolean) || []
            : [],
        date: searchParams.get("date") ? new Date(searchParams.get("date")) : ""
    }), [searchParams])

    const [isOpen, setIsOpen] = useState(false)
    
    const handleSidebarClose = () => {
        document.querySelector("body").classList.remove("lock")
        setIsOpen(false)
    }
    const handleSidebarToggle = () => {

        if (isOpen) {
            handleSidebarClose()
        } else {
            document.querySelector("body").classList.add("lock")
            setIsOpen(true)
        }
    }

    const systems = useSelector(getFilteres);
    // const [params, setParams] = useState({
    //     sort: 'latest',
    //     category: [],
    //     search: ''
    // })
    const filters = [
        {
            title: "Filter by Systems",
            checkboxOptions: systems
        },
    ]

    const handleQueryChange = (name, value) => {
        setSearchParams(prev => {
            if (value) {
                prev.set(name, value)
            } else if (prev.get(name)) {
                prev.delete(name)
            }
            return prev
        })
    }
    const handleFilterChange = (options) => {
        // setParams((prevFilters) => {
        //     const category = prevFilters.category.includes(options.name)
        //         ? prevFilters.category.filter((cat) => cat !== options.name)
        //         : [...prevFilters.category, options.name];

        //     return {
        //         ...prevFilters,
        //         category
        //     };
        // })
        const value = params.category.includes(options.name)
            ? params.category.filter((cat) => cat !== options.name).join(',')
            : [...params.category, options.name].join(',')
        handleQueryChange("category", value)

        // setSearchParams({
        //     ...params,
        //     category: params.category.includes(options.name)
        //         ? params.category.filter((cat) => cat !== options.name).join(',')
        //         : [...params.category, options.name].join(','),
        // });
    }
    const handleSortChange = (option) => {
        // setParams(prev => ({
        //     ...prev,
        //     sort: option.value
        // }))
        handleQueryChange("sort", option.value)
        // setSearchParams({
        //     ...params,
        //     sort: option.value,
        // });
    }
    const handleEventDateChange = (value) => {
        const date = new Date(new Date(value).setHours(12)).toISOString()
        handleQueryChange("date", date)
    }
    // useEffect(() => {
    //     const categoryParam = searchParams.get('category');

    //     const category = categoryParam ? categoryParam?.split(',').filter(Boolean) : [];
    //     const sort = searchParams.get('sort') || 'latest';
    //     const search = searchParams.get('search') || '';
    //     // setParams({ category, sort, search });
    // }, [searchParams])

    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])


    const fetchData = async () => {
        const res = await dispatch(getAllEventsFetch(searchParams.toString())).unwrap()
        // const res = await dispatch(getAllEventsFetch(`date=${new Date('10/02/2024').toISOString()}`)).unwrap()
        await dispatch(getEventCountByDateFetch()).unwrap()
        if (res) {
            window.scrollTo(0, 0)
        }
    }

    useEffect(() => {
        fetchData()
    }, [params])

    const compareDatesByDay = (date1, date2) => {
        const date1WithoutTime = new Date(date1);
        date1WithoutTime.setHours(0, 0, 0, 0);

        const date2WithoutTime = new Date(date2);
        date2WithoutTime.setHours(0, 0, 0, 0);

        return date1WithoutTime.getTime() === date2WithoutTime.getTime()
    };

    const getEventsForDate = (date) => {
        if (!date) return []
        else return eventCountByDate.find(event => compareDatesByDay(event.date, date))
    };

    const renderDayContents = (dayOfMonth, date) => {
        const event = getEventsForDate(date)
        
        const count = event?.count > 3 ? 3 : event?.count
        return (
            <div className='relative'>
                {dayOfMonth}
                {
                    !!count && (
                        <div className='event-markers-container'>
                            {Array.from({ length: count }).map((event, index) => (
                                <div key={index} className='event-marker'></div>
                            ))}
                        </div>
                    )
                }
            </div>
        );
    };

    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <aside className={`filters-sidebar section-filters__aside ${isOpen ? "sidebar-is-open" : ""}`} >
                        <div className="filters-sidebar__container">
                            <SidebarFilters filters={filters} defaultCheked={params?.category} onChange={handleFilterChange}>
                                <div className="style-date-picker">
                                    <ReactDatePicker
                                        // minDate={new Date()}
                                        inline
                                        selected={params.date}
                                        onChange={handleEventDateChange}
                                        renderDayContents={renderDayContents}
                                    // showDisabledMonthNavigation
                                    />
                                </div>
                            </SidebarFilters>
                        </div>
                        <button type='button' onClick={handleSidebarToggle} className="btn btn--primary btn--square btn--md sidebar-action-btn" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path>
                            </svg>
                        </button>
                    </aside>
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Event Calendar</h3>
                            </div>
                            <div className="page-heading__item">
                                <div className="input-wrapper ">

                                </div>
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                            </div>
                        </div>
                        <div className="row gutters-cards">
                            {!isEventsLoading && events.length ?
                                events.map(event => (
                                    <div key={event.id} className="col-12">
                                        <CardEvent data={event} />
                                    </div>
                                )) : isEventsLoading ?
                                    <PageLoader />
                                    : <h2>There is no events yet</h2>
                            }

                        </div>
                        <Pagination {...pagination} params={params} />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default EventCalendar