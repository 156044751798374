import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import mammoth from 'mammoth/mammoth.browser';
import '@cyntler/react-doc-viewer/dist/index.css';
// Настройка пути к pdf.worker.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const ReactDocViewer = ({ fileUrl, fileType }) => {
    const [docxContent, setDocxContent] = useState('');
    const [docWidth , setDocWidth] = useState(0)
    const docRef = useRef()
    function wrapTablesInDiv(htmlString) {
        // Создаем элемент-контейнер для парсинга HTML строки
        const container = document.createElement('div');
        container.innerHTML = htmlString;
      
        // Находим все таблицы в контейнере
        const tables = container.querySelectorAll('table');
      
        // Оборачиваем каждую таблицу в <div>
        tables.forEach(table => {
            table.classList.add('table')
          const wrapper = document.createElement('div');
          wrapper.classList.add('table-wrapper'); // добавляем класс, если нужно
          wrapper.classList.add('table-from-docs'); // добавляем класс, если нужно
          table.parentNode.insertBefore(wrapper, table);
          wrapper.appendChild(table);
        });
      
        // Возвращаем обновленный HTML
        return container.innerHTML;
      }
    useEffect(() => {
        if (fileType === 'docx') {
            fetch(fileUrl)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => mammoth.convertToHtml({ arrayBuffer }))
                .then(result => {
                    console.log('result', result.value)
                    
                    setDocxContent(wrapTablesInDiv(result.value))
                })
                .catch(err => console.error('Error converting DOCX to HTML:', err));
        }
    }, [fileUrl, fileType]);
    useEffect(() => {
        if(docRef?.current) {
            setDocWidth(docRef.current.offsetWidth)
        }
    },[docRef])
    switch (fileType) {
        case 'pdf':
            return (
                <Document file={fileUrl} inputRef={docRef} >
                    <Page pageNumber={1} width={docWidth} />
                </Document>
            );
        case 'docx':
            return <div dangerouslySetInnerHTML={{ __html: docxContent }} />;
        default:
            return <p>Unsupported file type.</p>;
    }
};

export default ReactDocViewer;