import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import api from '../../api'

const RejectInventation = () => {
    const {token} = useParams()
    const [success, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false);
    useEffect(() => {
        if(token) {
            submitHandler()
        }
    }, [token])

    async function submitHandler() {
        setIsLoading(true)
        try {
           
            const res = await api.forums.rejectInvite({
                "rejectToken": token
            })
            console.log(res)
            if(res?.success) {
                setSuccess(true)
            }  
            if(!res?.success) {
                setError(true)
            }
            
        } catch (err) {
            setError(true)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='finalize-login-page'>
            {isLoading && <span className="spiner"></span>}
            <h2>{!success && !error ? 'Wait a few seconds while the invitation is rejected, do not close the browser window.' : error ? 'Something went wrong, try again later' : 'Invitation declined'}</h2> 
            {!isLoading && <Link to={'/'} className="btn btn--primary btn--xl radius-5">Visit site</Link>}
        </div>
    )
}

export default RejectInventation
