const pagesBase = '/api/forums';

export const forums = (instance) => ({
    async createForum(payload) {
        try {
            const { data } = await instance.post(`${pagesBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async rejectInvite(payload) {
        try {
            const { data } = await instance.post(`${pagesBase}/moderators/reject-invite`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createRule({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/rules/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createModerator({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/moderators/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async managingModeratorStatus({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/moderators/${id}/manage-invite`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createForumSettingsByForumId({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createThread({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createThreadComment({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/comments/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeForum(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewForum(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async favoriteForum(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async subscribeForum(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/subscribe/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeThread(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewThread(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async favoriteThread(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeThreadComment(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/comments/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async pinThreadCommentModerator(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/threads/comments/pin/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateForumById({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateRuleByRuleId({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/rules/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async rulesOrderingByForumId({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/rules/ordering/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async moderatorOrderingByForumId({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/moderators/ordering/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editForumSettingsByForumId({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateThreadByThreadId({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/threads/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateThreadComment({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/threads/comments/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getAllForums(qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getForum(id) {
        try {
            const { data } = await instance.get(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getAllThreads({ id, qwery }) {
        try {
            const { data } = await instance.get(`${pagesBase}/${id}/threads?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getFavoriteThreads(qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}/threads/favorite?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getThread({ id , qwery}) {
        try {
            const { data } = await instance.get(`${pagesBase}/threads/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getUsersForAssignAsModeratorByForumId({ id, qwery }) {
        try {
            const { data } = await instance.get(`${pagesBase}/moderators/users/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getThreadComments({id, qwery}) {
        try {
            const { data } = await instance.get(`${pagesBase}/threads/${id}/comments?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteRuleByRuleId(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/rules/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteModeratorByModerId(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/moderators/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteThreadByThreadId(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/threads/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteThreadComment(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/threads/comments/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteForum(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});