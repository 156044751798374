import React, { useState } from 'react'
import BreadCrambs from '../../components/parts/BreadCrambs'
import ReactDatePicker from 'react-datepicker'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import RegisterNowModal from '../../components/Modals/RegisterNowModal'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getEventByIdFetch, getEventCountByDateFetch, viewEventByIdFetch } from '../../store/events/eventsThunks'
import { useSelector } from 'react-redux'
import PageLoader from '../../components/parts/PageLoader'
import { format } from 'date-fns'
import { formatDateRange, formatDateRange2, getImageSrc } from '../../helpers/utils'

const RelatedEvents = ({ data }) => {
    
    return (
        <div className="event-group">
            <div className="event-img-64x64">
                <img src={getImageSrc(data.cover)} />
            </div>

            <div>
                <h5 className="event-title">{data.name}</h5>
                <div className="event-date-group">
                    <div className="flex">
                        <img src={require("../../assets/img/calendar-schedule-1.svg").default} />
                    </div>
                    <span className="event-date-text">{format(data.startedAt, "EEEE, MMM dd")}</span>
                </div>
            </div>
        </div>
    )
}

const relatedEventsInitialCount = 5

const EventSingle = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const { event, isEventLoading, eventCountByDate } = useSelector(state => state.events)

    const [isRelatedEventsViewAll, setIsRelatedEventsViewAll] = useState(false)

    const helpLinks = [
        {
            title: 'FAQ',
            url: '/faq'
        },
        {
            title: 'Event Calendar',
            url: '/event-calendar'
        },
        {
            title: 'Pricing Plan',
            url: '/pricing-plan'
        },
        {
            title: 'About Us',
            url: '/about-us'
        },
        {
            title: 'Contact Us',
            url: '/contact-us'
        },
    ]
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/events',
            label: 'Event Calendar'
        },
        {
            link: '/events/:id',
            label: event?.name
        },
    ]
    const [registerModal, setRegisterModal] = useState(false);

    const registerModalHandler = () => {
        setRegisterModal(prev => !prev)
    }

    const handleToggleRelatedEventsView = () => {
        setIsRelatedEventsViewAll(!isRelatedEventsViewAll)
    }

    useEffect(() => {
        dispatch(getEventByIdFetch({ id }))
        dispatch(viewEventByIdFetch({ id }))
    }, [id])

    useEffect(() => {
        dispatch(getEventCountByDateFetch())
    }, [])
    const checkIsEnd = (date) => {
        const dateNow = new Date()
        const eventDate = new Date(date)
        return dateNow > eventDate
    }
    if (isEventLoading) {
        return <PageLoader />
    }

    if (!event) {
        return <h2>Event is not found</h2>
    }

    const relatedEvents = event.relatedEvents


    const compareDatesByDay = (date1, date2) => {
        const date1WithoutTime = new Date(date1);
        date1WithoutTime.setHours(0, 0, 0, 0);

        const date2WithoutTime = new Date(date2);
        date2WithoutTime.setHours(0, 0, 0, 0);

        return date1WithoutTime.getTime() === date2WithoutTime.getTime()
    };

    const getEventsForDate = (date) => {
        if (!date) return []
        else return eventCountByDate.find(event => compareDatesByDay(event.date, date))
    };

    const renderDayContents = (dayOfMonth, date) => {
        const event = getEventsForDate(date)
        const count = event?.count > 3 ? 3 : event?.count
        return (
            <div className='relative'>
                {dayOfMonth}
                {
                    !!count && (
                        <div className='event-markers-container'>
                            {Array.from({ length: count }).map((event, index) => (
                                <div key={index} className='event-marker'></div>
                            ))}
                        </div>
                    )
                }
            </div>
        );
    };


    const handleDateChange = (date) => {
        navigate(`/events?date=${new Date(date).toISOString()}`)
    }

    return (
        <>
            <main className="content">
                <BreadCrambs breabcrambs={breadcramds} />
                <div className="container">
                    <section className="section-filters">
                        <div className="page-section--row">
                            <div className="page-sidebar --none-border">
                                <aside className="filters-sidebar section-filters__aside" data-aside="body">
                                    <div className="filters-sidebar__container">
                                        <ReactDatePicker
                                            // minDate={new Date()}
                                            inline
                                            selected={new Date(event.startedAt)}
                                            onChange={handleDateChange}
                                            renderDayContents={renderDayContents}
                                        // showDisabledMonthNavigation
                                        />
                                        <div className="section-filters__aside--item" data-collapse="parent">

                                            {
                                                !!relatedEvents?.length && (
                                                    <>
                                                        <div className="title-events-group">
                                                            <div className="flex">
                                                                <img src={require("../../assets/img/confetti-papers.png")} />
                                                            </div>
                                                            <h5>You May Like These Events</h5>
                                                        </div>
                                                        <div className="like-events-list">
                                                            {
                                                                [...relatedEvents].slice(0, isRelatedEventsViewAll ? relatedEvents.length : relatedEventsInitialCount).map(event => (
                                                                    <RelatedEvents key={event.id} data={event} />
                                                                ))
                                                            }

                                                            <div className="collapse-block" data-collapse="body">
                                                                <div className="event-group mt-[16px]">
                                                                    <div className="event-img-64x64">
                                                                        <img src={require("../../assets/img/event-item-3.jpg")} />
                                                                    </div>

                                                                    <div>
                                                                        <h5 className="event-title">Worldwide medical <br />congresses and conferences</h5>
                                                                        <div className="event-date-group">
                                                                            <div className="flex">
                                                                                <img src={require("../../assets/img/calendar-schedule-1.svg").default} />
                                                                            </div>
                                                                            <span className="event-date-text">Wednesday, Feb 21</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="event-group">
                                                                    <div className="event-img-64x64">
                                                                        <img src={require("../../assets/img/event-item-4.jpg")} />
                                                                    </div>

                                                                    <div>
                                                                        <h5 className="event-title">HIMSS 2024 in<br /> Orlando, FL</h5>
                                                                        <div className="event-date-group">
                                                                            <div className="flex">
                                                                                <img src={require("../../assets/img/calendar-schedule-1.svg").default} />
                                                                            </div>
                                                                            <span className="event-date-text">Wednesday, Feb 21</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="event-group">
                                                                    <div className="event-img-64x64">
                                                                        <img src={require("../../assets/img/event-item-5.jpg")} />
                                                                    </div>

                                                                    <div>
                                                                        <h5 className="event-title">Worldwide medical <br />congresses and conferences</h5>
                                                                        <div className="event-date-group">
                                                                            <div className="flex">
                                                                                <img src={require("../../assets/img/calendar-schedule-1.svg").default} />
                                                                            </div>
                                                                            <span className="event-date-text">Wednesday, Feb 21</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                relatedEventsInitialCount < relatedEvents.length && (
                                                    <button onClick={handleToggleRelatedEventsView} className="btn text-[13px] justify-start color-pending">
                                                        {isRelatedEventsViewAll
                                                            ? "Show less"
                                                            : "See more events"
                                                        }
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <button className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path>
                                        </svg>
                                    </button>
                                </aside>
                            </div>

                            <div className="page-content">
                                <article className="single-post">
                                    <div id="introduction">
                                        <div className="pill-custom">
                                            <span className="pill-text">{event.filter?.name}</span>
                                        </div>
                                        <h2>
                                            {event.name}
                                        </h2>
                                        <div className="group-between--item flex-wrap">
                                            <div className="event-date-group">
                                                <div className="flex">
                                                    <img src={require("../../assets/img/calendar-schedule-1.svg").default} />
                                                </div>
                                                <span className="event-date-text fw-600">{formatDateRange2(event.startedAt, event.endedAt)}</span>
                                            </div>

                                            <div className="event-date-group">
                                                <div className="flex">
                                                    <img src={require("../../assets/img/clock-watch.svg").default} />
                                                </div>
                                                <span className="event-date-text fw-500">{format(event.startedAt, "hh:mm aaa")} - {format(event.endedAt, "hh:mm aaa")}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="article-media">
                                                <img src={getImageSrc(event.cover)} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <a href={event.link} className="color-danger block text-[18px]">
                                            {event.link}
                                        </a>
                                    </div>
                                    <div>
                                        <p>{event.description}</p>
                                    </div>
                                    <div>
                                        <div className="flex gap-[32px]">
                                            <button type='button'
                                                onClick={registerModalHandler} 
                                                className={`btn btn--primary gap-[10px] btn--md radius-5 ${checkIsEnd(event.endedAt) ? 'disabled' : ''}`}
                                            >
                                                <span className="info text-[13px] font-[500]">Register Now</span>
                                            </button>
                                            {/* <button className="btn btn--shadow gap-[10px] btn--md radius-5">
                                                <span className="info text-[13px] font-[500]">Add to Calendar</span>
                                            </button> */}
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <div className="page-sidebar --none-border">
                                <aside className="sidebar post-sidebar sidebar-xs">
                                    <div className="sidebar-body">
                                        <SidebarHelpLinks
                                            title={'Useful links'}
                                            links={helpLinks}
                                        />
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            {registerModal && <RegisterNowModal id={event.id} open={registerModal} handelClose={registerModalHandler} />}
        </>
    )
}

export default EventSingle
