import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../../assets/img/ico-upload.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/ico-trash.svg';

import pdf from "../../assets/img/Pdf-File.svg"
import word from "../../assets/img/Word.svg"
import { getImageSrc } from '../../helpers/utils';
import ButtonLoader from './ButtonLoader';

function capitalizeFirstLetter(str) {
    return !str ? str : str.charAt(0).toUpperCase() + str.slice(1);
}

const UploadImage = ({ onChange, imageCover = true, isRow, type = "image", defaulImages, multiple, setDelete, isNarrow, onSelect, errorMessage, error, docTypesRemome = []}) => {

    const [images, setImages] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null)
    const [loadingStates, setLoadingStates] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        const newImages = acceptedFiles.map(file => {
            file.preview = URL.createObjectURL(file)
            file.title = ''
            return file
        });
        const updatedImages = [...images, ...newImages];
        
        setImages(updatedImages);
        onChange(updatedImages);
    }, [onChange, defaulImages, images]);

    useEffect(() => {
        setImages(defaulImages);
    }, [defaulImages]);

    const handleDeleteImage = (e, index) => {
        e.stopPropagation()
        if (multiple) {
            const newImages = [...images];
            newImages.splice(index, 1);
            setImages(newImages);
            onChange(newImages)
            if (setDelete) {
                setDelete(prev => prev.filter((item, idx) => index !== idx))
            }
            if (selectedImageIndex === index) {
                setSelectedImageIndex(null)
                onSelect && onSelect(null)
            }
        } else {
            setImages([]);
            onChange([])
        }
    };

    const handleChangeFileName = (value, index) => {
        const newImages = [...images];
        newImages[index].title = value;
        setImages(newImages);
        onChange(newImages)
    };

    const handleSelectImage = (index) => {
        setSelectedImageIndex(index)
        onSelect && onSelect(images[index], index)
    }
    const handleLoadedData = (index) => {
        setLoadingStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    };
    const handleLoadStart = (index) => {
        setLoadingStates(prevStates => {
          const newStates = [...prevStates];
          newStates[index] = true; // Устанавливаем состояние загрузки на true при старте загрузки
          return newStates;
        });
      };
    const documentThumb = {
        pdf,
        docx: word
    }
    const getFileExt = (file) => {
        const parts = file.name ? file.name?.split('.') : file.preview?.split('.');
        const ext = parts?.length ? parts[parts.length - 1] : "";
        return ext;
    };
    const docTypes = { "application/pdf": [], "application/msword": [], "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], "text/plain": [] }
    if(docTypesRemome) {
        docTypesRemome.forEach(type => {
            delete docTypes[type]
        })
    }
    const acceptedFiles = {
        image: { "image/jpeg": [], "image/png": [], "image/gif": [], "image/bmp": [], "image/webp": [] },
        video: { "video/mp4": [], "video/webm": [], "video/ogg": [], 'video/3g2': [], 'video/3gp': [], 'video/3gp2': [], 'video/asf': [], 'video/asx': [], 'video/avi': [], 'video/m4v': [], 'video/mpe': [], 'video/mpg': [], 'video/wmv': [] },
        document: docTypes,
        media: {"video/*": [], "image/*": []}
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFiles[type],
        multiple
    });

    const typeText = type && multiple ? capitalizeFirstLetter(type) + "(s)" : capitalizeFirstLetter(type)
  
    // useEffect(() => {
    //     if (!images?.length && images?.length !== 0) return;
    //     onChange && onChange(images)
    // }, [images])
    return (
        <>
            <form method="post" className='relative max-w-full' action="#">
                <div {...getRootProps({ className: `upload-form ${isRow ? "" : "upload-form--col"}` })}>
                    <input {...getInputProps()} className="hidden" />
                    <div className="upload-form-content">
                        {
                            !multiple && images && images[0] ?
                                <div className={`image-preview-container overflow-hidden h-56  flex flex-auto max-w-full ${type === "video" ? 'aspect-video' : ""}`}>
                                    {type === "image" &&
                                        <img className={`m-auto ${imageCover ? 'h-full' : ''}`}
                                            src={images[0]?.preview}
                                            alt={`preview`} />
                                    }
                                    {type === "video" && <video className="w-full" src={images[0]?.preview}></video>}
                                    {type === "document" && <img className='h-full m-auto' src={documentThumb[getFileExt(images[0])] || ""} alt={`preview`} />}
                                    <button
                                        className='ico ico--xs btn--shadow-2 rounded-full btn--trash absolute top-2 right-2 z-10 btn--remove'
                                        type="button"
                                        onClick={(e) => handleDeleteImage(e)}>
                                        <DeleteIcon />
                                    </button>
                                </div>
                                :
                                <>
                                    <div className="upload-description">
                                        <span className="ico ico--xs">
                                            <UploadIcon />
                                        </span>
                                        <p>{isDragActive ? `Drop the ${typeText} here...` : `Drag & Drop ${typeText} here or`}</p>
                                    </div>
                                    <div className="btn btn--primary btn--xs radius-5">
                                        <span className="text-[13px] font-[500]">Upload</span>
                                    </div>
                                </>

                        }
                    </div>
                    {
                        errorMessage && error ?
                            <p className='color-danger text--xs mt-1'>{errorMessage}</p>
                            :
                            null
                    }
                </div>
            </form>
            {multiple && images?.length ? (
                <ul className={`blueray-list ${type === 'media' ? 'mt-4' :''} ${isNarrow ? "blueray-list--50" : "blueray-list-2"}`}>
                    {images.map((image, index) => (
                        <li key={index} className="blueray-item">
                            <div className="blueray-control">
                                <div className={`btn blueray-btn ${selectedImageIndex === index ? "selected" : ""}`} onClick={() => handleSelectImage(index)}>
                                    <div className="card-actions">
                                        <button type="button" onClick={(e) => handleDeleteImage(e, index)} className="ico ico--xs btn--shadow-2 rounded-full btn--trash">
                                            <span className="ico ico-20">
                                                <DeleteIcon />
                                            </span>
                                        </button>
                                    </div>
                                    {type === "image" && <img onLoadStart={() => handleLoadStart(index)} onLoad={() => handleLoadedData(index)} className={'h-full'} src={image?.preview} alt={`preview`} />}
                                    {type === "video" && 
                                            <video onLoadStart={() => handleLoadStart(index)} onLoadedData={() => handleLoadedData(index)} className="w-full" src={image?.preview}></video>
                                    }
                                    {type === "document" && <img onLoadStart={() => handleLoadStart(index)} onLoad={() => handleLoadedData(index)}  className='h-full' src={documentThumb[getFileExt(image)] || ""} alt={`preview`} />}
                                    {type === "media" && image.type.startsWith('image') && <img onLoadStart={() => handleLoadStart(index)} onLoad={() => handleLoadedData(index)} className={'h-full'} src={image?.preview} alt={`preview`} />}
                                    {type === "media" && image.type.startsWith('video') && <video onLoadStart={() => handleLoadStart(index)} onLoadedData={() => handleLoadedData(index)} className="w-full" src={image?.preview}></video>
                                    }
                                    {loadingStates[index] && <div className="video-loader">
                                        <ButtonLoader />
                                    </div>}
                                </div>
                                {type !== "media" && <div className="input-wrapper input--xs">
                                    <input
                                        className="input input--outline" type="text"
                                        value={image?.title}
                                        onChange={(e) => handleChangeFileName(e.target.value, index)}
                                        placeholder={`${capitalizeFirstLetter(type)} Name...`} />
                                </div>}
                            </div>
                        </li>
                    ))}
                </ul>
            ) : null}
        </>
    );
};

export default UploadImage;
