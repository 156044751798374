import { Plugin, TableUtils, toWidget } from "ckeditor5"

export default class InsertBelowTableButton extends Plugin {
    static get requires() {
        return [TableUtils];
    }
    init() {
        const editor = this.editor;

        // Добавляем кнопку после таблицы при её вставке
        editor.conversion.for('editingDowncast').add(dispatcher => {
            dispatcher.on('insert:table', (evt, data, conversionApi) => {
                const viewWriter = conversionApi.writer;
                const viewTable = conversionApi.mapper.toViewElement(data.item);

                // Создаём кнопку и вставляем её после таблицы
                const tableButton = viewWriter.createUIElement('div', {
                    class: 'ck-button-insert-below'
                });

                viewWriter.insert(viewWriter.createPositionAt(viewTable, 'end'), tableButton);

            });
        });
        this.__insertBelowClick();
    }
    __insertBelowClick() {
        const editor = this.editor;
        const view = editor.editing.view;


        view.document.on('click', (event, data) => {
            const target = data.target;

            // Проверяем, является ли целевой элемент ручкой изменения размера
            if (target.hasClass('ck-button-insert-below')) {
                const tableElement = target.previousSibling; // Находим таблицу через previousSibling

                if (tableElement) {
                    const model = editor.model;
                    model.change(writer => {
                        // const table = tableElement.getChild(1)

                        const body = tableElement.getChild(tableElement.childCount - 1)
                        
                        if (body) {
                            const lastRowIndex = body.getChildIndex(body.getChild(body.childCount - 1));
                            const lastRow = editor.editing.mapper.toModelElement(body.getChild(lastRowIndex));
                          
                            // // Находим последнюю ячейку в последней строке
                            const lastCellIndex = lastRow.getChildIndex(lastRow.getChild(lastRow.childCount - 1));
                            const lastCell = lastRow.getChild(lastCellIndex);
                            const position =  model.createPositionAt(lastCell, 0);
                            
                            writer.setSelection(position);

                            setTimeout(() => {
                                editor.execute('insertTableRowBelow')
                            }, 0)
                        }
                    });
                }
            }
        });
    }
}