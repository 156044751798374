import { useEffect, useRef, useState } from 'react'
import { Link, NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'
import HeaderQuiz from '../components/parts/HeaderQuiz'

import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ChevronLeftIcon } from "../assets/img/chevron-prev.svg"
import { ReactComponent as FlashcardsIcon } from "../assets/img/ico-cards-2.svg"
import { ReactComponent as CardsIcon } from "../assets/img/ico-cards.svg"
import { ReactComponent as FavorIcon } from "../assets/img/ico-favor.svg"
import { ReactComponent as InfoIcon } from "../assets/img/ico-info.svg"
import { ReactComponent as LikeIcon } from "../assets/img/ico-like.svg"
import { ReactComponent as MoonIcon } from "../assets/img/ico-moon.svg"
import { ReactComponent as StarIcon } from "../assets/img/ico-star-2.svg"
import { ReactComponent as PlaceholderIcon } from "../assets/img/user-placeholder.svg"
import { ReactComponent as SunIcon } from "../assets/img/ico-sun.svg"
import { ReactComponent as Report } from "../assets/img/report-icon.svg";
import { ReactComponent as DeleteIcon } from '../assets/img/delete-Icon.svg';
import logo from "../assets/img/logo.png"
import logoDark from "../assets/img/logo-footer.png"
import PageLoader from '../components/parts/PageLoader'
import { getImageSrc } from '../helpers/utils'
import { deleteFlashCardSetFetch, getFlashCardSetByIdFetch, toggleFavoriteFlashcardFetch, toggleLikeFlashcardFetch } from '../store/flashcards/flashcardsThunks'
import { getMe } from '../store/auth/authSlice'
import { NotFound } from '../pages/NotFound/NotFound'
import ThemSwith from '../components/parts/ThemSwith'
import DeleteModal from '../components/Modals/DeleteModal'
import ReportModal from '../components/Modals/ReportModal'

const FlashcardPageLayout = () => {
    const me = useSelector(getMe)
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { flashCardSet, quizData, pagination, isLoading } = useSelector(state => state.flashcards)
    const { siteTheme } = useSelector(state => state.auth)
    const [path, setPath] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [reportModal, setReportModal] = useState(false);
    const ref = useRef(null);
    const [deleteModal, setDeleteModal] = useState(false)
    useEffect(() => {
        dispatch(getFlashCardSetByIdFetch({ id, query: `page=${quizData.round}` }))
    }, [id, quizData.round]);

    const navLinkHndler = (value) => {
        setPath(value)
    }

    const toggleLike = () => {
        dispatch(toggleLikeFlashcardFetch({ id: id }))
    }
    const toggleFavorite = () => {
        dispatch(toggleFavoriteFlashcardFetch({ id: id }))
    }
    const deleteHandler = async () => {
        const res = await dispatch(deleteFlashCardSetFetch(flashCardSet.id)).unwrap()
        if (res) {
            navigate('/quiz/flashcards')
        }
    }
    const progress = quizData?.cardsTotal > 0 ? quizData.cardsCompleted * 100 / quizData?.cardsTotal : 0
    const handleOpen = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true)
        ref?.current && isOpen ? ref.current?.classList.remove("lock") : ref.current?.classList.add("lock")

    }
    useEffect(() => {
        const a = document.getElementsByClassName('quiz-page')[0]
        ref.current = a
    }, []);

    if (isLoading) return <PageLoader />
    console.log(flashCardSet)
    if (!flashCardSet) {
        return null
    }
    return (
        <div className='quiz-page'>
            <HeaderQuiz prev={`/quiz/flashcards/${flashCardSet?.prevSetId}`} next={`/quiz/flashcards/${flashCardSet?.nextSetId}`} name={flashCardSet?.name} desctiption={path} />
            <aside className={`sidebar-left sidebar--quize ${isOpen ? 'active' : ''}`} style={{ zIndex: 60 }}
                data-sidebar-menu>
                <div className="sidebar-left__content">
                    <div className="sidebar-header header-size">
                        <div className="flex items-center gap-[12px]">
                            <Link to={'/quiz/flashcards'} type='button' className="btn btn--transparent btn--square btn--xs rounded-full">
                                <span className="ico ico-20">
                                    <ChevronLeftIcon />
                                </span>
                            </Link>
                            <Link to={"/"} className="logo logo-size">
                                {/* <img src={require('../assets/img/logo.png')} /> */}
                                <img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
                            </Link>
                        </div>
                        <div className="sidebar-left-hide-container-btn">
                            <button onClick={handleOpen} type="button" className="btn btn-burger btn--shadow">
                                <div className={`header__burger btn--xs btn--square ${isOpen ? 'active' : ''}`}>
                                    <span></span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="sidebar-body scrollstyle">
                        <div className="heading-block gap-[12px]">
                            <div className="group-between">
                                <div className="group-between--item">
                                    <div className="pill-custom">
                                        <span className="pill-text">Cardiac</span>
                                    </div>
                                    <a href="#" className="count-cards">
                                        <span className="ico">
                                            <CardsIcon />
                                        </span>
                                        <p className="count-cards-text">{quizData?.cardsTotal}</p>
                                    </a>
                                </div>
                            </div>
                            <h3 className="heading title-sidebar">
                                {flashCardSet?.name}
                            </h3>
                            {me?.id === flashCardSet?.user?.id && <div className="row-group gap--md-2">
                                <Link to={`/flashcards-edit/${flashCardSet.id}/general`} className="btn btn--primary btn--sm radius-5 flex-1">
                                    <span className="info text--md">Edit</span>
                                </Link>
                                <button type='button' onClick={() => setDeleteModal(true)} className="btn btn--transparent btn--sm radius-5  flex-1">
                                    <span className="ico">
                                        <DeleteIcon />
                                    </span>
                                    <span className="info text--md color-danger">Delete</span>
                                </button>
                            </div>}
                            <div className="progress">
                                <p className="progress-text">{progress.toFixed()} %</p>
                                <div className="progress-bar">
                                    <span className="progress-line" style={{ width: `${progress}%` }}></span>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-menu">
                            <ul className="menu-list">
                                <li>
                                    <NavLink to={"."} end className={({ isActive }) => {
                                        if (isActive) {
                                            navLinkHndler('Description')
                                        }
                                        return `btn btn--md btn-menu-link ${isActive ? "--active-link" : ""}`
                                    }}>
                                        <span className="ico ico-22">
                                            <InfoIcon />
                                        </span>
                                        <span>Description</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"flashcards/start"} className={({ isActive }) => {
                                        if (isActive) {
                                            navLinkHndler('Flashcards')
                                        }
                                        return `btn btn--md btn-menu-link ${isActive ? "--active-link" : ""}`
                                    }}>
                                        <span className="ico ico-22">
                                            <FlashcardsIcon />
                                        </span>
                                        <span>Flashcards</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"Selected"} className={({ isActive }) => {
                                        if (isActive) {
                                            setPath('Selected')
                                        }
                                        return `btn btn--md btn-menu-link ${isActive ? "--active-link" : ""}`
                                    }}>
                                        <span className="ico ico-22">
                                            <StarIcon />
                                        </span>
                                        <span>Selected Flashcards</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="sidebar-footer">
                        <ThemSwith />
                        <div className="group-between">
                            <div className="user-info user-info--sm">
                                <div className="ico user-ico image-wrapper rounded-full">
                                    {
                                        flashCardSet?.user?.photo ?
                                            <img src={getImageSrc(flashCardSet?.user?.photo)} alt={flashCardSet?.user?.firstName} />
                                            :
                                            <PlaceholderIcon />
                                    }
                                </div>
                                <p className="user-name">{flashCardSet?.user?.firstName + " " + flashCardSet?.user?.lastName}</p>
                            </div>
                            <div className="flex items-center gap-[5px]">
                                <button type="button"
                                    onClick={() => { setReportModal(prev => !prev) }}
                                    className={`ico ico--sm rounded-full btn--transparent`}>
                                    <span className="ico ico-22">
                                        <Report />
                                    </span>
                                </button>
                                {!flashCardSet?.disable_favorites && <button type="button"
                                    onClick={toggleFavorite}
                                    className={`ico ico--sm rounded-full btn--transparent favorite-btn ${flashCardSet?.isFavorite ? "--selected" : ""}`}>
                                    <span className="ico ico-22">
                                        <FavorIcon />
                                    </span>
                                </button>}
                                <button type="button"
                                    onClick={toggleLike}
                                    className={`ico ico--sm rounded-full btn--transparent favorite-btn ${flashCardSet?.isLiked ? "--selected" : ""}`}>
                                    <span className="ico ico-22">
                                        <LikeIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {deleteModal && <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'flashcard'} deleteHandler={deleteHandler} />}
            </aside>
            <div className="custom-overlay toggler-menu">
            </div>
            <div className="wrapper header-fixed sidebar-left-fixed">
                {
                    !isLoading && flashCardSet ?
                        <Outlet />
                        : isLoading ?
                            <PageLoader />
                            :
                            <h2>Not Found</h2>
                }
            </div>
            {reportModal && <ReportModal open={reportModal} handelClose={() => { setReportModal(prev => !prev) }} refType={'FlashCardSet'} />}
        </div>
    )
}

export default FlashcardPageLayout