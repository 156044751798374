import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getQuizDiscussionByQuizIdFeth, сlearQuizResultsbyQuizIdFeth } from '../../../store/quizzes/quizzesThunks'
import { useSelector } from 'react-redux'
import CommentSection from '../../../components/comments/CommentSection'
import { addCommentQuizIdFeth, getCommentsByEntityTypeEntityIdFeth } from '../../../store/comments/commentsThunk'
import PageLoader from '../../../components/parts/PageLoader'
import MediaRenderer from '../../../components/parts/MediaRenderer'
import { socket } from '../../../App'
import { setNewComment } from '../../../store/comments/commentsSlise'

const QuizPageDiscussion = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate();
	const [commentsLimit , setCommentsLimit] = useState(10)
	const [commentsPage , setCommentsPage] = useState(1)
	const { comments, pagination, commentsIsLoading } = useSelector(state => state.comments)
	const { quizeDiscussion, isLoadingDiscussion, quiz } = useSelector(state => state.quizzes)
	const [searchParams, setSearchParams] = useSearchParams()
	useEffect(() => {
		dispatch(getQuizDiscussionByQuizIdFeth({ id }))
	}, [id])
	
	const restartQuize = async () => {
		const res = await dispatch(сlearQuizResultsbyQuizIdFeth(id)).unwrap()
		if (res) {
			navigate(`/quiz-page/${id}/quiz`)
		}
	}

	useEffect(() => {
        if(!id) return 
        socket.emit("quizComments", {
            id: id
        });
        socket.on('newComment', (comment) => {
            dispatch(setNewComment(comment))
        });
        return () => {
            socket.off('newComment');
        };
    }, [id])

	useEffect(() => {
        if (id) {
            dispatch(getCommentsByEntityTypeEntityIdFeth({id, type: 'quiz', qwery: `limit=${commentsLimit}&page=${commentsPage}&${commentsPage === 1 ? `&${searchParams.toString()}` : '' }`}));
        }
    }, [commentsPage])
	if(isLoadingDiscussion) return <PageLoader/>
	return (
		<main className="content">
			<div className="quiz">
				<div className="quiz__content">
					<div className="quiz__body pt-[60px] pb-[60px]">
						<div className="container">
							{quiz?.quizSetting?.discussion ?
								<div className="w-full">
									<div className="discus-block">
										<h4>
											{quizeDiscussion?.discussion?.statement}
										</h4>
										<br />
										<div style={{ whiteSpace: 'pre-wrap'}}>
											{quizeDiscussion?.discussion?.description}
										</div>

										{quizeDiscussion?.discussion?.links?.length > 0 &&
											<>
												<br />
												<ul>
													<li>
														<p>Related Articles</p>
													</li>
													<li>
														<ul>
															{quizeDiscussion?.discussion?.links.map((link, index) => (
																<li key={index}>
																	{/* <a href={link} target='_blank'>{link}</a> */}
																	<MediaRenderer url={link}/>
																</li>
															))}
														</ul>
													</li>
												</ul>
											</>
										}
									</div>

									{/* <div className="discus-block">
										<div className="discus-controls">
											<div>
												<label>
													<input type="checkbox" />
													<span className="btn btn--sm radius-5 btn--transparent btn--like gap-[7px]">
														<span className="ico">
															<LikeIcon />
														</span>
														<p className="text-[13px] font-[500]">Like</p>
													</span>
												</label>
											</div>
											<div>
												<label>
													<input type="checkbox" />
													<span className="btn btn--sm radius-5 btn--transparent btn--save gap-[7px]">
														<span className="ico">
															<FavorIcon />
														</span>
														<p className="text-[13px] font-[500]">Save</p>
													</span>
												</label>
											</div>
											<div>
												<label>
													<input type="checkbox" />
													<span className="btn btn--sm radius-5 btn--transparent btn--share gap-[7px]">
														<span className="ico">
															<ShareIcon />
														</span>
														<p className="text-[13px] font-[500]">Share</p>
													</span>
												</label>
											</div>
										</div>
									</div> */}

									{quiz?.quizSetting?.enableComments &&  <div className="discus-block">
										<CommentSection 
											addCommentFeth={addCommentQuizIdFeth} 
											commentArrray={comments} 
											pagination = {pagination} 
											setLimit={setCommentsPage}
											isLoading={commentsIsLoading}
										/>
									</div>}
								</div>
								: <h3>Discussions are blocked by the author</h3>
							}
						</div>
					</div>
					<div className="quiz__footer">
						<div className="container">
							<button type='button' onClick={restartQuize} className="btn btn--shadow gap-[10px] btn--md radius-5 ml-auto">
								<span className="ico">
									<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M17.864 4.25244V7.49377H14.6235H17.864" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
										<path d="M17.4413 7.49359C16.1973 5.21384 13.7792 3.6665 10.9998 3.6665C6.95 3.6665 3.6665 6.95 3.6665 10.9998C3.6665 15.0497 6.95 18.3332 10.9998 18.3332C15.0497 18.3332 18.3332 15.0497 18.3332 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
								</span>
								<span className="info text-[13px] font-[500] flex">Restart Quiz</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}

export default QuizPageDiscussion