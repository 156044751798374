import { format } from 'date-fns'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatDateRange2, getImageSrc } from '../../../helpers/utils'
import { getMe, setShowLoginModal } from '../../../store/auth/authSlice'
import RegisterNowModal from '../../Modals/RegisterNowModal'

const CardEvent = ({ data }) => {
    const [registerModal, setRegisterModal] = useState(false);
    const dispatch = useDispatch()
    const me = useSelector(getMe)

    const handleBook = () => {
        if (!me) {
            dispatch(setShowLoginModal(true))
        } else {
            setRegisterModal(true)
        }

    }
    const checkIsEnd = (date) => {
        const dateNow = new Date()
        const eventDate = new Date(date)
        return dateNow > eventDate
    }
    const handlePreventAccess = (e) => {
        if (!me) {
            e.preventDefault()
            dispatch(setShowLoginModal(true))
        }
    }
    return (
        <>
            <div className="card card-post --card-row card-post--md">
                <Link to={`/events/${data.id}`} onClick={handlePreventAccess} className="card-header post-image">
                    <img src={getImageSrc(data.cover)} />
                </Link>

                <div className="card-body !overflow-visible">
                    <div className="card-body--item">
                        <Link to={`/events/${data.id}`} onClick={handlePreventAccess}>
                            <h4 className="heading post-title">{data.name}</h4>
                        </Link>
                    </div>
                    <div className="card-body--item">
                        <div className="group-between">
                            <div className="group-between--item flex-wrap">
                                <div className="pill-custom">
                                    <span className="pill-text">
                                        {data.filter?.name}
                                    </span>
                                </div>
                                <div className="event-date-group">
                                    <div className="flex">
                                        <img src={require("../../../assets/img/calendar-schedule-1.svg").default} />
                                    </div>
                                    <span className="event-date-text fw-600">{formatDateRange2(data.startedAt, data.endedAt)}</span>
                                </div>

                                <div className="event-date-group">
                                    <div className="flex">
                                        <img src={require("../../../assets/img/clock-watch.svg").default} />
                                    </div>
                                    <span className="event-date-text fw-500">{format(data.startedAt, "hh:mm aaa")} - {format(data.endedAt, "hh:mm aaa")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body--item">
                        <p className="post--desk">{data.description}</p>
                    </div>
                    <div className="card-body--item items-start !overflow-visible">
                        <button 
                            onClick={handleBook} 
                            className={`btn btn--shadow btn--md radius-5 ${checkIsEnd(data.endedAt) ? 'disabled' : ''}`}
                            
                        >
                                Register Now
                        </button>
                    </div>
                </div>
            </div>
            {registerModal && <RegisterNowModal id={data.id} open={registerModal} handelClose={() => setRegisterModal(false)} />}
        </>
    )
}

export default CardEvent