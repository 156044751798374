import React, { useEffect, useState } from 'react'
import SelectGroupAsynk from '../UI/formElements/SelectGroupAsynk'
import api from '../../api';
import ModalTemplate from './ModalTemplate';
import 'ckeditor5/ckeditor5.css';



const InsertSavedTableModal = ({ open, handelClose, editorRef }) => {
    const [selectedTableId, setSelectedTableId] = useState(null)
    const [selectedTable, setSelectedTable] = useState(null)
    const loadOptions = async (search, loadedOptions, { page }) => {
        const res = await api.saveTables.getAllTables(`page=${page}&search=${search}`);
        console.log(res)
        return {
            options: res.data?.map(table => ({ label: table.title, value: table.id })),
            hasMore: page != res?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        };
    };
    const fetchTableById = async (tableId) => {
        const res = await api.saveTables.getTableById(tableId)
        console.log(res)
        if(res?.success) {
            setSelectedTable(res.data)
        }
    }
    useEffect(() => {
        if(selectedTableId) {
            fetchTableById(selectedTableId)
        }
    }, [selectedTableId])
   
    const insertHandler = () => {
        if (editorRef.current) {
			const editor = editorRef.current;
			editor.model.change(writer => {
				const insertPosition = editor.model.document.selection.getFirstPosition();
                
				const viewFragment = editor.data.processor.toView(selectedTable?.content);
				const modelFragment = editor.data.toModel(viewFragment);
				
                editor.model.insertContent(modelFragment, editor.model.document.selection)
			});
            handelClose()
		}
    }
    return (
        <ModalTemplate open={open} handelClose={handelClose} modalTheme='z-[9999]' modalSize={'modal__dialog--870'} showHeader={true}>
            <div className="modal__body pt-0">
                <div className="modal__body-heading text-center">
                    <h2>Insert table</h2>
                </div>
                <div className="row g-24">
                    <div className="col-12">
                        <SelectGroupAsynk
                            groupClasses={'items-center gap--lg md:flex-nowrap'}
                            placeholder={'Select Table'}
                            wrapperClasses={'select--outline input--md w-full'}
                            onChange={(options) => {
                                setSelectedTableId(options.value)
                            }}
                            fetchFunk={loadOptions}

                        />
                    </div>
                    {selectedTable && <div className="col-12">
                       
                        <div className='ck ck-editor editor-container table-preview'>
                            <div className="ck ck-editor__main" >
                                <div className='ck ck-content' dangerouslySetInnerHTML={{__html: selectedTable?.content}} />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="modal__footer">
                <button
                    type='button'
                    onClick={handelClose}
                    className={`btn btn--shadow btn--md radius-5`}
                >
                    Cancel
                </button>
                <button type='button'
                    onClick={insertHandler}
                    className={`btn btn--primary btn--md radius-5 md:ml-auto ${!selectedTable ? "disabled" : ''}`}
                >
                    Insert table
                </button>
            </div>
        </ModalTemplate>
    )
}

export default InsertSavedTableModal
