import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllArticlesFeth = createAsyncThunk(
    '/getAllArticlesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.getAllArticles(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getArticleByIdFeth = createAsyncThunk(
    '/getArticleByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.getArticleById(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const sendArticleToAuthorModerationFeth = createAsyncThunk(
    '/sendArticleToAuthorModerationFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.sendArticleToAuthorModeration(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const sendArticleToAdminModerationFeth = createAsyncThunk(
    '/sendArticleToAdminModerationFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.sendArticleToAdminModeration(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getArticlesVersiunsForApprovalFeth = createAsyncThunk(
    '/getArticlesVersiunsForApprovalFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.getArticlesVersiunsForApproval(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const moderateArticleAuthorFeth = createAsyncThunk(
    '/moderateArticleAuthorFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.moderateArticleAuthor(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const authorPublishOwnChangesFeth = createAsyncThunk(
    '/authorPublishOwnChangesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.authorPublishOwnChanges(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getArticlesHistoryFeth = createAsyncThunk(
    '/getArticlesHistoryFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.getArticlesHistory(payload);
            if (res.success) {
                
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getUsersListFeth = createAsyncThunk(
    '/getUsersListFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.getUsersList(payload);
            if (res.success) {
                console.log(res)
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getRelatedQuizzesListFeth = createAsyncThunk(
    '/getRelatedQuizzesListFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.getRelatedQuizzesList(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createNewArticleFeth = createAsyncThunk(
    '/createNewArticleFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.createNewArticle(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addSettingsToArticleByArticleIdFeth = createAsyncThunk(
    '/addSettingsToArticleByArticleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.addSettingsToArticleByArticleId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeArticleByArticleIdFeth = createAsyncThunk(
    '/likeArticleByArticleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.likeArticleByArticleId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewArticleByArticleIdFeth = createAsyncThunk(
    '/viewArticleByArticleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.viewArticleByArticleId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            // toast.error(res.message)
        } catch (err) {
            // toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const toggleFavoriteArticleByArticleIdFeth = createAsyncThunk(
    '/toggleFavoriteArticleByArticleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.toggleFavoriteArticleByArticleId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editArticleByIdFeth = createAsyncThunk(
    '/editArticleByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.editArticleById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editArticleSettingsByArticleIdFeth = createAsyncThunk(
    '/editArticleSettingsByArticleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.editArticleSettingsByArticleId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteArticleByIdFeth = createAsyncThunk(
    '/deleteArticleByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.deleteArticleById(payload);
            if (res.success) {
                // toast.success(res.message)
                toast.success('The article will be deleted after approval by the administrator')
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const manageAuthorStatusFeth = createAsyncThunk(
    '/manageAuthorStatusFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.manageAuthorStatus(payload);
            if (res.success) {
                // toast.success(res.message)
                return { data: res.data, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const manageEditorStatusFeth = createAsyncThunk(
    '/manageEditorStatusFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.articles.manageEditorStatus(payload);
            if (res.success) {
                // toast.success(res.message)
                return { data: res.data, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);