const usersBase = '/api/articles';

export const saveTables = (instance) => ({
    async createTable(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/tables`, payload);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateTable({id, body}) {
        try {
            const { data } = await instance.post(`${usersBase}/tables/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getAllTables(qwery) {
        try {
            const { data } = await instance.get(`${usersBase}/tables?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getTableById(tableId) {
        try {
            const { data } = await instance.get(`${usersBase}/tables/${tableId}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteTable(tableId) {
        try {
            const { data } = await instance.delete(`${usersBase}/tables/${tableId}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
   
});