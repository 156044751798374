import React, { useEffect, useState } from 'react'
import BreadCrambs from '../../components/parts/BreadCrambs'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import UpgradeToPremium from '../../components/parts/UpgradeToPremium'
import SingleActions from '../../components/parts/SingleActions'
import { ReactComponent as NotesIcon } from '../../assets/img/notes-icon.svg'
import { ReactComponent as FullScreenIcon } from '../../assets/img/fullScreen-icon.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/scrollbar';
import CaseModal from '../../components/Modals/CaseModal'
import Accordeon from '../../components/UI/Accordeon'
import { useDispatch } from 'react-redux'
import { deleteCaseByIdFeth, favoriteCaseByIdToggleFeth, getCaseByIdFeth, likeCaseByIdToggleFeth, viewCaseByIdFeth } from '../../store/cases/casesThunk'
import { useSelector } from 'react-redux'
import PageLoader from '../../components/parts/PageLoader'
import { addCommentCaseIdFeth, getCommentsByEntityTypeEntityIdFeth } from '../../store/comments/commentsThunk'
import CommentSection from '../../components/comments/CommentSection'
import CardQuiz from '../../components/parts/Cards/CardQuiz'
import CardArticle from '../../components/parts/Cards/CardArticle'
import CardCase from '../../components/parts/Cards/CardCase'
import { getDateToString, getImageSrc, scroltoComment } from '../../helpers/utils'
import CaseGalleryPart from '../../components/parts/CaseGalleryPart'
import SirebarLatestCases from '../../components/parts/sidebarPosts/SirebarLatestCases'
import SidebarPopularQuizes from '../../components/parts/sidebarPosts/SidebarPopularQuizes'
import DeleteModal from '../../components/Modals/DeleteModal'
import { setNewComment } from '../../store/comments/commentsSlise'
import { socket } from '../../App'



const CaseSingle = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { caseOne, isLoading } = useSelector(state => state.cases)
    const { comments, pagination, commentsIsLoading } = useSelector(state => state.comments)
    const [commentsLimit, setCommentsLimit] = useState(10)
    const [commentsPage, setCommentsPage] = useState(1)
    const [swiperNotes, setSwiperNotes] = useState(true);
    const [fullScreeModal, setSullScreeModal] = useState(false);
    const [selectedImageSet, setSelectedImagesSet] = useState(null)
    const [selectedHintsIndex, setSelectedHintsIndex] = useState(0)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const fullScreeModalHandle = () => {
        setSullScreeModal(prev => !prev)
    }

    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/cases',
            label: 'Cases'
        },
        {
            link: '/cases/:id',
            label: caseOne?.name
        },
    ]
    const helpLinks = [
        {
            title: 'How to create and upload personal cases',
            url: '/faq'
        },
        {
            title: 'Bookmark cases',
            url: '/faq'
        },
        {
            title: 'Share cases with your colleagues',
            url: '/faq'
        },
        {
            title: 'Use images in presentations',
            url: '/faq'
        },
        {
            title: 'How to use cases in quizes',
            url: '/faq'
        },
    ]
    const toggleLike = () => {
        dispatch(likeCaseByIdToggleFeth(id))

    }
    const toggleFavorite = () => {
        dispatch(favoriteCaseByIdToggleFeth(id))

    }
    const editHandler = () => {
        navigate(`/case-edit/${id}`)
    }

    const thumbsClickHandler = (set, index) => {
        setSelectedImagesSet(set)
        setSelectedHintsIndex(index)
        setSelectedVideo(null)
    }
    const videoThumbsClickHandler = (video, index) => {
        setSelectedVideo(video)
        setSelectedImagesSet(null)
        setSelectedHintsIndex(index)

    }

    useEffect(() => {
        if(!id) return 
        socket.emit("caseComments", {
            id: id
        });
        socket.on('newComment', (comment) => {
            dispatch(setNewComment(comment))
        });
        return () => {
            socket.off('newComment');
        };
    }, [id])

    const getCategory = (array) => {
        if (array) {
            const category = array.filter(item => item.type === 'diagnosis')
            const generateClass = category[0].name === 'Diagnosis Certain' ? '--success' :
                category[0].name === 'Diagnosis almost certain' ? '--warn-2' :
                    category[0].name === 'Diagnosis probable' ? '--warn' : ''
            return { name: category[0].name, class: generateClass }
        }
    }

    const deleteHandler = async () => {
        const res = await dispatch(deleteCaseByIdFeth(id)).unwrap()
        if (res) {
            navigate('/cases')
        }
    }
    useEffect(() => {
        if (caseOne?.caseContent?.images) {
            setSelectedImagesSet(caseOne?.caseContent?.images[0])
            setSelectedHintsIndex(0)
        }
    }, [caseOne])
    useEffect(() => {
        if (id) {
            dispatch(getCommentsByEntityTypeEntityIdFeth({ id, type: 'case', qwery: `limit=${commentsLimit}&page=${commentsPage}${commentsPage === 1 ? `&${searchParams.toString()}` : ''}` }));
        }
    }, [id, commentsPage])
    useEffect(() => {
        dispatch(getCaseByIdFeth({ id }))
        dispatch(viewCaseByIdFeth(id))
    }, [id])

    if (isLoading) return <PageLoader />
    return (
        <>
            <main className="content">
                <BreadCrambs breabcrambs={breadcramds} />
                <section className="page-section">
                    <div className="container">

                        <div className="page-section--row">
                            <div className="page-sidebar --none-border" style={{ position: 'static' }}>
                                <aside className="sidebar post-sidebar sidebar-xs">
                                    <div className="sidebar-body">
                                        <Link to={'/cases'} className="btn btn--shadow btn--lg radius-5 mr-auto">
                                            <span className="ico"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.1665 10H15.8332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M8.33317 5.83334L4.1665 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M8.33317 14.1667L4.1665 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            </span>
                                            <span>Back to Cases</span>
                                        </Link>
                                        {caseOne?.caseRelated && caseOne?.caseRelated?.length > 0 ?
                                            <SirebarLatestCases
                                                cases={caseOne?.caseRelated}
                                                title={'More Cases'}
                                            />
                                            : null}
                                        {caseOne?.quizzes && caseOne?.quizzes?.length > 0 ?
                                            <SidebarPopularQuizes
                                                quizes={caseOne?.quizzes}
                                                title={'More Quizes'}
                                            />
                                            : null}
                                    </div>
                                </aside>
                            </div>
                            <div className="page-content">
                                <Link to={`/cases/${caseOne?.nextCaseId}`} className={`btn btn--primary 
                                    btn--lg radius-5 ml-auto lg:hidden ${caseOne?.nextCaseId ? '' : 'disabled'}`}>
                                    <span>Next Case</span>
                                    <span className="ico"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 12H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14 17L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M14 7L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    </span>
                                </Link>
                                <article className="single-post">
                                    <div>
                                        <div className={`pill-custom pill-2 pill-status ${getCategory(caseOne?.filters)?.class}`}>
                                            <span className="pill-text">{getCategory(caseOne?.filters)?.name}</span>
                                        </div>
                                        <h2>
                                            {caseOne?.name}
                                        </h2>
                                        <SingleActions
                                            likes={caseOne?.likes}
                                            displayUser={true}
                                            user={caseOne?.user}
                                            createAt={caseOne?.updatedAt}
                                            toggleLike={toggleLike}
                                            toggleFavorite={toggleFavorite}
                                            isLikeProps={caseOne?.isLiked}
                                            isFavoriteProps={caseOne?.isFavorite}
                                            handleDeleteButton={() => setDeleteModal(true)}
                                            editButtonHandler={editHandler}
                                            refType={'Case'}
                                        />
                                        <hr />
                                        <div>
                                            <small>Case Presentation</small>
                                            <p>
                                                {caseOne?.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div>

                                        <div className="article-cases">
                                            <div className="article-cases-head">
                                                <div className="ach-item">
                                                    <button type='button' onClick={() => {
                                                        setSwiperNotes(prev => !prev)
                                                    }} className={`btn btn--shadow btn--sm radius-5 ${swiperNotes && "active-darck"}`}>
                                                        <span className="ico">
                                                            <NotesIcon />
                                                        </span>
                                                        <span className="info">Show Notes</span>
                                                    </button>
                                                </div>

                                                <div className="ach-item">
                                                    <button type='button' onClick={fullScreeModalHandle} className="btn btn--shadow btn--sm radius-5">
                                                        <span className="ico">
                                                            <FullScreenIcon />
                                                        </span>
                                                        <span className="info">Full Screen</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="article-cases-other">
                                                <Swiper
                                                    // onSwiper={setThumbsSwiper}
                                                    freeMode={true}
                                                    watchSlidesProgress={true}
                                                    slidesPerView={'auto'}
                                                    spaceBetween={12}
                                                    navigation={{
                                                        nextEl: '.swiper-button-next',
                                                        prevEl: '.swiper-button-prev',
                                                    }}
                                                    modules={[FreeMode, Thumbs, Navigation]}
                                                    className='swiper-cards'
                                                >
                                                    {caseOne?.caseContent?.images?.map((imageSet, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className={`${selectedHintsIndex === index
                                                                ? 'swiper-slide-thumb-active' : ''}`}
                                                                onClick={() => thumbsClickHandler(imageSet, index)}>
                                                                <div className="card card-case">
                                                                    <div className="card-header post-image">
                                                                        <img src={getImageSrc(imageSet.images.find(image => image.isCover).fileName)} />
                                                                        <div className="card-pills">
                                                                            <div className="photos-count-pill">
                                                                                <span className="count">{imageSet.images?.length}</span>
                                                                                photos
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>
                                                                            {imageSet.setName}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                    {caseOne?.caseContent?.videos?.map((video, index) => (
                                                        <SwiperSlide key={caseOne?.caseContent?.images.length + index}>
                                                            <div className={`${selectedHintsIndex === index + caseOne?.caseContent?.images.length
                                                                ? 'swiper-slide-thumb-active' : ''}`}
                                                                onClick={() => videoThumbsClickHandler(video, index + caseOne?.caseContent?.images.length)}>
                                                                <div className="card card-case">
                                                                    <div className="card-header post-image">
                                                                        <video className='w-full h-full' src={getImageSrc(video.fileName)} />
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>
                                                                            {video.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                    <div className="swiper-button-prev bg-button--shadow swiper-button-disabled" >
                                                        <div className="btn btn--shadow btn--square btn--sm rounded-full">
                                                            <span className="ico"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.1665 10H15.8332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M8.33317 5.83334L4.1665 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M8.33317 14.1667L4.1665 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-button-next bg-button--shadow" >
                                                        <div className="btn btn--shadow btn--square btn--sm rounded-full">
                                                            <span className="ico">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M19 12H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M14 17L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M14 7L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Swiper>
                                            </div>
                                            <div className="swiper-slider-photos swiper-fad">
                                                <CaseGalleryPart imagesSet={selectedImageSet} selectedVideo={selectedVideo} showNotes={swiperNotes} />
                                            </div>

                                        </div>
                                    </div>
                                    {caseOne?.caseContent?.notes &&
                                        <div>
                                            <p>
                                                {caseOne?.caseContent?.notes}
                                            </p>
                                        </div>}
                                    <div>
                                        <div className="w-full">
                                            {caseOne?.quizzes && caseOne?.quizzes?.length > 0 && <Accordeon
                                                iconStyle='icon-first'
                                                title={'Related quizzes'}
                                            >
                                                <div className="pb-[20px]">
                                                    <div className="row g-16">
                                                        {caseOne?.quizzes.map(quize => (
                                                            <div className="col-xl-4 col-md-6" key={quize.id}>
                                                                <CardQuiz
                                                                    authorized={true}
                                                                    data={quize}
                                                                    hideActions={true}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Accordeon>}
                                            {caseOne?.articles && caseOne?.articles?.length > 0 && <Accordeon
                                                iconStyle='icon-first'
                                                title={'Related articles'}
                                            >
                                                <div className="pb-[20px]">
                                                    <div className="row g-16">
                                                        {caseOne?.articles.map(quize => (
                                                            <div className="col-xl-4 col-md-6" key={quize.id}>
                                                                <CardArticle
                                                                    authorized={true}
                                                                    data={quize}
                                                                    hideActions={true}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Accordeon>}
                                            {caseOne?.caseRelated && caseOne?.caseRelated?.length > 0 &&
                                                <Accordeon
                                                    iconStyle='icon-first'
                                                    title={'Cases you may like'}
                                                >
                                                    <div className="pb-[20px]">
                                                        <div className="row g-16">
                                                            {caseOne?.caseRelated.map(quize => (
                                                                <div className="col-xl-4 col-md-6" key={quize.id}>
                                                                    <CardCase
                                                                        authorized={true}
                                                                        data={quize}
                                                                        hideActions={true}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Accordeon>}
                                        </div>
                                    </div>
                                    <div>
                                        <SingleActions
                                            likes={caseOne?.likes}
                                            displayUser={true}
                                            user={caseOne?.user}
                                            createAt={caseOne?.updatedAt}
                                            toggleLike={toggleLike}
                                            toggleFavorite={toggleFavorite}
                                            isLikeProps={caseOne?.isLiked}
                                            isFavoriteProps={caseOne?.isFavorite}
                                            handleDeleteButton={() => setDeleteModal(true)}
                                            editButtonHandler={editHandler}
                                            refType={'Case'}
                                        />
                                    </div>
                                    {caseOne?.caseSetting?.enableComments && <div>
                                        <CommentSection
                                            addCommentFeth={addCommentCaseIdFeth}
                                            commentArrray={comments}
                                            pagination={pagination}
                                            setLimit={setCommentsPage}
                                            isLoading={commentsIsLoading}
                                        />
                                    </div>}
                                </article>
                            </div>
                            <div className="page-sidebar --none-border" style={{ position: 'static' }}>
                                <aside className="sidebar post-sidebar sidebar-xs">
                                    <div className="sidebar-body">
                                        <Link to={`/cases/${caseOne?.nextCaseId}`} className={`btn btn--primary 
                                                btn--lg radius-5 ml-auto max-lg:hidden ${caseOne?.nextCaseId ? '' : 'disabled'}`}>
                                            <span>Next Case</span>
                                            <span className="ico"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 12H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M14 17L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M14 7L19 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            </span>
                                        </Link>
                                        <SidebarHelpLinks
                                            title={'ABOUT CASES'}
                                            links={helpLinks}
                                            footerText={'You can use MedWise cases in a variety of ways to help you learn and teach.'}
                                        />

                                        <UpgradeToPremium />
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {fullScreeModal && <CaseModal open={fullScreeModal} handelClose={fullScreeModalHandle} />}
            {deleteModal && <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'case'} deleteHandler={deleteHandler} />}
        </>
    )
}

export default CaseSingle
