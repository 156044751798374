import React, { useEffect, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import CheckBoxGroup from '../UI/formElements/CheckBoxGroup'

const TableOptionsModal = ({ open, handelClose, editorRef }) => {
    const [tableOprions, setTableOptions] = useState({
        searching: false,
        ordering: false,
        buttons: false,
    })
    const [disabledOptions, setDisabledOptions] = useState(false)
    const inputHandler = (name, value) => {
        setTableOptions(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const getSelectedTable = (editor) => {

        const model = editor.model;
        const selection = model.document.selection;

        // Проверяем, находится ли фокус в таблице
        const table = selection.getFirstPosition().findAncestor('table');
        if (table) {
            const view = editor.editing.mapper.toViewElement(table);
            const domTable = editor.editing.view.domConverter.mapViewToDom(view);

            return domTable;
        }
        return null;

    }
    useEffect(() => {
        if (editorRef.current) {
            const selectedTable = getSelectedTable(editorRef.current)
            const option = selectedTable.dataset.option
            const thead = selectedTable.querySelector('thead')
            const colspan = selectedTable.querySelector('[colspan]')
            const rowspan = selectedTable.querySelector('[rowspan]')
            if (!thead || colspan || rowspan) {
                setDisabledOptions(true)
            }
            if(option) {
                setTableOptions(JSON.parse(option))
            }
        }
    }, [editorRef])
    const handleSaveSettings = () => {
        if (editorRef.current) {
            editorRef.current.fire('setTableAttributes', JSON.stringify(tableOprions));
            handelClose()
        }
    };
    return (
        <ModalTemplate open={open} handelClose={handelClose} modalTheme='z-[9999]' showHeader={true}>
            <div className="modal__body pt-0">
                <div className="modal__body-heading text-center">
                    <h2>Table options</h2>

                </div>
                {disabledOptions ?
                    <div className='text-start'>
                        <p className='mb-3'>In order to set the settings, you should:</p>
                        <ul className='leading-6'>
                            <li>
                                <b>1.</b>  Set table headers
                            </li>
                            <li>
                               <b>2.</b>  The table must not contain merged cells
                            </li>
                        </ul>
                    </div>
                    :
                    <div className="row g-24">
                        <div className="col-12">
                            <CheckBoxGroup
                                wrapperClasses={'input--lg'}
                                checked={tableOprions.searching}
                                onChange={({ target }) => inputHandler('searching', target.checked)}
                            >
                                <p className="check-text">
                                    Enable search
                                </p>
                            </CheckBoxGroup>
                        </div>
                        <div className="col-12">
                            <CheckBoxGroup
                                wrapperClasses={'input--lg'}
                                checked={tableOprions.ordering}
                                onChange={({ target }) => inputHandler('ordering', target.checked)}
                            >
                                <p className="check-text">
                                    Enable сolumn sorting
                                </p>
                            </CheckBoxGroup>
                        </div>
                        <div className="col-12">
                            <CheckBoxGroup
                                wrapperClasses={'input--lg'}
                                checked={tableOprions.buttons}
                                onChange={({ target }) => inputHandler('buttons', target.checked)}
                            >
                                <p className="check-text">
                                    Enable the ability to hide/show columns
                                </p>
                            </CheckBoxGroup>
                        </div>
                    </div>
                    }

            </div>
            <div className="modal__footer">
                <button
                    type='button'
                    onClick={handelClose}
                    className={`btn btn--shadow btn--md radius-5 `}

                >
                    Cancel
                </button>
                <button type='button'
                    onClick={handleSaveSettings}
                    className={`btn btn--primary btn--md radius-5 md:ml-auto ${disabledOptions ? 'disabled' : ''}`}
                    disabled={disabledOptions}
                >
                    Save
                </button>
            </div>
        </ModalTemplate>
    )
}

export default TableOptionsModal
