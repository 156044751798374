import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getProfileByIdFeth = createAsyncThunk(
    '/getProfileByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileQuizzesFeth = createAsyncThunk(
    '/getProfileQuizzesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileQuizzes(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileFlashCardsFeth = createAsyncThunk(
    '/getProfileFlashCardsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileFlashCards(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const disable2FAFeth = createAsyncThunk(
    '/disable2FAFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.disable2FA(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileForumsFeth = createAsyncThunk(
    '/getProfileForumsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileForums(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const toggleUserBlockFeth = createAsyncThunk(
    '/toggleUserBlockFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.toggleUserBlock(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileCoursesFeth = createAsyncThunk(
    '/getProfileCoursesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileCourses(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileCassesFeth = createAsyncThunk(
    '/getProfileCassesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileCasses(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileArticlesFeth = createAsyncThunk(
    '/getProfileArticlesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileArticles(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileCommentsFeth = createAsyncThunk(
    '/getProfileCommentsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileComments(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileMaterialsFeth = createAsyncThunk(
    '/getProfileMaterialsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileMaterials(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getProfileThreadsFetch = createAsyncThunk(
    '/getProfileThreadsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.getProfileThreads(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addExperienceFeth = createAsyncThunk(
    '/addExperienceFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.addExperience(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const followUserByProfileIdFeth = createAsyncThunk(
    '/followUserByProfileIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.followUserByProfileId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCommentIdFeth = createAsyncThunk(
    '/addCommentIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.addCommentId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCertificateFeth = createAsyncThunk(
    '/addCertificateFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.addCertificate(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addExperticeFeth = createAsyncThunk(
    '/addExperticeFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.addExpertice(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editProfileFeth = createAsyncThunk(
    '/editProfileFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.editProfile(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editExperienceByExpIdFeth = createAsyncThunk(
    '/editExperienceByExpIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.editExperienceByExpId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCertificateByCertIdFeth = createAsyncThunk(
    '/editCertificateByCertIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.editCertificateByCertId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteExperienceByExpIdFeth = createAsyncThunk(
    '/deleteExperienceByExpIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.deleteExperienceByExpId(payload);
            if (res.success) {
                // toast.success(res.message)
                return { res, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteCertificateByCertIdFeth = createAsyncThunk(
    '/deleteCertificateByCertIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.deleteCertificateByCertId(payload);
            if (res.success) {
                // toast.success(res.message)
                return { res, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteExpertiseByExpertiseIdFeth = createAsyncThunk(
    '/deleteExpertiseByExpertiseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.deleteExpertiseByExpertiseId(payload);
            if (res.success) {
                // toast.success(res.message)
                return { res, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteUserPhotoFeth = createAsyncThunk(
    '/deleteUserPhotoFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.profile.deleteUserPhoto();
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);