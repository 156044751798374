const base = '/api/reports';

export const reports = (instance) => ({
    async createReport(payload) {
        try {
            const { data } = await instance.post(`${base}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});