import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Flashcard from '../../../components/parts/Flashcard';
import QuizSteps from '../../../components/parts/QuizSteps';
import { STATUSES } from '../../../helpers/constants';
import { flashcardsQuizEnd, flashcardsQuizStart, setCurrentCardIndex, submitFlashcardAnswer, updateCardStats } from '../../../store/flashcards/flashcardsSlice';
import { addFlashcardCardStatsByCardIdFetch } from '../../../store/flashcards/flashcardsThunks';




const FlashcardPageFlashcards = () => {
    const { flashCardSet: { flashcards, cards_per_round }, isLoading, quizData, pagination} = useSelector(state => state.flashcards)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [currentCardIndex, setcurrentCardIndex] = useState(0)
    const [startCardTime, setStartCardTime] = useState(Date.now())
    const [isCardOpen, setCardOpen] = useState()
    
    useEffect(() => {
        if ( quizData?.cardsCompleted === pagination.total) {
            navigate("end")
        } else {
            dispatch(flashcardsQuizStart())
        }
    }, [])
    console.log(currentCardIndex)
    useEffect(() => {
        if(quizData) {
            setcurrentCardIndex(quizData.currentCardIndex >= cards_per_round ? quizData.currentCardIndex - (cards_per_round * (quizData.round - 1)) : quizData.currentCardIndex)
        }
    }, [quizData])
    if (!flashcards?.length) {
        return (
            <div className='flex flex-auto items-center justify-center'>
                <h2>There is no flashcards</h2>
            </div>
        )
    }
    console.log(quizData)
    const handleSubmit = async (value) => {
        
        const body = {
            "difficulty": value,
            "timeSpent":Math.floor(( Date.now() - startCardTime) / 1000)
        }
       
        // dispatch(submitFlashcardAnswer({ id: flashcards[currentCardIndex]?.id, status: value }))
        const res = await dispatch(addFlashcardCardStatsByCardIdFetch({id: flashcards[currentCardIndex]?.id , body })).unwrap();
        if(res) {
            dispatch(updateCardStats({id: flashcards[currentCardIndex]?.id , body }))
            setStartCardTime(Date.now())
            if (currentCardIndex < flashcards?.length - 1) {
                // setCurrentCardIndex(prev => prev + 1)
                dispatch(setCurrentCardIndex(currentCardIndex + 1))
            } else {
                dispatch(flashcardsQuizEnd())
                navigate("end")
                dispatch(setCurrentCardIndex(0))
            }
            setCardOpen(false)
        }
    }

    const handleCardOpen = (isOpened) => {
        setCardOpen(isOpened)
    }

    return (
        <main className="content">
            {
                <div className="quiz">
                    <QuizSteps data={flashcards} total={pagination.total} activeIndex={currentCardIndex} />
                    <div className="quiz__content card-fix-mobail">
                        <div className="quiz__body">
                            <div className="container full-center">
                                <Flashcard data={flashcards[currentCardIndex]} onOpen={handleCardOpen} />
                            </div>
                        </div>
                        <div className="quiz__footer bg-white">
                            <div className="container gap-[12px]">
                                <p className="flex items-center gap-[6px]">
                                    <span className="ico">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.62933 14.6667H3.40733C2.63 14.6667 2 14.0367 2 13.2594V6.22203C2 5.4447 2.63 4.8147 3.40733 4.8147H7.62933C8.40667 4.8147 9.03667 5.4447 9.03667 6.22203V13.2594C9.03733 14.0367 8.40667 14.6667 7.62933 14.6667Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M6.50146 4.81468L7.15413 2.37668C7.35546 1.62601 8.12746 1.18001 8.87813 1.38135L12.9561 2.47401C13.7068 2.67535 14.1528 3.44668 13.9515 4.19801L12.1301 10.9953C11.9288 11.746 11.1575 12.192 10.4068 11.9907L9.0368 11.624" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <span>{quizData?.cardsCompleted + 1} / {pagination?.total}</span>
                                </p>
                                {
                                    isCardOpen ?
                                        <div className="group-between">
                                            <div className="group-between--item gap--xs">
                                                <button onClick={() => handleSubmit(STATUSES.AGAIN.name)} className="btn btn--outline-danger btn--md gap-[6px] radius-5">
                                                    <span className="text--sm color-default">Again</span>
                                                    <span className="text--sm">{quizData?.againCount}</span>
                                                </button>
                                                <button onClick={() => handleSubmit(STATUSES.HARD.name)} className="btn btn--outline-warning btn--md gap-[6px] radius-5">
                                                    <span className="text--sm color-default">Hard</span>
                                                    <span className="text--sm">{quizData?.hardCount}</span>
                                                </button>
                                                <button onClick={() => handleSubmit(STATUSES.GOOD.name)} className="btn btn--outline-success btn--md gap-[6px] radius-5">
                                                    <span className="text--sm color-default">Good</span>
                                                    <span className="text--sm">{quizData?.goodCount}</span>
                                                </button>
                                                <button onClick={() => handleSubmit(STATUSES.EASY.name)} className="btn btn--outline-pending btn--md gap-[6px] radius-5">
                                                    <span className="text--sm color-default">Easy</span>
                                                    <span className="text--sm">{quizData?.easyCount}</span>
                                                </button>
                                            </div>
                                        </div>
                                        : null
                                }
                                <button onClick={() => handleSubmit(STATUSES.SKIP.name)} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                    <span className="info text-[13px] font-[500] sm:flex hidden">Next Card</span>
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.375 14.6668L16.0417 11.0002L12.375 7.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M5.95801 15.5834L10.5413 11.0001L5.95801 6.41675" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </main>
    )
}

export default FlashcardPageFlashcards