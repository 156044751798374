import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom'


import { ReactComponent as PresentationIcon } from '../../assets/img/sidebar-presentation.svg'
import { ReactComponent as ImagesIcon } from '../../assets/img/sidebar-image-videos.svg'
import { ReactComponent as HintsIcon } from '../../assets/img/sidebar-hints.svg'
import { ReactComponent as QuizIcon } from '../../assets/img/sidebar-quiz.svg'
import { ReactComponent as DescroptionIcon } from '../../assets/img/sidebar-message.svg'
import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import { ReactComponent as FavorIcon } from "../../assets/img/ico-favor.svg"
import { ReactComponent as LikeIcon } from "../../assets/img/ico-like.svg"
import { ReactComponent as DeleteIcon } from '../../assets/img/delete-Icon.svg';
import { ReactComponent as Report } from "../../assets/img/report-icon.svg";
import logo from "../../assets/img/logo.png"
import logoDark from "../../assets/img/logo-footer.png"
import ThemSwith from './ThemSwith'
import { getImageSrc } from '../../helpers/utils'
import { useDispatch } from 'react-redux'
import { delQuizByIdFeth, toggleFavoriteQuizByIdFeth, toggleLikeQuizByIdFeth } from '../../store/quizzes/quizzesThunks'
import { useSelector } from 'react-redux'
import { setActiveHintWathIndex, setActiveImageWathIndex, setActiveVideoWathIndex } from '../../store/quizzes/quizzesSlice'
import { getMe } from '../../store/auth/authSlice'
import DeleteModal from '../Modals/DeleteModal'
import ReportModal from '../Modals/ReportModal'



const SidebarQuizPage = ({ quizData, navHandler }) => {
    const dispatch = useDispatch();
    // const id = useParams()
    const me = useSelector(getMe)
    const links = [
        {
            icon: <PresentationIcon />,
            to: 'presentation',
            label: 'Case Presentation'
        },
        {
            icon: <ImagesIcon />,
            to: 'materials',
            label: 'Images & Videos'
        },
        {
            icon: <HintsIcon />,
            to: 'hints',
            label: 'Hints'
        },
        {
            icon: <QuizIcon />,
            to: 'quiz',
            label: 'Quiz'
        },
        {
            icon: <DescroptionIcon />,
            to: 'discussion',
            label: 'Discussion'
        },

    ]
    const createDate = new Date(quizData?.updatedAt)
    const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const { pathname } = useLocation()
    let navigate = useNavigate()
    const { quizMedia, isLoading, quizeHints, activeHintWathIndex, activeImageWathIndex, activeVideoWathIndex } = useSelector(state => state.quizzes)
    const { siteTheme } = useSelector(state => state.auth)
    const [isOpen, setIsOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('images');
    const [reportModal, setReportModal] = useState(false);
    const ref = useRef(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const handleOpen = () => {
        if (window.innerWidth < 992) {
            isOpen ? setIsOpen(false) : setIsOpen(true)
            ref?.current && isOpen ? ref.current?.classList.remove("lock") : ref.current?.classList.add("lock")
        }
    }

    useEffect(() => {
        const a = document.getElementsByClassName('quiz-page')[0]
        ref.current = a
    }, []);
    const toggleLike = () => {
        dispatch(toggleLikeQuizByIdFeth(quizData.id))
    }
    const toggleFavorite = () => {
        dispatch(toggleFavoriteQuizByIdFeth(quizData.id))
    }
    const activeImagesHandler = (value) => {
        dispatch(setActiveImageWathIndex(value))
    }
    const activeVideosHandler = (value) => {
        dispatch(setActiveVideoWathIndex(value))
    }
    const deleteHandler = async () => {
        const res = await dispatch(delQuizByIdFeth(quizData.id)).unwrap()
        if (res) {
            navigate('/quiz')
        }
    }
    const activeHintsHandler = (value) => {
        dispatch(setActiveHintWathIndex(value))
    }
    useEffect(() => {
        if (pathname.includes('hints')) {
            navHandler('Hints')
        }
        if (pathname.includes('materials')) {
            navHandler('Images & Videos')
            setActiveTab('images')
        }
        if (pathname.includes('materials/video')) {
            setActiveTab('videos')
        }
        if (pathname.includes('materials/notes')) {
            setActiveTab('notes')
        }
    }, [pathname])

    if (!quizData) return null
    return (
        <aside className={`sidebar-left sidebar--quize ${isOpen ? 'active' : ''}`} style={{ zIndex: 60 }} data-sidebar-menu="">

            <div className="sidebar-left__content">
                <div className="sidebar-header header-size">
                    <div className="flex items-center gap-[12px]">
                        <Link to={'/quiz'} className="btn btn--transparent btn--square btn--xs rounded-full">
                            <span className="ico ico-20">
                                <img src={require('../../assets/img/chevron-prev.svg').default} />
                            </span>
                        </Link>
                        <Link to={"/"} className="logo logo-size">
                            {/* <img src={require('../../assets/img/logo.png')} /> */}
                            <img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
                        </Link>
                    </div>
                    <div className="sidebar-left-hide-container-btn">
                        <button onClick={handleOpen} type="button" className="btn btn-burger btn--shadow">
                            <div className={`header__burger btn--xs btn--square ${isOpen ? 'active' : ''}`}>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="sidebar-body scrollstyle">
                    {(pathname.includes('hints') || pathname.includes('materials')) ? <>
                        <div className="tabs-header">
                            <button type='button' onClick={() => { pathname.includes('materials') ? navigate('presentation') : navigate('materials') }} className="btn btn-tablink btn--xl radius-5">
                                <span className="ico ico-22">
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11 3.25V3.25C15.5568 3.25 19.25 6.94325 19.25 11.5V11.5C19.25 16.0568 15.5568 19.75 11 19.75V19.75C6.44325 19.75 2.75 16.0568 2.75 11.5V11.5C2.75 6.94325 6.44325 3.25 11 3.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.3335 11.5H14.6668" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.0835 14.25L7.3335 11.5L10.0835 8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span>Back</span>
                            </button>
                            {pathname.includes('materials') &&
                                <ul className="tabs-nav">
                                    <li className="tabs-nav--item">
                                        <Link to={'materials'}
                                            onClick={() => {

                                                setActiveTab('images')
                                            }}
                                            className={`btn btn-tablink btn--xl radius-5 ${activeTab === 'images' && 'active-tab'}`}>
                                            <span className="ico ico-22">
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.9314 11.3207L11.9317 11.3203C11.9992 11.2507 11.9975 11.1395 11.9279 11.072C11.8582 11.0044 11.7471 11.0061 11.6795 11.0756C11.6119 11.1452 11.6135 11.2564 11.683 11.324C11.7525 11.3917 11.8637 11.3902 11.9314 11.3207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M3.25 12.6917C3.54494 12.6463 3.8428 12.6224 4.1412 12.6204V12.6204C5.79574 12.6204 7.3825 13.2776 8.55243 14.4476C9.72237 15.6175 10.3796 17.2043 10.3796 18.8588C10.3769 19.1572 10.3531 19.455 10.3083 19.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <rect x="3.25" y="6.91669" width="12.8333" height="12.8333" rx="3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></rect>
                                                    <path d="M6 6.91667V6C6 4.48122 7.23122 3.25 8.75 3.25H17C18.5188 3.25 19.75 4.48122 19.75 6V14.25C19.75 15.7688 18.5188 17 17 17H16.0833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span>Images</span>
                                        </Link>
                                    </li>
                                    <li className="tabs-nav--item">
                                        <Link to={'materials/video'} onClick={() => {

                                            setActiveTab('videos')
                                        }} className={`btn btn-tablink btn--xl radius-5 ${activeTab === 'videos' && 'active-tab'}`}>
                                            <span className="ico ico-22">
                                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.0294 8.80317L13.5879 10.9078C14.0379 11.1737 14.0379 11.8254 13.5879 12.0913L10.0294 14.1959C9.57103 14.4673 8.9917 14.1363 8.9917 13.6038V9.39534C8.9917 8.86275 9.57103 8.53184 10.0294 8.80317V8.80317Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.5 19.75H5.5C3.98108 19.75 2.75 18.5189 2.75 17V6C2.75 4.48108 3.98108 3.25 5.5 3.25H16.5C18.0189 3.25 19.25 4.48108 19.25 6V17C19.25 18.5189 18.0189 19.75 16.5 19.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span>Videos</span>
                                        </Link>
                                    </li>
                                    <li className="tabs-nav--item">
                                        <Link to={'materials/notes'} onClick={() => {
                                            handleOpen()
                                            setActiveTab('notes')
                                        }} className={`btn btn-tablink btn--xl radius-5 ${activeTab === 'notes' && 'active-tab'}`}>
                                            <span className="ico ico-22">
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.8119 11.9898L12.3525 17.4492C12.0019 17.7998 11.7744 18.2547 11.7043 18.7456L11.5046 20.1434C11.4842 20.2862 11.5322 20.4303 11.6342 20.5323C11.7363 20.6343 11.8803 20.6823 12.0232 20.6619L13.421 20.4622C13.9119 20.3921 14.3668 20.1647 14.7174 19.8141L20.1768 14.3546C20.8298 13.7016 20.8298 12.6428 20.1768 11.9898V11.9898C19.8632 11.6762 19.4379 11.5 18.9944 11.5C18.5509 11.5 18.1255 11.6762 17.8119 11.9898Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M8.75 9.66668H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M8.75 13.3333H10.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M8.74984 19.75H5.99984C5.51374 19.7501 5.0475 19.5572 4.70368 19.2135C4.35986 18.8699 4.16664 18.4038 4.1665 17.9177V5.08333C4.16637 4.59724 4.35934 4.13099 4.70297 3.78718C5.0466 3.44336 5.51273 3.25013 5.99883 3.25H16.9998C17.4859 3.24986 17.9522 3.44284 18.296 3.78646C18.6398 4.13009 18.833 4.59623 18.8332 5.08233V8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M3.25 7.83333H5.38583" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M3.25 11.5H5.38583" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M3.14014 15.1667H5.38597" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span>Notes</span>
                                        </Link>
                                    </li>
                                </ul>}
                        </div>
                        <div className="tabs-body">
                            {pathname.includes('hints') &&
                                <div className="tabs-item active-tab" id="images">
                                    {quizeHints?.length > 0 ? <ul className="blueray-list">
                                        {quizeHints.map((item, index) => (
                                            <li className="blueray-item" key={item.id}>
                                                <label className="blueray-control">
                                                    <input type="radio" checked={index === activeHintWathIndex} onChange={() => {
                                                        handleOpen()
                                                        activeHintsHandler(index)
                                                    }} className="hidden" name="hints" />
                                                    <div className="btn blueray-btn">
                                                        <img src={getImageSrc(item.fileName)} />
                                                    </div>
                                                    {item.name ? <p className="blueray-title">{item.name}</p> : null}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                        :
                                        <h3 className='text-center'>
                                            No Hints added
                                        </h3>
                                    }
                                </div>
                            }
                            {pathname.includes('materials') &&
                                <div className="tabs-item  active-tab" id="images">
                                    {activeTab === 'images' &&
                                        <>
                                            {quizMedia?.images?.length > 0 ?
                                                <ul className="blueray-list">
                                                    {quizMedia?.images.map((item, index) =>
                                                        <li key={item.id} className="blueray-item">
                                                            <label className="blueray-control">
                                                                <input
                                                                    type="radio"
                                                                    checked={activeImageWathIndex === index}
                                                                    value={index}
                                                                    onChange={() => {
                                                                        handleOpen()
                                                                        activeImagesHandler(index)
                                                                    }} className="hidden"
                                                                    name="blueray"
                                                                />
                                                                <div className="btn blueray-btn">
                                                                    <img src={getImageSrc(item?.fileName)} />
                                                                </div>
                                                                <p className="blueray-title">
                                                                    {item.name}
                                                                </p>
                                                            </label>
                                                        </li>)}
                                                </ul>
                                                :
                                                <h3 className='text-center'>
                                                    No pictures added
                                                </h3>
                                            }
                                        </>
                                    }
                                    {activeTab === 'videos' &&
                                        <>
                                            {quizMedia?.videos?.length > 0 ? <ul className="blueray-list">
                                                {quizMedia?.videos.map((item, index) => (
                                                    <li className="blueray-item" key={item.id}>
                                                        <label className="blueray-control">
                                                            <input
                                                                type="radio"
                                                                checked={activeVideoWathIndex === index}
                                                                value={index}
                                                                className="hidden"
                                                                name="video"
                                                                onChange={() => {
                                                                    handleOpen()
                                                                    activeVideosHandler(index)
                                                                }}
                                                            />
                                                            <div className="btn blueray-btn">
                                                                <video
                                                                    className="w-full"
                                                                    src={getImageSrc(item.fileName)}
                                                                ></video>
                                                            </div>
                                                            <p className="blueray-title">
                                                                {item.name}
                                                            </p>
                                                        </label>
                                                    </li>))}
                                            </ul> :
                                                <h3 className='text-center'>
                                                    No videos added
                                                </h3>
                                            }
                                        </>
                                    }
                                    {activeTab === 'notes' &&
                                        <ul className="list-notes">
                                            <li className="note-item">
                                                <div className="note-block">
                                                    <p className="note-block--title">Author Notes</p>
                                                    <p className="note-block--desk">
                                                        {quizMedia?.notes}
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            }
                        </div>
                    </> :
                        <>
                            <div className="heading-block gap-[12px]">
                                <div className="group-between">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{quizData?.filter?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{createDate.getDate()} {month_names_short[createDate.getMonth()]}, {createDate.getFullYear()}</p>
                                    </div>
                                </div>
                                <h3 className="heading title-sidebar">
                                    {quizData?.name}
                                </h3>
                                {me?.id === quizData?.user?.id && <div className="row-group gap--md-2">
                                    <Link to={`/quiz-edit/${quizData.id}/general`} className="btn btn--primary btn--sm radius-5 flex-1">
                                        <span className="info text--md">Edit</span>
                                    </Link>
                                    <button type='button' onClick={() => setDeleteModal(true)} className="btn btn--transparent btn--sm radius-5  flex-1">
                                        <span className="ico">
                                            <DeleteIcon />
                                        </span>
                                        <span className="info text--md color-danger">Delete</span>
                                    </button>
                                </div>}
                            </div>
                            <div className="sidebar-menu">
                                <ul className="menu-list">
                                    {links.map((link, index) => (
                                        <li key={index}>
                                            <NavLink to={link.to}
                                                onClick={() => {

                                                    if (link.label === 'Hints' || link.label === 'Images & Videos') return
                                                    handleOpen()
                                                }} className={({ isActive }) => {
                                                    if (isActive) {
                                                        navHandler(link.label)
                                                    }
                                                    return ` btn btn--md btn-menu-link ${isActive ? '--active-link' : ''
                                                        }`
                                                }}>
                                                <span className="ico ico-22">
                                                    {link.icon}
                                                </span>
                                                <span>{link.label}</span>

                                            </NavLink>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </>
                    }
                </div>
                <div className="sidebar-footer">
                    <ThemSwith />
                    <div className="group-between">
                        <div className="user-info user-info--sm">
                            <div className="ico user-ico image-wrapper rounded-full">
                                {
                                    quizData?.user?.photo ?
                                        <img src={getImageSrc(quizData?.user?.photo)} alt={quizData?.user?.firstName} />
                                        :
                                        <PlaceholderIcon />
                                }
                            </div>
                            <p className="user-name">{quizData?.user?.firstName + " " + quizData?.user?.lastName}</p>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <button type="button"
                            onClick={() => {setReportModal(prev => !prev)}}
                                className={`ico ico--sm rounded-full btn--transparent`}>
                                <span className="ico ico-22">
                                    <Report />
                                </span>
                            </button>
                            <button type="button"
                                onClick={toggleFavorite}
                                className={`ico ico--sm rounded-full btn--transparent favorite-btn ${quizData?.isFavorite ? "--selected" : ""}`}>
                                <span className="ico ico-22">
                                    <FavorIcon />
                                </span>
                            </button>
                            <button type="button"
                                onClick={toggleLike}
                                className={`ico ico--sm rounded-full btn--transparent favorite-btn ${quizData?.isLiked ? "--selected" : ""}`}>
                                <span className="ico ico-22">
                                    <LikeIcon />
                                </span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'quiz'} deleteHandler={deleteHandler} />
            {reportModal && <ReportModal open={reportModal} handelClose={() => {setReportModal(prev => !prev)}} refType={'Quiz'}/>}
        </aside>
    )
}

export default SidebarQuizPage
