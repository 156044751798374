import React, { useEffect, useMemo, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import SingleActions from '../../components/parts/SingleActions'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { setClearArticle } from '../../store/articles/articlesSlice'
import { useDispatch } from 'react-redux'
import Loader from '../../components/UI/Loader'
import 'ckeditor5/ckeditor5.css';
import { initializeChart } from '../../hooks/creacteChartFn'
import $ from 'jquery';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
// import 'datatables.net-rowgroup-dt/css/rowGroup.dataTables.min.css';

import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';
import 'datatables.net';
import 'datatables.net-responsive-dt';
// import 'datatables.net-rowgroup-dt';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs5';
import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
import 'datatables.net-buttons/js/buttons.colVis'
import { getMe } from '../../store/auth/authSlice'
import { authorPublishOwnChangesFeth, sendArticleToAdminModerationFeth, sendArticleToAuthorModerationFeth } from '../../store/articles/articlesThunks'
import { toast } from 'react-toastify'

const ArticleCreatePreview = () => {
    const { id } = useParams()
    const user = useSelector(getMe)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { articleId, article, isLoading } = useSelector(state => state.articles)
    const [isLoadingFake, setIsLoadingFake] = useState(false)
    const conf = {
        title: 'Preview Article',
    }

    const isEdittor = useMemo(() => {
        return (article?.editors?.map((editor) => editor?.id) || []).includes(user?.id)
    }, [article, user?.id])

    const isCreator = useMemo(() => article?.user?.id === user?.id, [article, user?.id])
    const isAutor = useMemo(() => {
        return (article?.authors?.map((author) => author?.id) || []).includes(user?.id)
    }, [article, user?.id])

    const approvalHandler = async () => {
        let res = null
        setIsLoadingFake(true)
        if (isEdittor || isCreator || isAutor) {
            res = await dispatch(sendArticleToAdminModerationFeth(id || articleId)).unwrap()
        } else {
            res = await dispatch(sendArticleToAuthorModerationFeth(id)).unwrap()
        }
        setIsLoadingFake(false)
    }
    const publishHandler = async () => {
        setIsLoadingFake(true)
        const res = await dispatch(authorPublishOwnChangesFeth(id)).unwrap()
        if (res) {
            toast.success('Article is Publish')
        }
        setIsLoadingFake(false)
    }

    useEffect(() => {
        if (!article && isLoadingFake) return

       
        let tableTimeout = setTimeout(() => {
            let tables = document.querySelectorAll('table')
            tables.forEach((table, index) => {
                const tableHasOptions = $(table).closest('[data-option]').attr('data-option')
                if (tableHasOptions) {
                    
                    const tableOptions = JSON.parse(tableHasOptions)

                    if(
                        !tableOptions.searching && 
                        !tableOptions.ordering && 
                        !tableOptions.buttons
                    ) {
                        return
                    }

                    $(table).closest('.table').attr('id', `datatable-${index}`)
                    
                    const datatable = $(table).DataTable({
                        paging: false,
                        searching: tableOptions.searching,
                        ordering: tableOptions.ordering,
                        info: false,
                        // scrollX: true,
                        buttons:tableOptions.buttons ? [
                            'colvis', 
                        ] : [],
                    })
                    if(tableOptions.buttons) {
                        datatable.buttons().container().appendTo(`#datatable-${index} .dt-layout-start:eq(0)`);
                    }
                }
            })

        }, 1000)
        let chartTimeOut = setTimeout(() => {
            let canvasElements = document.querySelectorAll('.chartImage')

            canvasElements.forEach((element, index) => {
                const chartData = element.getAttribute('data-chart');
                initializeChart(JSON.parse(chartData), element);
            });
        }, 1000)
        return () => {
            clearTimeout(tableTimeout)
            clearTimeout(chartTimeOut)
        }
    }, [article, isLoadingFake]);

    if (isLoadingFake) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader />
        </div>
    )
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz create-article footer-fixed pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="pill-custom mb-[20px]">
                                        <span className="pill-text">{article?.filter?.name}</span>
                                    </div>
                                    <h3 className="mb-[32px]">{article?.title}</h3>
                                    <SingleActions wrapperClasses={'mb-[32px]'} disabledButtons={true} user={article?.user} createAt={article?.createdAt} likes={article?.likes} displayUser={article?.displayAuthorName} />
                                    <div className="single-post editor-text editor-container ck-content" dangerouslySetInnerHTML={{ __html: article?.content }} >
                                    </div>

                                </div>

                            </div>
                            <div className="quiz__footer">
                                <div className="container">
                                    <div className="quiz__footer__group-btns">
                                        <Link to={'/articles'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Cancel</span>
                                        </Link>
                                        <div className="flex gap-[12px]">
                                            {article?.isCompleted && (isCreator || isEdittor || isAutor) ?
                                                <button type='button' onClick={publishHandler} className={`btn btn--primary gap-[10px] btn--md radius-5 ${article?.moderationStatus === 'draft' ? '' : 'disabled'}`}>
                                                    <span className="info text-[13px] font-[500]">
                                                        {article?.moderationStatus === 'draft' ? 'Published' : 'Article is Published'}
                                                    </span>
                                                </button>
                                                :
                                                <button type='button' onClick={approvalHandler} disabled={article?.status !== 'draft'} className={`btn btn--primary gap-[10px] btn--md radius-5 ${article?.status !== 'draft' ? 'disabled' : ''}`}>
                                                    <span className="info text-[13px] font-[500]">
                                                        {isEdittor || isCreator || isAutor ? 'Submit for approval' : 'Suggest changes'}
                                                    </span>
                                                </button>
                                            }
                                            <button type='button' onClick={() => {
                                                setIsLoadingFake(true)
                                                setTimeout(() => {
                                                    setIsLoadingFake(false)
                                                }, 1000)
                                            }} className="btn btn--primary gap-[10px] btn--md radius-5">
                                                <span className="info text-[13px] font-[500]">Save</span>
                                            </button>
                                            <button onClick={() => {
                                                dispatch(setClearArticle())
                                                navigate(`/article-create`)
                                            }} className="btn btn--primary gap-[10px] btn--md radius-5">
                                                <span className="info text-[13px] font-[500]">Save and Create Another</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ArticleCreatePreview
