import { createSlice, current } from '@reduxjs/toolkit';
import { getCommentsByEntityTypeEntityIdFeth } from './commentsThunk';
import { getThreadCommentFeth } from '../forums/forumsThunk';


const initialState = {
    comments: [],
    pagination: null,
    commentsIsLoading: false
}

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        setNewComment: (state, { payload }) => {
            const updateComments = (comments) =>
                comments.map((comment) => {
                    if (comment.id === payload.parentId) {
                        return {
                            ...comment,
                            replies: comment.replies ? [...comment.replies, payload] : [payload],
                        };
                    } else if (comment.replies) {
                        return {
                            ...comment,
                            replies: updateComments(comment.replies)
                        };
                    } else {
                        return comment;
                    }
                });

            if (!payload.parentId) {
                state.comments = [payload, ...state.comments]
            } else {
                state.comments = updateComments(state.comments)
            }
        },
        setDelComment: (state, { payload }) => {
            function deleteCommentOrReply(comments) {
                return comments.filter(comment => {
                  // Если id совпадает с комментарием, удаляем его
                  if (comment.id === payload) {
                    return false;
                  }
              
                  // Фильтруем ответы внутри комментария
                  if (comment.replies && comment.replies.length > 0) {
                    comment.replies = comment.replies.filter(reply => reply.id !== payload);
                  }
              
                  // Оставляем комментарий, если он не был удален
                  return true;
                });
              }
              state.comments = deleteCommentOrReply(state.comments)
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getCommentsByEntityTypeEntityIdFeth.fulfilled, (state, action) => {
                state.commentsIsLoading = false
                if (action.payload?.pagination.page === 1) {
                    state.comments = action.payload.comments
                } else {
                    state.comments = [...state.comments, ...action.payload.comments]
                }

                state.pagination = action.payload?.pagination
            })
            .addCase(getCommentsByEntityTypeEntityIdFeth.pending, (state, action) => {
                state.commentsIsLoading = true
            })
            .addCase(getCommentsByEntityTypeEntityIdFeth.rejected, (state, action) => {
                state.commentsIsLoading = false
            })
            .addCase(getThreadCommentFeth.fulfilled, (state, action) => {
                state.commentsIsLoading = false

                if (action.payload.pagination.page === 1) {
                    state.comments = action.payload.comments
                } else {
                    state.comments = [...state.comments, ...action.payload.comments]
                }

                state.pagination = action.payload.pagination
            })
            .addCase(getThreadCommentFeth.pending, (state, action) => {
                state.commentsIsLoading = true
            })
            .addCase(getThreadCommentFeth.rejected, (state, action) => {
                state.commentsIsLoading = false
            })
    }
})

export const {
    setNewComment,
    setDelComment
} = commentsSlice.actions;


export default commentsSlice.reducer