import React, { useEffect, useState } from 'react'

import ChatMessageHead from './ChatMessageHead';
import SendMessageForm from './SendMessageForm';
import MessagesBody from './MessagesBody';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';


import { getAllConversationsFeth, getConversationByIdFeth, messagesMarkAsReadFeth } from '../../../store/chat/chatThunk';
import { socket } from '../../../App';
import { useSelector } from 'react-redux';
import { setNewMessageToCats, setSearchMessageId } from '../../../store/chat/chatSlice';
import PageLoader from '../../../components/parts/PageLoader';


const MessagesPart = ({ activeMobilePart, chatHandler }) => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const { allChats, currentChatData, searchMessageId } = useSelector(state => state.chat)
    const [limits, setLimets] = useState(20)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isFirstLoad, setIsFirsLoad] = useState(false)

    const fethMessages = async () => {
        if (!hasMore || loading) return;
        if (page === 1) {
            setIsFirsLoad(true)
        }
        setLoading(true)
        const res = await dispatch(getConversationByIdFeth({ id: id, qwery: `page=${page}&messageId=${searchMessageId ? searchMessageId : ''}` })).unwrap()
        if (res) {
            if(searchMessageId) {
                setPage(res.pagination.page)
            }
            if (res.pagination.maxPages === page) {
                setHasMore(false)
            }

        }
        setLoading(false)
        setIsFirsLoad(false)
    }
   
    useEffect(() => {
        setHasMore(true)
        // setLimets(10)        
        setPage(1)
        dispatch(setSearchMessageId(null))
    }, [id])
    useEffect(() => {
        if (id && !searchMessageId) {
            fethMessages()
        }
    }, [id, page, hasMore])
    // useEffect(() => {
    //     if (page > 1) {
    //         fethMessages()
    //     }
    // }, [page, hasMore])
    useEffect(() => {
        if(searchMessageId) {
            fethMessages()
        }
    }, [searchMessageId])

    if (isFirstLoad) return <PageLoader />
    if (!currentChatData) return (
        <div className='empty-selected-chat flex-auto flex items-center justify-center flex-col gap-3'>
            <h4>Chat Not Found</h4>
        </div>
    )
    return (
        <div className={`chart-block ${activeMobilePart != 'users-list' ? 'active-chat-block' : ''}`}>
            <ChatMessageHead chatHandler={chatHandler} />
            <MessagesBody setLimets={setPage} />
            <SendMessageForm />
        </div>
    )
}

export default MessagesPart
