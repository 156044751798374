import { createSlice } from '@reduxjs/toolkit';
import { addCertificateFeth, addCommentIdFeth, addExperienceFeth, addExperticeFeth, deleteCertificateByCertIdFeth, deleteExperienceByExpIdFeth, deleteExpertiseByExpertiseIdFeth, deleteUserPhotoFeth, editCertificateByCertIdFeth, editExperienceByExpIdFeth, editProfileFeth, followUserByProfileIdFeth, getProfileArticlesFeth, getProfileByIdFeth, getProfileCassesFeth, getProfileCommentsFeth, getProfileCoursesFeth, getProfileFlashCardsFeth, getProfileForumsFeth, getProfileMaterialsFeth, getProfileQuizzesFeth, getProfileThreadsFetch, toggleUserBlockFeth } from './profileThunk';
import { likeArticleByArticleIdFeth, toggleFavoriteArticleByArticleIdFeth } from '../articles/articlesThunks';
import { favoriteCaseByIdToggleFeth, likeCaseByIdToggleFeth } from '../cases/casesThunk';
import { favoriteCourseByCourseIdFeth, likeCourseByCourseIdFeth } from '../courses/coursesThunk';
import { favoriteForumFeth, likeForumFeth } from '../forums/forumsThunk';
import { toggleFavoriteQuizByIdFeth, toggleLikeQuizByIdFeth } from '../quizzes/quizzesThunks';
import { favoriteMaterialLibraryByidFeth, likeMaterialLibraryByIdFeth } from '../materials/materialLibraryThunks';



const initialState = {
    profile: null,
    profileData: [],
    isLoading: false,
    errors: null,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {

    },

    extraReducers(builder) {
        builder
            .addCase(getProfileByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profile = action.payload
            })
            .addCase(getProfileByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(getProfileByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileQuizzesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileQuizzesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileQuizzesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileFlashCardsFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileFlashCardsFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileFlashCardsFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileForumsFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileForumsFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileForumsFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileCoursesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileCoursesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileCoursesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileCassesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileCassesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileCassesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileArticlesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileArticlesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileArticlesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileMaterialsFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileMaterialsFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileMaterialsFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileThreadsFetch.fulfilled, (state, action) => {
                state.isLoading = false
                state.profileData = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileThreadsFetch.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileThreadsFetch.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getProfileCommentsFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload?.pagination.page === 1) {
                    state.profileData = action.payload?.data
                } else {
                    state.profileData = [...state.profileData, ...action.payload?.data]
                }

                state.pagination = action.payload?.pagination;
            })
            .addCase(getProfileCommentsFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
                state.profileData = null
            })
            .addCase(getProfileCommentsFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editProfileFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profile = {
                    ...state.profile,
                    ...action.payload
                }
            })
            .addCase(editProfileFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editProfileFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(addExperticeFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profile.expertise = action.payload
            })
            .addCase(addExperticeFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addExperticeFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(addCertificateFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profile.certificates.push(action.payload)
            })
            .addCase(addCertificateFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addCertificateFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(addExperienceFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.profile.experiences.push(action.payload)
            })
            .addCase(addExperienceFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addExperienceFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editCertificateByCertIdFeth.fulfilled, (state, action) => {
                const currentCetrificate = state.profile.certificates.findIndex(item => item.id === action.payload.id)
                state.profile.certificates[currentCetrificate] = action.payload
                state.isLoading = false
            })
            .addCase(editCertificateByCertIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editCertificateByCertIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editExperienceByExpIdFeth.fulfilled, (state, action) => {
                const currentCetrificate = state.profile.experiences.findIndex(item => item.id === action.payload.id)
                state.profile.experiences[currentCetrificate] = action.payload
                state.isLoading = false
            })
            .addCase(editExperienceByExpIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editExperienceByExpIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(deleteExpertiseByExpertiseIdFeth.fulfilled, (state, action) => {
                state.profile.expertise = state.profile.expertise.filter(item => item.id !== action.payload.id)
            })
            .addCase(deleteUserPhotoFeth.fulfilled, (state, action) => {
                state.profile.user.photo = null
            })
            .addCase(deleteCertificateByCertIdFeth.fulfilled, (state, action) => {
                state.profile.certificates = state.profile.certificates.filter((item) => item.id != action.payload.id)
            })
            .addCase(deleteExperienceByExpIdFeth.fulfilled, (state, action) => {
                state.profile.experiences = state.profile.experiences.filter((item) => item.id != action.payload.id)
            })
            .addCase(followUserByProfileIdFeth.fulfilled, (state, action) => {
                state.profile.isFollow = action.payload.isFollow
                state.profile.user = {
                    ...state.profile.user,
                    ...action.payload.user
                }

            })
            .addCase(toggleUserBlockFeth.fulfilled, (state, action) => {
                if (state.profile) {
                    state.profile.isBlocked = action.payload.isBlocked
                }
            })
            .addCase(likeArticleByArticleIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes

                }
                if (state.profile) {
                    state.profile.activity.likes = action.payload.isLiked ? state.profile.activity.likes + 1 : state.profile.activity.likes - 1
                }

            })
            .addCase(toggleFavoriteArticleByArticleIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(likeCaseByIdToggleFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if (state.profile) {
                    state.profile.activity.likes = action.payload.isLiked ? state.profile.activity.likes + 1 : state.profile.activity.likes - 1
                }
            })
            .addCase(favoriteCaseByIdToggleFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(likeCourseByCourseIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if (state.profile) {
                    state.profile.activity.likes = action.payload.isLiked ? state.profile.activity.likes + 1 : state.profile.activity.likes - 1
                }
            })
            .addCase(favoriteCourseByCourseIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(likeForumFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if (state.profile) {
                    state.profile.activity.likes = action.payload.isLiked ? state.profile.activity.likes + 1 : state.profile.activity.likes - 1
                }
            })
            .addCase(favoriteForumFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(toggleFavoriteQuizByIdFeth.fulfilled, (state, action) => {
                const quizeCurrent = state.profileData.find(item => item.id === action.payload.id)
                if (quizeCurrent) {
                    quizeCurrent.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(toggleLikeQuizByIdFeth.fulfilled, (state, action) => {
                const quizeCurrent = state.profileData.find(item => item.id === action.payload.id)
                if (quizeCurrent) {
                    quizeCurrent.isLiked = action.payload.isLiked
                    quizeCurrent.likes = action.payload.likes
                }
                if (state.profile) {
                    state.profile.activity.likes = action.payload.isLiked ? state.profile.activity.likes + 1 : state.profile.activity.likes - 1
                }
            })
            .addCase(likeMaterialLibraryByIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if (state.profile) {
                    state.profile.activity.likes = action.payload.isLiked ? state.profile.activity.likes + 1 : state.profile.activity.likes - 1
                }
            })
            .addCase(favoriteMaterialLibraryByidFeth.fulfilled, (state, action) => {
                let currentArticle = state.profileData.find(item => item.id === action.payload.id);
                if (currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }

            })
    }
})

export const {

} = profileSlice.actions;


export default profileSlice.reducer