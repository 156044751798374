import { Command, Plugin, ResizeObserver } from "ckeditor5"

class RowHeightCommand extends Command {
    execute(options) {
        const model = this.editor.model;
        const doc = model.document;

        model.change(writer => {
            const selection = doc.selection;
            const tableRow = selection.getFirstPosition().findAncestor('tableRow');

            if (tableRow) {
                writer.setAttribute('rowHeight', options.height, tableRow);
            }
        });
    }

    refresh() {
        const selection = this.editor.model.document.selection;
        this.isEnabled = selection.hasAttribute('tableRow');
    }
}

export default class TableRowsResize extends Plugin {
    init() {
        const editor = this.editor;

        // Регистрация команды rowHeight
        editor.commands.add('rowHeight', new RowHeightCommand(editor));

        // Расширяем схему для таблицы
        editor.model.schema.extend('tableRow', { allowAttributes: ['rowHeight'] });

        // Обработка downcast для атрибута rowHeight
        editor.conversion.for('downcast').attributeToAttribute({
            model: 'rowHeight',
            view: (attributeValue, { writer }) => {
                if (typeof attributeValue === 'undefined' || attributeValue === null) {
                    return;
                }
                const heightValue = attributeValue.toString();
                if (heightValue) {
                    return {
                        key: 'style',
                        value: {
                            height: `${heightValue}px`
                        }
                    };
                }
            }
        });

        // Обработка upcast для атрибута rowHeight
        editor.conversion.for('upcast').attributeToAttribute({
            view: {
                name: 'tr',
                styles: {
                    height: /[\s\S]+/
                }
            },
            model: {
                key: 'rowHeight',
                value: viewElement => {
                    const height = viewElement.getStyle('height');
                    return height ? parseInt(height, 10) || null : null;
                }
            }
        });

        this._addResizeDivToCells();
    }

    _addResizeDivToCells() {
        const editor = this.editor;

        // Добавляем div для изменения размера в каждую ячейку таблицы
        editor.conversion.for('editingDowncast').add(dispatcher => {
            dispatcher.on('insert:tableCell', (evt, data, conversionApi) => {
                const viewWriter = conversionApi.writer;
                const viewCell = conversionApi.mapper.toViewElement(data.item);
                    const resizeDiv = viewWriter.createUIElement('div', {
                        class: 'table-row-resize-handle'
                    });
                    // Вставляем div в конец ячейки
                    viewWriter.insert(viewWriter.createPositionAt( viewCell , 'end'), resizeDiv);
            });
        });
        this._enableRowResizing();
    }

    _enableRowResizing() {
        const editor = this.editor;
        const view = editor.editing.view;

        let resizing = false;
        let startY = 0;
        let startHeight = 0;

        view.document.on('mousedown', (event, data) => {
            const target = data.target;

            // Проверяем, является ли целевой элемент ручкой изменения размера
            if (target.hasClass('table-row-resize-handle')) {
                data.preventDefault();
                data.stopPropagation();
                resizing = true;
                startY = data.domEvent.clientY;

                const viewTableRow = target.findAncestor('tr');
                const domTableRow = view.domConverter.mapViewToDom(viewTableRow);

                if (domTableRow) {
                    startHeight = domTableRow.offsetHeight;
                } else {
                    resizing = false;
                    return;
                }

                // Обработка перемещения мыши
                const onMouseMove = (moveEvent) => {
                    if (resizing) {
                        const newHeight = startHeight + (moveEvent.clientY - startY);
                        if (newHeight > 20) { // Минимальная высота строки
                            const rowElement = target.findAncestor('tr');
                            if (rowElement) {
                                editor.model.change(writer => {
                                    const tableRow = editor.editing.mapper.toModelElement(rowElement);
                                    if (tableRow) {
                                        writer.setAttribute('rowHeight', newHeight, tableRow);
                                    }
                                });
                            }
                        }
                    }
                };

                const onMouseUp = () => {
                    resizing = false;
                    window.removeEventListener('mousemove', onMouseMove);
                    window.removeEventListener('mouseup', onMouseUp);
                };

                window.addEventListener('mousemove', onMouseMove);
                window.addEventListener('mouseup', onMouseUp);
            }
        });
    }
}
