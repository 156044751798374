export const filterOptions = [
    { value: "latest", label: "Latest" },
    { value: "likes", label: "Most liked" },
    { value: "views", label: "Most Views" },
]

export const categoryOptions = [
    { value: "Breast", label: "Breast" },
    { value: "Cardiac", label: "Cardiac" },
    { value: "Central Nervous System", label: "Central Nervous System" },
    { value: "Chest", label: "Chest" },
]

export const socialLinks = {
    youtube: 'https://www.youtube.com/channel/UCrrkPk7M9DhEX9XeNg2evmg',
    facebook: 'https://www.facebook.com/people/RCVIR/61567204733364/',
    tweeter: 'https://x.com/RCVIR_org',
    linkedin: 'https://www.linkedin.com/company/rcvir/'
}

export const STATUSES = {
    GOOD: {
        name: "Good",
        color: "#2CC932"
    },
    AGAIN: {
        name: "Again",
        color: "#F02B10",
    },
    HARD: {
        name: "Hard",
        color: "#F08910"
    },
    EASY: {
        name: "Easy",
        color: "#08A0F6"
    },
    SKIP: {
        name: "Skip",
        color: "#08A0F6"
    },
    default: {
        name: "default",
        color: "#999999"
    },
    active: {
        name: "active",
        color: "#999999"
    }
}
