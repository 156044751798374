import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SelectGroup from '../UI/formElements/SelectGroup'
import ModalTemplate from './ModalTemplate'
import TextareaGroup from '../UI/formElements/TextareaGroup'
import { toast } from 'react-toastify'
import api from '../../api'
import ButtonLoader from '../UI/ButtonLoader'
const reasonCategories = [
    {
        label: 'Inaccurate Translation',
        value: 'Inaccurate Translation'
    },
    {
        label: 'File Upload Issue',
        value: 'File Upload Issue'
    },
    {
        label: 'Formatting Problems',
        value: 'Formatting Problems'
    },
    {
        label: 'Missing Sections',
        value: 'Missing Sections'
    },
    {
        label: 'Document Type Not Supported',
        value: 'Document Type Not Supported'
    },
    {
        label: 'Slow Processing Time',
        value: 'Slow Processing Time'
    },
    {
        label: 'Incorrect Language Output',
        value: 'Incorrect Language Output'
    },
    {
        label: 'Other (with a text field for more details)',
        value: 'Other (with a text field for more details)'
    },
]

const ReportModal = ({ open, handelClose, refType }) => {
    const {threadId, id } = useParams()
    const [formData, setFormData] = useState({
        reason: '',
        content: '',
    })
    const [isLoading, setIsLoading] = useState(false)

    const formDataHandler = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(() => {
        setFormData({
            reason: '',
            content: '',
        })
    }, [open])
    const submitHandler = async () => {
        if (formData.reason === '') {
            toast.error('Select reason')
            return
        }
        setIsLoading(true)
        const data = {
            refId: +threadId || +id,
            refType,
            reason: formData.reason,
            content: formData.content === '' ? null : formData.content
        }
        const res = await api.reports.createReport(data)
        console.log(res)
        if (res?.success) {
            toast.success(res?.message)
        }
        if (!res?.success && res?.message) {
            toast.error(res?.message)
        }
        setIsLoading(false)
        handelClose()
    }
    return (
        <ModalTemplate open={open} handelClose={handelClose} showHeader={true} >


            <div className="modal__body items-center pt-0">
                <div className="modal__body-heading text-center">
                    <h2>Leave a complaint</h2>
                </div>
                <div className="row g-24">
                    <div className="col-12">
                        <SelectGroup
                            label={'Why are you submitting a complaint?'}
                            wrapperClasses={'select--outline input--lg'}
                            placeholder={'Select a reason'}
                            options={reasonCategories}
                            onChange={(option) => { formDataHandler('reason', option.value) }}
                        />
                    </div>
                    <div className="col-12">
                        <TextareaGroup
                            wrapperClasses='input--lg'
                            label={'Enter the text of the complaint'}
                            textareaClasses={'input--outline p-[16px]'}
                            placeholder={'Enter your text...'}
                            onChange={({ target }) => formDataHandler('content', target.value)}
                            value={formData.content}
                        />
                    </div>
                </div>
            </div>
            <div className="modal__footer">

                <button
                    type='button'
                    onClick={handelClose}
                    className={`btn btn--shadow btn--md radius-5 ${isLoading ? 'disabled': ''}`}
                    disabled={isLoading}
                >
                    Cancel
                </button>
                <button type='button'
                    onClick={submitHandler} 
                    className={`btn btn--primary btn--md radius-5 md:ml-auto ${isLoading ? 'disabled': ''}`}
                    disabled={isLoading}
                >
                    {
                        isLoading
                            ? <ButtonLoader /> :
                            'Send complaint'
                    }
                </button>
            </div>


        </ModalTemplate>
    )
}

export default ReportModal
