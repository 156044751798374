import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';
import { finalizeUploadToast } from '../../helpers/utils';


export const getAllQuizzesFeth = createAsyncThunk(
    '/getAllQuizzesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getAllQuizzes(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getQuizGeneralByQuizIdFeth = createAsyncThunk(
    '/getQuizGeneralByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getQuizGeneralByQuizId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getQuizDiscussionByQuizIdFeth = createAsyncThunk(
    '/getQuizDiscussionByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getQuizDiscussionByQuizId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getQuizQuestionsByQuizIdFeth = createAsyncThunk(
    '/getQuizQuestionsByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getQuizQuestionsByQuizId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getQuizMediaByQuizIdFeth = createAsyncThunk(
    '/getQuizMediaByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getQuizMediaByQuizId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createQuizFeth = createAsyncThunk(
    '/createQuizFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.createQuiz(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addQuizMediaFilesByQuizIdFeth = createAsyncThunk(
    '/addQuizMediaFilesByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.addQuizMediaFilesByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                finalizeUploadToast()
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addQuizHintFilesByQuizIdFeth = createAsyncThunk(
    '/addQuizHintFilesByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.addQuizHintFilesByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addQuizQuestionsByQuizIdFeth = createAsyncThunk(
    '/addQuizQuestionsByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.addQuizQuestionsByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addQuizSettingsByQuizIdFeth = createAsyncThunk(
    '/addQuizSettingsByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            
            const res = await api.quizzes.addQuizSettingsByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const toggleFavoriteQuizByIdFeth = createAsyncThunk(
    '/toggleFavoriteQuizByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.toggleFavoriteQuizById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const toggleLikeQuizByIdFeth = createAsyncThunk(
    '/toggleLikeQuizByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.toggleLikeQuizById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewQuizByIdFeth = createAsyncThunk(
    '/viewQuizByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.viewQuizById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            // toast.error(res.message)
        } catch (err) {
            // toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editQuizByIdFeth = createAsyncThunk(
    '/editQuizByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.editQuizById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editQuizMediaFilesByQuizIdFeth = createAsyncThunk(
    '/editQuizMediaFilesByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.editQuizMediaFilesByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                finalizeUploadToast()
                return res.data;
            } 
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editQuizHintFilesByQuizIdFeth = createAsyncThunk(
    '/editQuizHintFilesByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.editQuizHintFilesByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editQuizQuestionsByQuestionIdFeth = createAsyncThunk(
    '/editQuizQuestionsByQuestionIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.editQuizQuestionsByQuestionId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editQuizSettingsByIdFeth = createAsyncThunk(
    '/editQuizSettingsByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.editQuizSettingsById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteQuizQuestionsByQuestionIdFeth = createAsyncThunk(
    '/deleteQuizQuestionsByQuestionIdFeth/',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.deleteQuizQuestionsByQuestionId(id);
            if (res.success) {
                toast.success(res.message)
                return {res, id};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addQuizQuestionsOrderingByQuizIdFeth = createAsyncThunk(
    '/addQuizQuestionsOrderingByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.addQuizQuestionsOrderingByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addQuizResultByQuestionIdFeth = createAsyncThunk(
    '/addQuizResultByQuestionIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.addQuizResultByQuestionId(payload);
            if (res.success) {
                toast.success(res.message)
                return {data: res.data, questionId: payload.id};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const сlearQuizResultsbyQuizIdFeth = createAsyncThunk(
    '/сlearQuizResultsbyQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.сlearQuizResultsbyQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getQuizResultsByQuizIdFeth = createAsyncThunk(
    '/getQuizResultsByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getQuizResultsByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const cloneQuizQuestionFeth = createAsyncThunk(
    '/cloneQuizQuestionFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.cloneQuizQuestion(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getQuizHintsByQuizIdFeth = createAsyncThunk(
    '/getQuizHintsByQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.getQuizHintsByQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const delQuizByIdFeth = createAsyncThunk(
    '/delQuizByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.quizzes.delQuizById(payload);
            if (res.success) {
                toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
