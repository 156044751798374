import { useEffect, useState } from "react";
import ModalTemplate from "./ModalTemplate";
import { useSelector } from "react-redux";
import InputGroupRow from "../UI/formElements/InputGroupRow";
import { useValidation } from "../../hooks/useValidation";
import TextareaGroupRow from "../UI/formElements/TextareaGroupRow";
import ButtonLoader from "../UI/ButtonLoader";
import { createRuleFeth, deleteRuleByRuleIdFeth, rulesOrderingByForumIdFeth, updateRuleByRuleIdFeth } from "../../store/forums/forumsThunk";
import { useDispatch } from "react-redux";
import { setRule, setRuleId } from "../../store/forums/forumsSlice";


export const AddRuleModal = ({ open, handleClose }) => {
    const dispatch = useDispatch()
    const { forumOne, forumId, currentRule, currentRuleId, isLoadingRule } = useSelector(state => state.forums)
    const [formData, setFormData] = useState({
        rule: "",
        description: "",
        order: forumOne?.rules?.length || 0
    })

    const formDataHandler = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const validators = {
        rule: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
    };
    const messages = {
        rule: 'First name is required',
        description: 'Description is required',
    };
    const exceptions = ['order']
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages,
            exceptions
        }
    );

    const removeHandler = async (id) => {
        const filteredRules = forumOne.rules.filter(item => item.id !== id)
        const res = await dispatch(deleteRuleByRuleIdFeth(id)).unwrap();
        if (res) {
            const orderingData = filteredRules.map((item, index) => ({
                id: item.id,
                order: index
            }))
            const res2 = await dispatch(rulesOrderingByForumIdFeth({
                id: forumId, body: {
                    "ordering": orderingData
                }
            })).unwrap()
            if (res2) {
                handleClose()
            }
        }
    }
    const clearState = () => {
        dispatch(setRuleId(null))
        dispatch(setRule(null))
        setFormData({
            rule: "",
            description: "",
            order: forumOne?.rules?.length || 0
        })
    }
    const submitHandler = async (e, createAnother = false) => {

        e.preventDefault();
        if (errors) {
            console.log(errors)
            setIsErrorShown(true)
            return
        }
        let res = null
        if (currentRuleId) {
            res = await dispatch(updateRuleByRuleIdFeth({ id: currentRuleId, body: formData })).unwrap()
        } else {
            res = await dispatch(createRuleFeth({ id: forumId, body: formData })).unwrap()
        }
        if (res) {
            if (createAnother) {
                clearState()
            }
        }
    }
    useEffect(() => {
        if (currentRuleId) {
            setFormData(currentRule)
        } else {
            clearState()
        }
    }, [currentRuleId, currentRule])
    return (
        <ModalTemplate 
            open={open}
            handelClose={() => {
                handleClose()
                clearState()
            }}
            modalSize={'modal__dialog--870'}
        >
            <>
                <div className="modal__body items-center pt-0">
                    <div className="modal__body-heading text-center">
                        {currentRuleId ? <h2>Edit Rule</h2> : <h2>Add new Rule</h2>}
                    </div>
                    <div className="list-group list-group--lg w-full">
                        <InputGroupRow
                            label={"Rule"}
                            inputClasses={'input--outline'}
                            placeholder={'Enter your rule...'}
                            value={formData.rule}
                            onChange={({ target }) => formDataHandler('rule', target.value)}
                            error={isErrorShown && errors?.rule}
                            errorMessage={errorMessages?.rule}
                        />
                        <TextareaGroupRow
                            label={'Description'}
                            placeholder={"Enter Description..."}
                            textareaClasses="input--outline"
                            onChange={({ target }) => formDataHandler('description', target.value)}
                            value={formData.description}
                            rows="7"
                            error={isErrorShown && errors?.description}
                            errorMessage={errorMessages?.description}
                        />
                    </div>
                </div>

                <div className="modal__footer">
                    {currentRuleId ?
                        <button
                            type='button'
                            disabled={isLoadingRule}
                            onClick={() => { removeHandler(currentRuleId) }}
                            className="btn btn--danger btn--md radius-5">
                            {
                                isLoadingRule
                                    ? <ButtonLoader /> :
                                    'Delete'
                            }
                        </button>
                        :
                        <button
                            type='button'
                            onClick={() => {
                                handleClose()
                                clearState()
                            }}
                            className="btn btn--shadow btn--md radius-5"
                            disabled={isLoadingRule}
                        >
                            {
                                isLoadingRule
                                    ? <ButtonLoader /> :
                                    'Cancel'
                            }
                        </button>
                    }
                    <button type='button'
                        onClick={submitHandler}
                        className="btn btn--primary btn--md radius-5 md:ml-auto"
                        disabled={isLoadingRule}
                    >
                        {
                            isLoadingRule
                                ? <ButtonLoader /> :
                                'Save'
                        }

                    </button>
                    <button type='button'
                        onClick={(e) => {
                            submitHandler(e, true)

                        }}
                        className="btn btn--primary btn--md radius-5" disabled={isLoadingRule}>
                        {
                            isLoadingRule
                                ? <ButtonLoader /> :
                                'Save and Add Another'
                        }
                    </button>
                </div>
            </>
        </ModalTemplate>
    )
}